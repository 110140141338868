import { Box, Card, Link, Stack, styled, Tooltip, Typography } from '@mui/material';
import chroma from 'chroma-js';
import { FC, useState } from 'react';
import { DataCardProps } from './data-card.types';

interface ContainerProps {
  backgroundColor?: string;
  wrap?: boolean;
}

const Container = styled(Card)<ContainerProps>(({ backgroundColor, theme, wrap }) => ({
  position: 'relative',
  alignItems: 'center',
  borderRadius: theme.dataCard.borderRadius,
  background: backgroundColor || theme.dataCard.background,
  color: theme.dataCard.contrastText,
  width: '100%',
  padding: `${theme.spacing(2)} ${theme.spacing(1.5)}`,
  '& .avatar': {
    width: '60px',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      fontSize: theme.dataCard.avatarSize,
      maxWidth: '1em',
      maxHeight: '1em',
    },
    '& svg': {
      fontSize: '2rem',
    },
  },
  '& .indicator': {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  '& .content': {
    minWidth: 0,
    width: '100%',
  },
  '& .label, .title, .hint': {
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: wrap ? 'normal' : 'nowrap',
  },
  '& a': {
    textDecoration: 'none',
  },
  '& .label, .hint': {
    color: chroma(theme.dataCard.contrastText).alpha(0.7).hex(),
  },
}));

export const DataCard: FC<DataCardProps> = ({
  avatar,
  backgroundColor,
  hint,
  href,
  indicator,
  label,
  title,
  tooltip,
  wrap,
  ...props
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  return <Tooltip
    arrow
    onMouseEnter={() => setTooltipOpen(true)}
    onMouseLeave={() => setTooltipOpen(false)}
    open={tooltipOpen}
    title={tooltip}
  >
    <Container
      backgroundColor={backgroundColor}
      data-testid={props['data-testid']}
      elevation={tooltipOpen && tooltip ? 1 : 0}
      wrap={wrap}
    >
      {indicator ? <Box className='indicator'>{indicator}</Box> : <></>}
      <Stack alignItems='center' direction='row' gap={1} height='100%' >
        {avatar ? <Box className='avatar'>{avatar}</Box> : <></>}
        <Box className='content'>
          <Stack direction='column'>
            {label
              ? <Typography
                data-testid={props['data-testid'] ? `${props['data-testid']}-label` : ''}
                className='label'
                component='div'
                variant='dataCardLabel'
              >
                {label}
              </Typography>
              : <></>
            }
            {title
              ? <Typography
                data-testid={props['data-testid'] ? `${props['data-testid']}-title` : ''}
                className='title'
                component='div'
                variant='dataCardTitle'
              >
                {href ? <Link component='a' href={href}>{title}</Link> : title}
              </Typography>
              : <></>
            }
            {hint
              ? <Typography
                data-testid={props['data-testid'] ? `${props['data-testid']}-hint` : ''}
                className='hint'
                component='div'
                variant='dataCardHint'
              >
                {hint}
              </Typography>
              : <></>
            }
          </Stack>
        </Box>
      </Stack>
    </Container>
  </Tooltip>;
};
