import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Divider, IconButton, Slide, Stack, Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { scrollbar } from '../../../../style';
import { ToggleLayers } from '../../../../types';
import { MapScrollableWrapper } from '../../../wrapper/map-scrollable-wrapper';
import { SLIDE_DURATION } from '../layer-selection';
import { SubMenuSectionController } from './sub-menu-section-controller';
import { getSubSectionData } from './sub-menu.util';

interface Props {
  closeSubMenu: () => void;
  height?: number;
  selectedSubMenu: ToggleLayers;
  width?: number;
}

export const SubMenu: FC<Props> = ({
  closeSubMenu,
  height,
  selectedSubMenu,
  width,
}) => {
  const { t } = useTranslation(['common']);
  const theme = useTheme();

  return (
    <MapScrollableWrapper>
      <Slide direction="left" in={!!selectedSubMenu} timeout={SLIDE_DURATION}>
        <Stack sx={{ ...scrollbar, height, overflow: 'auto', width }}>
          <Stack direction="row" sx={{ alignItems: 'center', pb: 1 }}>
            <IconButton onClick={closeSubMenu} sx={{ mr: 0.5, p: 0 }}>
              <ChevronLeftIcon sx={{ color: theme.palette.common.black }} />
            </IconButton>
            <Typography>{t(`common:component.map.layers.labels.${selectedSubMenu}`)}</Typography>
          </Stack>
          <Divider />
          {getSubSectionData(selectedSubMenu).map(({ cacheDataTypes, cacheFilter, itemType, storageKey, typePropertyName }) =>
            <SubMenuSectionController
              key={itemType}
              cacheDataTypes={cacheDataTypes}
              cacheFilter={cacheFilter}
              label={t(`common:component.map.layers.labels.${itemType}`)}
              storageKey={storageKey}
              typePropertyName={typePropertyName}
            />,
          )}
        </Stack>
      </Slide>
    </MapScrollableWrapper>
  );
};
