/* eslint-disable react-hooks/exhaustive-deps */
import { RoleFunction } from '@eagle/common';
import { PointOfInterest, PointOfInterestSet, PointOfInterestType } from '@eagle/core-data-types';
import { Location } from '@eagle/data-function-types';
import { FC, useEffect } from 'react';
import { useMap } from 'react-leaflet';
import { evaluate } from '../../../../evaluator';
import { useCustomRoutes, useDynamicModule, useUiTemplate } from '../../../../hooks';
import { FeatureIcons, MapPanes, Nullable, Portals } from '../../../../types';
import { DEFAULT_POI_MAP_POPUP } from '../../../../ui-templates';
import { switchToPortal, useHasAuthorization } from '../../../../util';
import { PortalFeatureIcons } from '../../../icons';
import { MapPopup } from '../../map-popup';

interface PointOfInterestDetailsProps {
  icon?: JSX.Element;
  image?: Nullable<string>;
  pointOfInterest: PointOfInterest;
  pointOfInterestSet: PointOfInterestSet;
  pointOfInterestType: PointOfInterestType;
}

interface PointOfInterestPopupProps {
  closePopup: () => void;
  icon?: JSX.Element;
  image?: Nullable<string>;
  isPopupOpen: boolean;
  pointOfInterest: PointOfInterest;
  pointOfInterestSet: PointOfInterestSet;
  pointOfInterestType: PointOfInterestType;
}

const POINT_OF_INTEREST_ADMIN = [RoleFunction.POINT_OF_INTEREST_ADMINISTRATOR] as const;

const PointOfInterestDetails: FC<PointOfInterestDetailsProps> = ({ icon, image, pointOfInterest, pointOfInterestType }): JSX.Element => {
  const { _id } = pointOfInterest;
  const { pointOfInterest: customRoutesPointOfInterest } = useCustomRoutes();
  const { hasAuthorization } = useHasAuthorization();
  const { module, loaded: moduleLoaded } = useDynamicModule<FeatureIcons>('feature-icons', PortalFeatureIcons.Admin);
  const { template, loaded: mapThingLoaded } = useUiTemplate('poi-map-popup', DEFAULT_POI_MAP_POPUP);

  const isBase64Image = (dataURI: string): boolean => {
    return dataURI.startsWith('data:image') && dataURI.includes('base64');
  };

  if (!(mapThingLoaded && module && moduleLoaded && pointOfInterest && pointOfInterestType)) return <></>;

  return <>
    {evaluate(template, {
      href: hasAuthorization(POINT_OF_INTEREST_ADMIN) ? switchToPortal(null, Portals.ADMIN, `${customRoutesPointOfInterest}/${_id}`) : null,
      icon: <module.PointOfInterestIcon />,
      poiIcon: image && isBase64Image(image)
        ? <img width="32" height="32" src={image} />
        : icon
          ? <>{icon}</>
          : <module.PointOfInterestIcon />,
      pointOfInterest,
      pointOfInterestType,
    })}
  </>;
};

export const PointOfInterestPopup: FC<PointOfInterestPopupProps> = ({ icon, image, pointOfInterest, pointOfInterestSet, pointOfInterestType, isPopupOpen, closePopup }) => {
  const { location } = pointOfInterest;
  const map = useMap();

  useEffect(() => {
    if (!isPopupOpen) return;
    map.addEventListener('click', closePopup);

    return () => {
      map.removeEventListener('click', closePopup);
    };
  }, [isPopupOpen]);

  return (
    <MapPopup
      handleCloseButtonClick={closePopup}
      isPopupOpen={isPopupOpen}
      locationData={location as Location}
      pane={MapPanes.POPUP_PANE}
    >
      <PointOfInterestDetails
        icon={icon}
        image={image}
        pointOfInterest={pointOfInterest}
        pointOfInterestSet={pointOfInterestSet}
        pointOfInterestType={pointOfInterestType}
      />
    </MapPopup>
  );
};
