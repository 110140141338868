import { Stack } from '@mui/material';
import { FC } from 'react';
import { useSmallScreen } from '../../hooks';

interface WelcomePage {
  graphic: JSX.Element;
  logo: JSX.Element;
  text: JSX.Element;
  title: JSX.Element;
}

export const WelcomePageLayout: FC<WelcomePage> = ({ graphic, logo, text, title }) => {
  const smallScreen = useSmallScreen();
  return (
    <Stack
      spacing={4}
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        pt: 4,
        marginBottom: smallScreen ? '5vh' : '15vh',
        width: '100%',
      }}
    >
      <Stack direction="row">
        {logo}
        <Stack sx={{ textAlign: 'center', justifyContent: 'center', marginLeft: '1.75vw' }} data-testid="welcome-page-title">
          {title}
        </Stack>
      </Stack>
      <Stack spacing={2} sx={{ textAlign: 'center', mx: 1 }} data-testid="welcome-page-message">
        {text}
      </Stack>
      {graphic}
    </Stack>
  );
};
