import { TrackingEventTypes } from '@eagle/data-function-types';
import { Stack, Switch, Typography, useTheme } from '@mui/material';
import L from 'leaflet';
import { useSnackbar } from 'notistack';
import { Dispatch, FC, PropsWithChildren, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MapContainer } from 'react-leaflet';
import { EventLocationData, FlexBox, FloatingButton, LoadingComponent, MapLayerController, MapLayersProvider, MapScale, SavePosition, ThingEventPane, useBoolFlag, useHistorySearch, ZOOM, ZoomButtons } from '../../components';
import { BOUNDS_OF_EARTH, HISTORY_MAP_CENTER, MAP_MAX_ZOOM, MAP_MIN_ZOOM } from '../../constants';
import { MapProvider, useSavedPosition, useSmallScreen } from '../../hooks';
import { MapStorageKeys } from '../../types';
import { Maybe } from '../../types/common';
import { savedPositionCheck, ValidateResize } from '../../util';

interface Props extends PropsWithChildren {
  center: L.LatLng;
  hoveredEventId?: Maybe<string>;
  selectedEvent: Maybe<EventLocationData>;
  setHoveredEventId: Dispatch<SetStateAction<Maybe<string>>>;
  setSelectedEvent: Dispatch<SetStateAction<Maybe<EventLocationData>>>;
  storageKey: MapStorageKeys;
  zoom?: number;
}

export const EventHistoryMap: FC<Props> = ({
  center,
  children,
  hoveredEventId,
  selectedEvent,
  setHoveredEventId,
  setSelectedEvent,
  storageKey,
  zoom = ZOOM.earth,
}) => {
  const { t } = useTranslation(['common', 'track']);
  const { enqueueSnackbar } = useSnackbar();
  const flagSnapToRoad = useBoolFlag('track-history-map-snap-to-road-toggle');
  const flagRouteAnimationSwitch = useBoolFlag('thing-history-page-route-animation-switch');
  const theme = useTheme();
  const [isAntPolylineEnabled, setIsAntPolylineEnabled] = useState(!flagRouteAnimationSwitch);
  const { savedPosition } = useSavedPosition(storageKey);
  const smallScreen = useSmallScreen();
  const {
    eventData,
    eventLimit,
    mapLoading,
    setSnapToRoad,
    snapToRoad,
  } = useHistorySearch();
  const savedCenter = savedPosition && new L.LatLng(savedPosition.lat, savedPosition.lng);
  const noEvents = eventData.every((item) => !item.data.count);
  const mapLoadingCheck = Object.values(mapLoading).some((value) => value === true);

  useEffect(() => {
    savedPositionCheck(storageKey);
  }, [storageKey]);

  const onClick = (): void => {
    if (mapLoadingCheck) return;
    if (!snapToRoad) {
      const eventsOutsideRange = eventData.filter(({ data: { items } }) =>
        items.filter(({ eventTypeId }) => eventTypeId === TrackingEventTypes.LOCATION_UPDATE).length > eventLimit,
      );
      if (eventsOutsideRange.length !== eventData.length) setSnapToRoad(!snapToRoad);
      eventsOutsideRange.forEach(({ entityDisplay }) =>
        enqueueSnackbar(t('track:page.history.too-many-locations.hint.failure', { display: entityDisplay }), { variant: 'info' }),
      );
      return;
    }
    setSnapToRoad(!snapToRoad);
  };

  return (
    <MapProvider>
      <LoadingComponent isLoading={mapLoadingCheck} />
      <FlexBox
        sx={{
          'svg > g > path': { outline: 'none !important' },
          '& .leaflet-container': {
            filter: noEvents ? 'blur(4px)' : 'none',
            height: '100%',
            pointerEvents: noEvents ? 'none' : 'auto',
            width: '100%',
          },
          '& .leaflet-div-icon': { alignItems: 'center', background: 'none', border: 'none', display: 'flex' },
          height: '100%',
          position: 'relative',
        }}
      >
        <MapLayersProvider>
          <MapContainer
            center={savedCenter ?? center}
            maxBounds={BOUNDS_OF_EARTH}
            maxBoundsViscosity={1}
            maxZoom={MAP_MAX_ZOOM}
            minZoom={MAP_MIN_ZOOM}
            preferCanvas={true}
            renderer={new L.Canvas()}
            touchZoom={true}
            worldCopyJump={false}
            zoom={savedPosition?.alt ?? zoom}
            zoomAnimation={true}
            zoomAnimationThreshold={2}
            zoomControl={false}
            zoomDelta={1}
            zoomSnap={0.5}
          >
            <MapLayerController drawerLayerSelection={smallScreen} shouldShowLayerSelection={!noEvents} />
            {children}
            {!noEvents &&
              <Stack>
                <Stack direction="row" spacing={2} sx={{ position: 'inherit', m: 2, zIndex: 400, justifyContent: smallScreen ? 'center' : 'inherit' }}>
                  {flagSnapToRoad &&
                    <FloatingButton
                      data-testid="snap-to-road-button"
                      disabled={mapLoadingCheck}
                      onClick={onClick}
                      variant="contrast"
                    >
                      <Switch checked={snapToRoad} data-testid="snap-to-road-switch" disabled={mapLoadingCheck} /> {t('common:component.map.labels.snap-to-road')}
                    </FloatingButton>
                  }
                  {flagRouteAnimationSwitch &&
                    <FloatingButton
                      onClick={() => {
                        setIsAntPolylineEnabled((value) => !value);
                      }}
                      variant="contrast"
                    >
                      <Switch checked={isAntPolylineEnabled} />
                      {t('common:component.map.labels.route-animation')}
                    </FloatingButton>
                  }
                </Stack>
                <ValidateResize />
                <ZoomButtons sx={{ bottom: 0, m: 3, position: 'absolute', right: 0, zIndex: 500 }} />
                <MapScale />
              </Stack>
            }
            <ThingEventPane
              hoveredEventId={hoveredEventId}
              isAntPolylineEnabled={isAntPolylineEnabled}
              selectedEvent={selectedEvent}
              setHoveredEventId={setHoveredEventId}
              setSelectedEvent={setSelectedEvent}
              storageKey={MapStorageKeys.HISTORY_JOURNEY_SAVED_POSITION}
            />
            <SavePosition initialCenter={HISTORY_MAP_CENTER} storageKey={storageKey} />
          </MapContainer>
          {noEvents &&
            <FlexBox
              sx={{
                alignItems: 'center',
                bottom: 0,
                justifyContent: 'center',
                left: 0,
                position: 'absolute',
                right: 0,
                top: 0,
              }}
            >
              <Typography
                color="text.secondary"
                fontStyle="italic"
                sx={{
                  textShadow: `0 0 ${theme.spacing(1)} ${theme.palette.background.paper}, 0 0 ${theme.spacing(2)} ${theme.palette.background.paper}, 0 0 ${theme.spacing(3)} ${theme.palette.background.paper}`,
                }}
                variant="body2"
              >
                {t('common:component.map.hint.no-location-information')}
              </Typography>
            </FlexBox>
          }
        </MapLayersProvider>
      </FlexBox>
    </MapProvider>
  );
};
