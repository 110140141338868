import { Chip } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export type LeaseStatus = 'pending' | 'active' | 'finished' | 'no-lease';

const statusToChipDefinition: Record<LeaseStatus, { label: string; color: 'success' | 'warning' | 'error' | 'default' }> = {
  'pending': { label: 'admin:page.thing-details.lease-management.pending.labels', color: 'warning' },
  'active': { label: 'admin:page.thing-details.lease-management.active.labels', color: 'success' },
  'finished': { label: 'admin:page.thing-details.lease-management.finished.labels', color: 'default' },
  'no-lease': { label: 'admin:page.thing-details.lease-management.no-active-lease.labels', color: 'error' },
};

interface LeaseStatusChipProps {
  status: LeaseStatus;
  fullWidth?: boolean;
}

export const LeaseStatusChip: FC<LeaseStatusChipProps> = ({ status, fullWidth }) => {
  const { t } = useTranslation(['admin']);
  const chipDefinition = statusToChipDefinition[status];

  return (
    <Chip
      data-testid="lease-status-chip"
      color={chipDefinition.color}
      label={t(chipDefinition.label)}
      sx={fullWidth ? { width: '100%' } : undefined}
    />
  );
};
