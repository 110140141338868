import { CreateCheckoutSessionRequest, DataSubscriptionPlan, LastDataSubscriptionResult, SupportedCurrency, ThingDataSubscriptionInfo } from '@eagle/core-data-types';
import { EthingsRestClient } from '@eagle/ethings-rest-client';
import { captureException } from '@sentry/react';
import { AxiosInstance } from 'axios';
import { i18n } from 'i18next';
import { LDFlagSet } from 'launchdarkly-js-client-sdk';
import { DateTime, Duration } from 'luxon';
import { useEffect } from 'react';
import { isArray, isIssue, isObject, isRecord, isString } from 'validata';
import { DomainCustomConfig, useAuthenticated } from '../../auth';
import { Nullable, Undefinable } from '../../types';
import { FILTER_OUT } from '../../util';
import { CartItem, ContractStatus, ContractUnavailabilityReason, SelectablePlan, SelectValue, StoredCart, StoredCartEntry, StoredStripeCheckoutResponse, StripeCheckoutResponse, ThingTypeWithDataSubscriptionPlans, ThingWithContractData } from './contract-management.types';

export const CONTRACT_LIST_CART_STORAGE_KEY = 'CONTRACT_LIST_CART';
export const CONTRACT_LIST_CURRENCY_STORAGE_KEY = 'CONTRACT_LIST_CURRENCY';
const CONTRACT_LIST_ACTIVE_CHECKOUT_STORAGE_KEY = 'CONTRACT_LIST_ACTIVE_CHECKOUT';

const CONTRACT_MANAGEMENT_FLAG = 'admin-contract-management-feature';
export const CONTRACT_MANAGEMENT_CART_VALIDITY_FLAG = 'admin-contract-management-shopping-cart-validity-time';

const IN_CONTRACT_THRESHOLD_DAYS = 30;

export const hasContractManagementAccess = (flags: LDFlagSet, config: Partial<DomainCustomConfig>): boolean => {
  return Boolean(flags[CONTRACT_MANAGEMENT_FLAG] && config.paymentGateway?.enabled);
};

export const getContractStatus = (item: ThingWithContractData): ContractStatus => {
  if (!item.contract.dataSubscription) {
    return 'unknown';
  }

  const now = DateTime.now();
  const expiry = DateTime.fromJSDate(item.contract.dataSubscription.lastDataSubscription.expiry);

  if (!isSubscriptionOwner(item.contract.dataSubscription)) {
    return 'not-renewable';
  }

  if (isSubscriptionFinished(item.contract.dataSubscription.lastDataSubscription)) {
    return 'out-of-contract';
  }

  if (now > expiry) {
    return 'recently-expired';
  }

  if (expiry.diff(now, 'days').days > IN_CONTRACT_THRESHOLD_DAYS) {
    return 'in-contract';
  }

  return 'upcoming';
};

export const isSubscriptionFinished = (dataSubscription: LastDataSubscriptionResult): boolean => {
  if (!dataSubscription.finish) {
    return false;
  }
  const now = DateTime.now();
  const finish = DateTime.fromJSDate(dataSubscription.finish);

  return now > finish;
};

const isSubscriptionOwner = (dataSubscription: ThingDataSubscriptionInfo): boolean => {
  return Boolean((
    dataSubscription.lastDataSubscription.stakeholderType &&
    dataSubscription.lifecycleState?.activeStakeholderRoles?.includes(dataSubscription.lastDataSubscription.stakeholderType)
  ));
};

export const formatCurrency = (i18n: i18n, currency: string): string => {
  try {
    const parts = new Intl.NumberFormat(i18n.resolvedLanguage, { style: 'currency', currency, currencyDisplay: 'code' }).formatToParts();
    const currencyPart = parts.find((part) => part.type === 'currency');
    if (!currencyPart) {
      return currency;
    }
    return currencyPart.value;
  }
  catch (error) {
    console.error(error);
    captureException(error);
    return currency;
  }
};

export const formatPrice = (i18n: i18n, currency: string, amount: number): string => {
  try {
    return new Intl.NumberFormat(i18n.resolvedLanguage, { style: 'currency', currency, currencyDisplay: 'symbol' }).format(amount);
  }
  catch (error) {
    console.error(error);
    captureException(error);
    return `${amount} ${currency}`;
  }
};

export const formatTerm = (i18n: i18n, term: string): string => {
  try {
    return Duration.fromISO(term, { locale: i18n.resolvedLanguage }).toHuman();
  }
  catch (error) {
    console.error(error);
    captureException(error);
    return term;
  }
};

export const serializeSelectValue = (value: SelectValue): string => {
  return JSON.stringify({ dataSubscriptionPlanId: value.dataSubscriptionPlanId, term: value.term });
};

export const deserializeSelectValue = (option: string): SelectValue => {
  return JSON.parse(option) as SelectValue;
};

export const getInitialCartContent = async (accountId: string, validityInHours: number, thingTypes: ThingTypeWithDataSubscriptionPlans[], currency: SupportedCurrency, restClient: EthingsRestClient): Promise<CartItem[]> => {
  try {
    const storedCartByAccountId = getValidatedLocalStorageValue(CONTRACT_LIST_CART_STORAGE_KEY, isStoredCartValid);
    if (!storedCartByAccountId) {
      return [];
    }
    const storedCart = storedCartByAccountId[accountId];
    if (!storedCart) {
      return [];
    }

    const now = DateTime.now();
    const expiry = DateTime.fromISO(storedCart.timeStamp).plus({ hours: validityInHours });
    if (!expiry.isValid) {
      throw new Error('Invalid cart expiry date in localStorage.');
    }
    if (now > expiry) {
      return [];
    }

    const result: CartItem[] = [];
    const thingIds = storedCart.content.map((storedCartItem) => storedCartItem.thingId);
    if (thingIds.length === 0) {
      return [];
    }

    const [things, subscriptions] = await Promise.all([
      restClient.thing.getAll({
        filter: {
          ...FILTER_OUT.deleted,
          _id: { $in: thingIds },
        },
      }),
      restClient.thingDataSubscriptionInfo.getThingDataSubscriptionInfo(thingIds),
    ]);

    for (const storedCartItem of storedCart.content) {
      const thing = things.find((thing) => thing._id === storedCartItem.thingId);
      if (!thing) {
        throw new Error('Thing stored in cart not found.');
      }
      const thingType = thingTypes.find(({ thingType }) => thing.thingTypeId === thingType._id);
      const dataSubscriptionPlan = thingType?.dataSubscriptionPlans.find(({ dataSubscriptionPlanId }) => storedCartItem.dataSubscriptionPlanId === dataSubscriptionPlanId);
      if (!dataSubscriptionPlan) {
        throw new Error('Data subscription plan stored in cart not found.');
      }
      const term = dataSubscriptionPlan.subscriptionTerms.find((term) => term === storedCartItem.term);
      if (!term) {
        throw new Error('Term stored in cart not found.');
      }
      const price = getTermPrice(dataSubscriptionPlan, term, currency);
      if (price === null) {
        throw new Error('Stored cart item does not support stored currency.');
      }

      const subscription = subscriptions[thing._id];
      const availablePlans = getSelectablePlans(thingType?.dataSubscriptionPlans ?? [], currency);
      if (!canSelectDataSubscriptionPlan(subscription, availablePlans).result) {
        continue;
      }

      result.push({
        thing,
        dataSubscriptionPlan,
        term,
      });
    }

    return result;
  }
  catch (error) {
    console.error(error);
    captureException(error);
    window.localStorage.removeItem(CONTRACT_LIST_CART_STORAGE_KEY);
    return [];
  }
};

export const getStoredCurrency = (accountId: string, supportedCurrencies: string[]): Nullable<SupportedCurrency> => {
  try {
    const storedCurrencyByAccountId = getValidatedLocalStorageValue(CONTRACT_LIST_CURRENCY_STORAGE_KEY, isStoredCurrencyValid);
    if (!storedCurrencyByAccountId) {
      return null;
    }

    const storedCurrency = storedCurrencyByAccountId[accountId];
    if (!storedCurrency) {
      return null;
    }
    if (!supportedCurrencies.includes(storedCurrency)) {
      throw new Error('Stored currency is not supported.');
    }

    return storedCurrency;
  }
  catch (error) {
    console.error(error);
    captureException(error);
    window.localStorage.removeItem(CONTRACT_LIST_CURRENCY_STORAGE_KEY);
    return null;
  }
};

const getValidatedLocalStorageValue = <T>(key: string, validator: (input: unknown) => input is T): Nullable<T> => {
  const rawLocalStorageValue = window.localStorage.getItem(key);
  if (!rawLocalStorageValue) {
    return null;
  }
  const parsedValue: unknown = JSON.parse(rawLocalStorageValue);
  const isValid = validator(parsedValue);
  if (!isValid) {
    throw new Error(`Invalid value stored in localStorage (${key}).`);
  }
  return parsedValue;
};

export const isStoredCartValid = (value: unknown): value is StoredCart => {
  const result = isRecord(
    isObject({
      timeStamp: isString(),
      content: isArray(
        isObject({
          dataSubscriptionPlanId: isString(),
          term: isString(),
          thingId: isString(),
        })),
    }),
  ).process(value);

  return !isIssue(result);
};

export const isStoredCurrencyValid = (value: unknown): value is Record<string, string> => {
  return !isIssue(isRecord(isString()).process(value));
};

export const getUnsupportedItemsInCart = (cartContent: CartItem[], currency: SupportedCurrency): CartItem[] => {
  return cartContent.filter(({ dataSubscriptionPlan }) => !dataSubscriptionPlan.supportedCurrencies.includes(currency));
};

export const getTermPrice = (plan: DataSubscriptionPlan, term: string, currency: SupportedCurrency): Nullable<number> => {
  if (!plan.supportedCurrencies.includes(currency)) {
    return null;
  }
  const price = plan.price[term]?.[currency];
  if (price === undefined) {
    return null;
  }
  return price.base;
};

export const formatDataSubscriptionPlanWithTerm = (i18n: i18n, plan: DataSubscriptionPlan, term: string): string => {
  const planKey = `data-subscription:data.data-subscription-plans.${plan.display}.labels`;
  const localizedPlanDisplay = i18n.exists(planKey) ? i18n.t(planKey) : plan.display;
  return `${localizedPlanDisplay} (${formatTerm(i18n, term)})`;
};

export const cartItemsToStoredCartEntry = (items: CartItem[]): StoredCartEntry => {
  return {
    content: items.map((item) => ({
      thingId: item.thing._id,
      dataSubscriptionPlanId: item.dataSubscriptionPlan.dataSubscriptionPlanId,
      term: item.term,
    })),
    timeStamp: new Date().toISOString(),
  };
};

export const useSyncToLocalStorageByAccount = (key: string, value: unknown): void => {
  const { account } = useAuthenticated();

  useEffect(() => {
    const validateStoredValue = (value: unknown): value is Record<string, unknown> => {
      const result = isRecord().process(value);
      if (isIssue(result)) {
        throw new Error(`Invalid value stored in ${key}: ${JSON.stringify(value)}.`);
      }
      return true;
    };

    try {
      const rawLocalStorageValue = window.localStorage.getItem(key);
      if (!rawLocalStorageValue) {
        window.localStorage.setItem(key, JSON.stringify({ [account._id]: value }));
        return;
      }
      const storedValueByAccountId: unknown = JSON.parse(rawLocalStorageValue);
      if (validateStoredValue(storedValueByAccountId)) {
        window.localStorage.setItem(key, JSON.stringify({
          ...storedValueByAccountId,
          [account._id]: value,
        }));
      }
    }
    catch (error) {
      console.error(error);
      captureException(error);
    }
  }, [account._id, key, value]);
};

export const getSelectablePlans = (plans: DataSubscriptionPlan[], currency: SupportedCurrency): SelectablePlan[] => {
  const result: SelectablePlan[] = [];

  for (const plan of plans) {
    if (!plan.supportedCurrencies.includes(currency)) continue;

    for (const term of plan.subscriptionTerms) {
      const price = getTermPrice(plan, term, currency);
      if (price === null) continue;
      result.push({
        plan,
        term,
        price,
      });
    }
  }

  return result;
};

export const canSelectDataSubscriptionPlan = (
  dataSubscription: Undefinable<ThingDataSubscriptionInfo>,
  selectablePlans: SelectablePlan[],
): { result: true } | { result: false; reason: ContractUnavailabilityReason } => {
  if (!dataSubscription) {
    return { result: false, reason: 'no-contract' };
  }
  if (!isSubscriptionOwner(dataSubscription)) {
    return { result: false, reason: 'non-owner' };
  }
  if (dataSubscription.lastDataSubscription.activeCheckoutSessionExists) {
    return { result: false, reason: 'already-in-cart' };
  }
  if (selectablePlans.length === 0) {
    return { result: false, reason: 'no-plans' };
  }
  return { result: true };
};

export const getCartItemsWithActiveCheckoutSession = async (restClient: EthingsRestClient, items: CartItem[]): Promise<CartItem[]> => {
  if (items.length === 0) {
    return [];
  }

  const subscriptions = await restClient.thingDataSubscriptionInfo.getThingDataSubscriptionInfo(items.map((item) => item.thing._id));

  return items.filter((item) => {
    const subscription = subscriptions[item.thing._id];
    if (!subscription) {
      return false;
    }
    return subscription.lastDataSubscription.activeCheckoutSessionExists;
  });
};

export const cartItemsToApiCallBody = (cartItems: CartItem[], currency: SupportedCurrency): CreateCheckoutSessionRequest => {
  return {
    cart: cartItems.map((item) => ({
      thingId: item.thing._id,
      dataSubscriptionPlanId: item.dataSubscriptionPlan.dataSubscriptionPlanId,
      term: item.term,
      currency,
    })),
  };
};

export const storeActiveCheckout = (checkout: StripeCheckoutResponse): void => {
  const data: StoredStripeCheckoutResponse = {
    sessionId: checkout.sessionId,
    expiresAt: checkout.expiresAt.toISOString(),
  };

  window.localStorage.setItem(CONTRACT_LIST_ACTIVE_CHECKOUT_STORAGE_KEY, JSON.stringify(data));
};

export const clearStoredActiveCheckout = (): void => {
  window.localStorage.removeItem(CONTRACT_LIST_ACTIVE_CHECKOUT_STORAGE_KEY);
};

export const hasActiveStoredCheckout = (): boolean => {
  try {
    const now = DateTime.now();
    const rawLocalStorageValue = window.localStorage.getItem(CONTRACT_LIST_ACTIVE_CHECKOUT_STORAGE_KEY);
    if (!rawLocalStorageValue) {
      return false;
    }
    const storedActiveSession: unknown = JSON.parse(rawLocalStorageValue);
    if (!isStoredActiveCheckoutValid(storedActiveSession)) {
      throw new Error('Invalid active session stored in localStorage');
    }
    const expiresAt = DateTime.fromISO(storedActiveSession.expiresAt);
    if (!expiresAt.isValid) {
      throw new Error('Invalid active checkout expiry date in localStorage.');
    }

    if (now > expiresAt) {
      window.localStorage.removeItem(CONTRACT_LIST_ACTIVE_CHECKOUT_STORAGE_KEY);
      return false;
    }

    return true;
  }
  catch (error) {
    console.error(error);
    captureException(error);
    clearStoredActiveCheckout();
    return false;
  }
};

export const isStoredActiveCheckoutValid = (value: unknown): value is StoredStripeCheckoutResponse => {
  const result = isObject({
    sessionId: isString(),
    expiresAt: isString(),
  }).process(value);

  return !isIssue(result);
};

export const expireCheckoutSession = async (sessionId: string, accessToken: string, axios: AxiosInstance, keepAlive: boolean): Promise<void> => {
  // If available, we use fetch with keepalive to ensure that sessions will expire even if the page is unloaded.
  const url = `/api/v1/checkout-session/${sessionId}/expire`;
  const isFetchWithKeepAliveSupported = 'fetch' in window && 'keepalive' in Request.prototype;

  if (!keepAlive || !isFetchWithKeepAliveSupported) {
    const response = await axios.post<void>(`/api/v1/checkout-session/${sessionId}/expire`);
    return response.data;
  }

  await window.fetch(url, {
    method: 'POST',
    keepalive: true,
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
};
