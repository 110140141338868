import { DateRange, DateRangePickerDay, PickersDay, StaticDatePicker, StaticDateRangePicker } from '@mui/lab';
import { Button, Stack, useTheme } from '@mui/material';
import { DateTime, DurationUnit } from 'luxon';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Undefinable } from '../../types';
import { testid } from '../../util';
import { useDateTimeRangePickerContext } from '../../util/data-time-range-picker';
import { DateTimeRangeError } from '../../util/date';
import { Alert } from '../alert';
import { DateFieldType } from '../date-time-range-picker';
import { TimePicker } from '../date-time-range-picker/time-picker';
import { InlineLayout } from '../event-history/inline-layout';
import { FlexBox } from '../flex-box';
import { RefreshIcon } from '../icons';
import { modalStyles } from './inline-date-time-range-picker.styles';

const DEFAULT_TIME = DateTime.now().set({ hour: 0, minute: 0, second: 0 });

interface Props {
  'data-testid'?: string;
  defaultEndDate?: DateTime;
  defaultStartDate?: DateTime;
  maxDateRange: number;
  maxRangeUnit: DurationUnit;
  onCalendarSelect: (date: Date, defaultTime: DateTime, dateFieldType: DateFieldType) => void;
  onReset: () => void;
  onSetRange: () => void;
  shouldDisableDate: (date: DateTime, maxRange: number) => boolean;
  shouldDisableSetRange: () => boolean;
  singleDate?: boolean;
  hideSeconds?: boolean;
}

export const InlineDateTimeRangePickerView: FC<Props> = ({
  defaultEndDate = DEFAULT_TIME,
  defaultStartDate = DEFAULT_TIME,
  maxDateRange,
  maxRangeUnit,
  onCalendarSelect,
  onSetRange,
  shouldDisableDate,
  shouldDisableSetRange,
  singleDate,
  hideSeconds,
  ...props
}): JSX.Element => {
  const { t } = useTranslation(['common']);
  const { classes } = modalStyles();
  const theme = useTheme();
  const {
    clearAll,
    dateTimeRangeError,
    endDate,
    endTime,
    endTimeError,
    handleDisableEscapeKey,
    setEndTime,
    setEndTimeError,
    setStartTime,
    setStartTimeError,
    startDate,
    startTime,
    startTimeError,
  } = useDateTimeRangePickerContext();
  const isResetButtonDisabled = !startDate && !endDate && !startTime && !endTime;
  const [refresh, setRefresh] = useState<Undefinable<Date>>();

  useEffect(() => {
    if (!refresh && endDate) setRefresh(new Date());
  }, [endDate, refresh]);

  const renderTimePicker = (
    disabled: boolean,
    label: string,
    setTime: (date?: DateTime) => void,
    setTimeError: (error?: DateTimeRangeError) => void,
    date?: DateTime,
    testId?: string,
  ): JSX.Element => {
    const timeUpdated = (time: DateTime | undefined): void => {
      setTime(time);
    };

    return (
      <TimePicker
        data-testid={testId}
        disabled={disabled}
        handleDisableEscapeKey={handleDisableEscapeKey}
        label={label}
        maxRange={maxDateRange}
        maxRangeUnit={maxRangeUnit}
        setTime={timeUpdated}
        setTimeError={setTimeError}
        time={date}
        views={hideSeconds ? ['hours', 'minutes'] : ['hours', 'minutes', 'seconds']}
      />
    );
  };

  const renderCalendar = (
    defaultEnd: DateTime,
    defaultStart: DateTime,
    isDateDisabled: (date: DateTime, maxRange: number) => boolean,
    maxRange: number,
  ): JSX.Element => {
    const handleRangeChange = (newRange: DateRange<Date>): void => {
      if (startDate && endDate) return;
      if (!startDate) {
        const newStartDate = newRange[0] ?? newRange[1];
        newStartDate && onCalendarSelect(newStartDate, defaultStart, DateFieldType.START_DATE);
        return;
      }
      const newEndDate = newRange[1] ?? newRange[0];
      newEndDate && onCalendarSelect(newEndDate, defaultEnd, DateFieldType.END_DATE);
    };

    const handleDateChange = (date: Date | null): void => {
      if (date) {
        onCalendarSelect(date, defaultStart, DateFieldType.START_DATE);
        onCalendarSelect(DateTime.fromJSDate(date).plus({ hours: maxDateRange }).toJSDate(), defaultEnd, DateFieldType.END_DATE);
        return;
      }
    };

    if (singleDate) {
      return (<StaticDatePicker
        componentsProps={{
          leftArrowButton: { 'data-testid': 'left-button' },
          rightArrowButton: { 'data-testid': 'right-button' },
        }}
        className={classes.calendarPicker}
        value={startDate?.toJSDate() ?? null}
        onChange={handleDateChange}
        renderDay={(date, _selectedDates, pickersDayProps) => <PickersDay data-testid={testid`day-${DateTime.fromJSDate(date).day}`} {...pickersDayProps} />}
        renderInput={() => <></>}
        disableFuture
        displayStaticWrapperAs='desktop'
        views={['month', 'day']}
      />);
    }

    return (
      <StaticDateRangePicker
        key={refresh?.getTime()}
        componentsProps={{
          leftArrowButton: { 'data-testid': 'left-button' },
          rightArrowButton: { 'data-testid': 'right-button' },
        }}
        calendars={1}
        className={classes.calendarPicker}
        defaultCalendarMonth={endDate?.toJSDate()}
        disableFuture
        displayStaticWrapperAs="desktop"
        onChange={handleRangeChange}
        renderDay={(date, dateRangePickerDayProps) => <DateRangePickerDay data-testid={testid`day-${DateTime.fromJSDate(date).day}`} {...dateRangePickerDayProps} />}
        renderInput={() => <></>}
        shouldDisableDate={(date: Date) => isDateDisabled(DateTime.fromJSDate(date), maxRange)}
        value={[startDate?.toJSDate() ?? null, endDate?.toJSDate() ?? null]}
      />
    );
  };

  const ErrorMessage = (): JSX.Element => {
    let error;

    if (dateTimeRangeError) {
      error = dateTimeRangeError;
    } else if (startTimeError) {
      error = startTimeError;
    } else if (endTimeError) {
      error = endTimeError;
    }

    if (!error) return <></>;
    if (error !== DateTimeRangeError.INVALID_RANGE_OVER) return <Alert severity='error'>{t(`common:component.date-picker.hint.${error}`)}</Alert>;

    // @ts-expect-error Normally `count` should be a number, but we want to pass a string here and react-i18next types don't accept that.
    return <Alert severity='error'>{t(`common:component.date-picker.hint.${error}`, {
      count: t(`common:component.date-time-range-picker.hint.${maxRangeUnit}WithCount`, { count: maxDateRange }),
    })}</Alert>;
  };

  const renderSetRangeButton = (handleSetRange: () => void, disabled: boolean): JSX.Element => {
    return (
      <Button
        disabled={disabled}
        onClick={handleSetRange}
        variant="contained"
        data-testid="set-range-button"
      >
        {t('common:component.date-picker.action.set-range')}
      </Button>
    );
  };

  const renderResetButton = (disabled: boolean, handleReset: () => void): JSX.Element => {
    if (singleDate) return <></>;
    return (
      <Button
        data-testid="reset-button"
        disabled={disabled}
        onClick={handleReset}
        startIcon={<RefreshIcon />}
        sx={{
          alignSelf: 'center',
          color: theme.palette.primary.main,
          width: 'fit-content',
        }}
      >
        {t('common:component.date-picker.action.reset-range')}
      </Button>
    );
  };

  return (
    <InlineLayout data-testid={props['data-testid']} sx={{ padding: '0', width: '100%' }}>
      <FlexBox sx={{ alignItems: 'center', justifyContent: 'center' }}>
        {renderCalendar(
          defaultEndDate,
          defaultStartDate,
          shouldDisableDate,
          maxDateRange,
        )}
      </FlexBox>
      <Stack direction='row' spacing={1}>

        {renderTimePicker(
          !startDate,
          t('common:component.date-picker.labels.start-time'),
          setStartTime,
          setStartTimeError,
          startTime,
          'start-time',
        )}
        {renderTimePicker(
          !endDate,
          t('common:component.date-picker.labels.end-time'),
          setEndTime,
          setEndTimeError,
          endTime,
          'end-time',
        )}
      </Stack>
      <ErrorMessage />
      <Stack direction="row" sx={{ justifyContent: 'flex-end' }} spacing={1}>
        {renderResetButton(isResetButtonDisabled, clearAll)}
        {renderSetRangeButton(onSetRange, shouldDisableSetRange())}
      </Stack>
    </InlineLayout>
  );
};
