import { FaultDescriptionResponse } from '@eagle/core-data-types';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useAuthenticated } from '../../auth';
import { Undefinable } from '../../types';

interface UseFaultDescriptionQueryParams {
  code: string;
  failureModeIdentifier: Undefinable<number>;
  thingId: string;
}

export const useFaultDescriptionQuery = ({ code, failureModeIdentifier, thingId }: UseFaultDescriptionQueryParams): UseQueryResult<FaultDescriptionResponse> => {
  const { restClient } = useAuthenticated();
  const { i18n } = useTranslation();

  return useQuery({
    queryKey: ['fault-description', { code, failureModeIdentifier, language: i18n.resolvedLanguage, thingId }],
    queryFn: async () => {
      if (failureModeIdentifier === undefined) {
        throw new Error('failureModeIdentifier should not be undefined.');
      }

      const dtcCode = Number(code);
      if (Number.isNaN(dtcCode)) {
        throw new Error('Invalid DTC code.');
      }

      return restClient.faultDescription.getFaultDescription({
        dtcCode,
        fmi: failureModeIdentifier,
        thingId,
        language: i18n.resolvedLanguage,
      });
    },
  });
};
