import {
  AccountType, LifecycleTemplate, SharedThing, SharedThingType, Stage, ThingLifeCycleStateResponse,
  ThingLifeCycleStateResponseStakeholder
} from '@eagle/core-data-types';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  InputAdornment,
  InputBaseComponentProps,
  Pagination,
  PaginationItem,
  Stack,
  TextField
} from '@mui/material';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { scrollIntoView } from 'seamless-scroll-polyfill';
import { useAuthenticated } from '../../auth';
import { useApiErrorHandler, useFetchAllCache, usePromise, useSmallScreen } from '../../hooks';
import { CacheDataTypes } from '../../types';
import { testid } from '../../util';
import { Alert } from '../alert';
import { Dialog } from '../dialog';
import { StageConfirmDialog } from '../life-cycle-stage-menu/stage-confirm-dialog/stage-confirm-dialog';
import { MiddleSpinner } from '../middle-spinner';
import { SharedThingCard } from '../shared-thing-card/shared-thing-card';

interface Props {
  onSelfClaimDialogClose: () => void;
  onSelfClaimDialogNext: () => void;
  open: boolean;
  lifecycleTemplate: LifecycleTemplate;
  stakeholderRole: string | null;
}

interface PaginationLocal {
  limit: number;
  skip: number;
}

interface ClaimConstraint {
  matchType?: 'suffix' | 'regex';
  minSuffixLength?: number;
  maxSuffixLength?: number;
  regex?: string;
}

export interface AssociativeArray {
  [key: string]: string;
}

export interface EntityTypeIdDisplays {
  brand?: Record<string, string>;
  country?: Record<string, string>;
  model?: Record<string, string>;
  variant?: Record<string, string>;
}

interface TargetStageResult {
  targetStage?: string;
  useCurrentStakeholder: boolean;
  currentUserRole: string | null;
}

const getUserRoleFromAccountType = (accountType: AccountType | undefined): string | null => {
  const accountTypeDisplay = accountType?.display;
  if (!accountTypeDisplay) return null;
  const displayLower = accountTypeDisplay.toLowerCase();
  if (displayLower.includes('dealer')) return 'dealer';
  if (displayLower.includes('manufacturer')) return 'manufacturer';
  if (displayLower.includes('customer')) return 'customer';
  return null;
};

const determineTargetStageWithRole = (
  accountType: AccountType | undefined,
  lifecycleTemplate: LifecycleTemplate,
  sharedThing?: SharedThing,
): TargetStageResult => {
  const currentUserRole = getUserRoleFromAccountType(accountType);
  let targetStage: Stage | undefined;

  const currentStageId = sharedThing?.lifecycleState?.stageId;
  const currentStage = lifecycleTemplate.stages.find((s) => s.stageId === currentStageId);

  if (currentStage && currentUserRole) {
    const roleConfig = currentStage.stakeholders?.[currentUserRole]?.selfClaim;
    if (roleConfig?.targetStage) {
      targetStage = lifecycleTemplate.stages.find((s) => s.stageId === roleConfig.targetStage);
    }
  }

  return {
    targetStage: targetStage?.stageId,
    useCurrentStakeholder: false,
    currentUserRole,
  };
};

export const SelfClaimDialog: FC<Props> = ({
  onSelfClaimDialogClose,
  onSelfClaimDialogNext,
  open,
  lifecycleTemplate,
}) => {
  const { t } = useTranslation(['admin', 'common']);
  const { axios, accountType } = useAuthenticated();
  const { handleGetError } = useApiErrorHandler();
  const [nextDisabled, setNextDisabled] = useState(true);
  const [showMore, setShowMore] = useState(false);
  const [selectedSharedThing, setSelectedSharedThing] = useState<SharedThing | undefined>();
  const [searching, setSearching] = useState(false);
  const [searchClicked, setSearchClicked] = useState(false);
  const [sharedThings, setSharedThings] = useState<SharedThing[]>([]);
  const [matchCount, setMatchCount] = useState(0);
  const smallScreen = useSmallScreen();
  const scrollRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [paginationLocal, setPaginationLocal] = useState<PaginationLocal>({ limit: 4, skip: 0 });
  const [formData, setFormData] = useState<AssociativeArray>({});
  const [validInputs, setValidInputs] = useState<Record<string, boolean>>({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const currentPage = Math.floor(paginationLocal.skip / paginationLocal.limit) + 1;
  const startIndex = (currentPage - 1) * paginationLocal.limit;
  const endIndex = startIndex + paginationLocal.limit;
  const currentPageSharedThings = sharedThings.slice(startIndex, endIndex);

  const sharedThingTypeCache = useFetchAllCache(CacheDataTypes.SHARED_THING_TYPE);

  const [sharedThingTypes] = usePromise<SharedThingType[]>(
    () => sharedThingTypeCache.all(),
    [sharedThingTypeCache],
  );

  const sharedThingTypeVehicleDefinitions = useMemo(() => {
    const sharedThingTypeVehicle = sharedThingTypes?.find((sharedThingType) => { return sharedThingType.display === 'Vehicle'; });
    return sharedThingTypeVehicle?.properties.definitions;
  }, [sharedThingTypes]);

  const validateValue = (name: string, value: string): boolean => {
    if (!claimProperties) return true;

    const claimProperty = claimProperties[name];
    if (!claimProperty || typeof claimProperty !== 'object') return true;

    const { matchType, minSuffixLength, maxSuffixLength, regex } = claimProperty;

    if (matchType === 'suffix') {
      const length = value.length;
      return !(
        (minSuffixLength !== undefined && length < minSuffixLength && length > 0) ||
        (maxSuffixLength !== undefined && length > maxSuffixLength)
      );
    }

    if (matchType === 'regex' && regex) {
      try {
        return new RegExp(regex).test(value);
      } catch {
        return false;
      }
    }

    return true;
  };

  const onError = useCallback((error: Error, type: string): void => {
    handleGetError(error, type, undefined, error.message);
  }, [handleGetError]);

  const validateInput = (name: string, value: string): void => {
    const isValid = validateValue(name, value);
    setValidInputs((prev) => ({ ...prev, [name]: isValid }));
  };

  const stakeholderRoleFromAccountType = useMemo(
    () => getUserRoleFromAccountType(accountType),
    [accountType],
  );

  const claimProperties = useMemo((): Record<string, ClaimConstraint> => {
    if (!stakeholderRoleFromAccountType) return {};

    return lifecycleTemplate.stages.reduce(
      (acc, stage) =>
        Object.assign(acc, stage.stakeholders?.[stakeholderRoleFromAccountType]?.selfClaim?.claimProperties),
      {},
    );
  }, [stakeholderRoleFromAccountType, lifecycleTemplate.stages]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;

    if (value) {
      setFormData({ ...formData, [name]: value });
    }
    else {
      const { [name]: ignored, ...newFormData } = formData; // eslint-disable-line @typescript-eslint/no-unused-vars
      setFormData(newFormData);
    }

    validateInput(name, value);
  };

  const handleShowMoreToggle = useCallback((): void => {
    setShowMore(!showMore);
  }, [showMore]);

  const handleSelectedSharedThing = (sharedThing: SharedThing, selected: boolean): void => {
    if (selected) {
      setSelectedSharedThing(sharedThing);
      setNextDisabled(false);
    } else {
      setSelectedSharedThing(undefined);
      setNextDisabled(true);
    }
  };

  const emptyState = useMemo(() => <Alert severity='info'>{t('admin:page.transfers-list.update-stage-dialog.self-claim.no-matching-results.hint')}</Alert>, [t]);

  const handleSearch = useCallback(async (): Promise<void> => {
    setSearchClicked(true);
    setSearching(true);
    setIsLoading(true);
    setSharedThings([]);
    setNextDisabled(true);

    try {
      const response = await axios.get<SharedThing[]>(`/api/v1/life-cycle-template/${lifecycleTemplate._id}/self-claimable-shared-things`, {
        params: {
          claimProperties: JSON.stringify(formData),
        },
      });

      setSharedThings(response.data);
      setMatchCount(response.data.length);
    } catch (error) {
      setSharedThings([]);
      setMatchCount(0);
      onError(error as Error, CacheDataTypes.SHARED_THING);
    } finally {
      setSearching(false);
      setIsLoading(false);
    }
  }, [axios, formData, lifecycleTemplate._id, onError]);

  useEffect(() => {
    const allValid = Object.values(validInputs).every((isValid) => isValid);
    setIsFormValid(Object.values(validInputs).length > 0 && allValid);
  }, [validInputs]);

  const paginationComponent = useMemo(() => (
    matchCount > paginationLocal.limit
      ? <Pagination
        count={Math.ceil(matchCount / paginationLocal.limit)}
        disabled={isLoading}
        onChange={(_, page) => {
          setPaginationLocal({ limit: paginationLocal.limit, skip: paginationLocal.limit * (page - 1) });
          scrollRef.current && scrollIntoView(scrollRef.current, { behavior: 'smooth', block: 'start' });
        }}
        renderItem={(item) => (
          <PaginationItem data-testid={testid`pagination-item-${item.type}${item.type === 'page' ? `-${item.page ?? ''}` : ''}`}
            {...item}
          />
        )}
        page={Math.floor(paginationLocal.skip / paginationLocal.limit) + 1}
        showFirstButton={!smallScreen}
        showLastButton={!smallScreen}
      />
      :
      <></>
  ), [matchCount, paginationLocal.limit, paginationLocal.skip, isLoading, smallScreen]);

  const RenderSharedThingList: FC = () => {
    if (searching || isLoading) return <MiddleSpinner />;

    if (searchClicked && !sharedThings.length && Object.values(validInputs).length > 0) {
      return (
        <>
          {emptyState}
        </>
      );
    }

    return (
      <>
        <Box mt={2} sx={{ overflow: 'auto', height: '30vh' }}>
          {currentPageSharedThings.map((sharedThing) => (
            <SharedThingCard
              sharedThing={sharedThing}
              key={sharedThing._id}
              onSelectedSharedThing={handleSelectedSharedThing}
              showDetails={sharedThing._id === selectedSharedThing?._id}
              sharedThingTypeVehicleDefinitions={sharedThingTypeVehicleDefinitions}
            />
          ))}
        </Box>
        {paginationComponent}
      </>
    );
  };

  const handleClearInput = (name: string): void => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [name]: ignored, ...newFormData } = formData;
    setFormData(newFormData);
    setValidInputs((prev) => {
      const newValid = { ...prev };
      delete newValid[name];
      return newValid;
    });
    if (Object.keys(newFormData).length === 0) {
      setSharedThings([]);
      setMatchCount(0);
    }
    setSelectedSharedThing(undefined);
    setNextDisabled(true);
    setSearching(false);
    setIsLoading(false);
    setSearchClicked(false);
  };

  const renderClaimFields = (
    Object.keys(claimProperties)
      .filter((_, index) => index === 0 || (index > 0 && showMore))
      .map((key) => (
        <TextField
          label={sharedThingTypeVehicleDefinitions?.[key]?.label || key}
          name={key}
          type="text"
          size="small"
          key={key}
          variant="outlined"
          fullWidth
          value={formData[key] || ''}
          onChange={handleInputChange}
          inputProps={{ ...claimProperties[key] as unknown as InputBaseComponentProps }}
          InputProps={{
            endAdornment: formData[key] ? (
              <InputAdornment position="end">
                <IconButton
                  aria-label="clear input"
                  onClick={() => handleClearInput(key)}
                  edge="end"
                >
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ) : null,
          }}
          error={validInputs[key] === false}
          helperText={
            validInputs[key] === false
              ? t('common:component.shared-thing-card.hint.invalid-search-input', {
                display: sharedThingTypeVehicleDefinitions?.[key]?.label ?? key,
              })
              : undefined
          }
        />
      ))
  );

  const renderShowMore = (
    Object.keys(claimProperties).length > 1 && (
      <Button
        data-testid="button-show-more"
        onClick={handleShowMoreToggle}
        size="medium"
        variant="text"
      >
        {showMore
          ? t('admin:page.thing-detail-update-stage-dialog.show-less.labels')
          : t('admin:page.thing-detail-update-stage-dialog.show-more.labels')}
      </Button>
    )
  );

  const handleNext = useCallback(() => {
    if (selectedSharedThing) {
      setShowConfirmDialog(true);
    }
  }, [selectedSharedThing]);

  const handleConfirmClose = useCallback(() => {
    setShowConfirmDialog(false);
    setSelectedSharedThing(undefined);
  }, []);

  const handleConfirmSuccess = useCallback(() => {
    onSelfClaimDialogNext();
  }, [onSelfClaimDialogNext]);

  const actions = useMemo(() => (
    <>
      <Button
        data-testid="self-claim-dialog-cancel-button"
        onClick={onSelfClaimDialogClose}
        size="medium"
        variant="outlined"
      >
        {t('common:common.action.cancel')}
      </Button>
      <Button
        data-testid="self-claim-dialog-next-button"
        disabled={nextDisabled}
        onClick={handleNext}
        size="medium"
        variant="contained"
      >
        {t('common:common.action.next')}
      </Button>
    </>
  ), [handleNext, nextDisabled, onSelfClaimDialogClose, t]);

  const { targetStage, useCurrentStakeholder, currentUserRole } = useMemo(
    () => determineTargetStageWithRole(accountType, lifecycleTemplate, selectedSharedThing),
    [accountType, lifecycleTemplate, selectedSharedThing],
  );

  const lifeCycleState: ThingLifeCycleStateResponse = useMemo(() => {
    const stakeholders: Record<string, ThingLifeCycleStateResponseStakeholder | null> = {};
    if (useCurrentStakeholder && currentUserRole) {
      const currentStakeholders = selectedSharedThing?.lifecycleState?.stakeholders;
      const currentStakeholderId = currentStakeholders?.[currentUserRole];
      if (currentStakeholderId) {
        stakeholders[currentUserRole] = { _id: currentStakeholderId, display: currentStakeholderId };
      }
    }
    return {
      stageId: selectedSharedThing?.lifecycleState?.stageId || '',
      stakeholders,
      devices: selectedSharedThing?.lifecycleState?.devices || {},
      deviceChecks: selectedSharedThing?.lifecycleState?.deviceChecks,
      lifecycleTemplateId: lifecycleTemplate._id,
      stageDisplay: selectedSharedThing?.lifecycleState?.stageId || '',
      severity: 0,
    };
  }, [selectedSharedThing, lifecycleTemplate, useCurrentStakeholder, currentUserRole]);

  return (
    <>
      <Dialog
        actions={actions}
        data-testid="self-claim-dialog"
        fullWidth
        onClose={onSelfClaimDialogClose}
        open={Boolean(open && !showConfirmDialog)}
        scroll={true}
        title={t('admin:page.transfers-list.update-stage-dialog.self-claim.vehicle-search.title')}
      >
        <Card data-testid="self-claim-search-card">
          <CardContent>
            <Stack spacing={2} useFlexGap>
              {renderClaimFields}
              {renderShowMore}
              <Button
                data-testid="button-search"
                disabled={!isFormValid}
                onClick={handleSearch}
                size="medium"
                variant="contained"
              >
                {t('common:component.search.labels.label')}
              </Button>
            </Stack>
          </CardContent>
        </Card>

        <RenderSharedThingList />
      </Dialog>

      {selectedSharedThing && (
        <StageConfirmDialog
          config={{
            isSelfClaim: true,
            selectedSharedThing,
            simplifiedAccountSelect: useCurrentStakeholder,
            skipStakeholderSelection: useCurrentStakeholder,
            stakeholderRolesToDisplay: [currentUserRole ?? ''],
            forceEditableStakeholderSelect: true,
          }}
          dialogTitle={t('admin:page.transfers-list.update-stage-dialog.self-claim.vehicle-search.title')}
          stageId={targetStage || ''}
          lifecycleTemplate={lifecycleTemplate}
          lifeCycleState={lifeCycleState}
          onClose={onSelfClaimDialogClose}
          onBack={handleConfirmClose}
          onSuccess={handleConfirmSuccess}
          open={showConfirmDialog}
          stakeholderRoles={[currentUserRole ?? '']}
          sharedThingId={selectedSharedThing._id}
          editableStakeholders={[]}
        />
      )}
    </>
  );
};

