import { Geofence } from '@eagle/core-data-types';
import { Divider, Link, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Popup } from 'react-leaflet';
import { useCustomRoutes } from '../../../../hooks';
import { makeStyles } from '../../../../theme';
import { MapPanes } from '../../../../types';

interface Props {
  geofences: Geofence[];
  handleMouseOut: () => void;
  handleMouseOver: (id: string) => void;
}

const useStyles = makeStyles()({
  contextMenu: {
    zIndex: 700,
    '&:before': { display: 'none' },
    '.leaflet-popup-content p': { margin: 0 },
    '.leaflet-popup-content-wrapper': { padding: 0 },
    '.leaflet-popup-tip': { display: 'none' },
  },
});

export const GeofenceContextMenu: FC<Props> = ({ geofences, handleMouseOut, handleMouseOver }) => {
  const { classes } = useStyles();
  const { t } = useTranslation(['common']);
  const { geofence: customRoutesGeofence } = useCustomRoutes();
  const path = `/admin/#/${customRoutesGeofence}/`;

  return (
    <Popup
      className={classes.contextMenu}
      closeButton={false}
      minWidth={150}
      pane={MapPanes.POPUP_PANE}
    >
      <Stack spacing={0.5} sx={{ py: 1 }}>
        <Typography sx={{ px: 1 }} variant="body2">{t('common:component.map.hint.select-geofence')}</Typography>
        <Divider />
        {geofences.map(({ _id, display }) =>
          <Link
            key={_id}
            href={`${path}${_id}`}
            onMouseEnter={() => handleMouseOver(_id)}
            onMouseLeave={handleMouseOut}
            sx={{
              overflow: 'hidden',
              px: 1,
              textDecoration: 'none',
              textOverflow: 'ellipsis',
            }}
            variant="body2"
          >
            {display}
          </Link>,
        )}
      </Stack>
    </Popup>
  );
};
