import { ServiceMetric, ServiceType } from '@eagle/core-data-types';
import { Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { testid } from '../../util';
import { useBoolFlag, useNumberFlag } from '../flags';
import { FormatDate, FormatUnit } from '../format';
import { ListPaperColumn } from '../list-item';
import { NextServiceHistoryDisplay } from './next-service-history';
import { ServiceHistoryDialog } from './service-history-dialog';
import { ServiceCenterDisplay } from './service-history-service-center';
import { ServiceHistoryExtended } from './service-history.types';

interface Props {
  item: ServiceHistoryExtended;
  handleClose: () => void;
  open: boolean;
  serviceMetrics: ServiceMetric[];
  serviceTypes: ServiceType[];
  templateHasServiceCenter: boolean;
}

export const ServiceHistoryDetailsDialog: FC<Props> = ({ item, handleClose, open, serviceTypes, serviceMetrics, templateHasServiceCenter }) => {
  const { t } = useTranslation(['common']);
  const hasServicedByFeature = useBoolFlag('portals-servicing-module-serviced-by-feature');
  const displayServiceTypeFlag = useBoolFlag('portals-servicing-module-display-service-type-details-feature-temporary');
  const isNextServiceEnabled = useBoolFlag('portals-servicing-module-next-service-feature');
  const numberOfLinesServiceType = useNumberFlag('portals-servicing-module-display-service-type-details-number-of-lines-feature-temporary');

  return (
    <ServiceHistoryDialog
      data-testid="view-service-details-dialog"
      title={t('common:page.thing-detail.service-history.service-history-title.labels')}
      fullWidth
      maxWidth="xs"
      onClose={handleClose}
      open={open}
      hasCloseBtn={true}
    >
      <Stack spacing={2} direction="column" sx={{ mb: 1 }}>
        {hasServicedByFeature && templateHasServiceCenter
          && <ListPaperColumn label={t('common:page.thing-detail.service-history-dialog.serviced-by.labels')} data-testid={'serviced-by-display'}>
            {item.servicedBy ? <ServiceCenterDisplay accountId={item.servicedBy} sharedThingId={item.sharedThingId} /> : <Typography>{t('common:page.thing-detail.service-history-dialog.serviced-by-third-party.labels')}</Typography>}
          </ListPaperColumn>
        }
        <ListPaperColumn label={t('common:page.thing-detail.service-history.service-type.labels')}>{item.serviceTypeDisplay}</ListPaperColumn>
        {displayServiceTypeFlag && item.serviceTypeDetails
          && <ListPaperColumn label={t('common:page.thing-detail.service-history.service-type-details.labels')}>
            <Typography
              sx={{
                overflowWrap: 'anywhere',
                whiteSpace: 'pre-line',
                ...(numberOfLinesServiceType
                  ? {
                    display: '-webkit-box',
                    overflow: 'hidden',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: numberOfLinesServiceType,
                  }
                  : {}
                ),
              }}
            >
              {item.serviceTypeDetails}
            </Typography>
          </ListPaperColumn>
        }
        <ListPaperColumn label={t('common:page.thing-detail.service-history.date.labels')}><FormatDate value={item.occurred} /></ListPaperColumn>
        {renderMetrics(item.thingServiceMetrics)}
        {item.details &&
          <ListPaperColumn label={t('common:page.thing-detail.service-history-dialog.service-details.label')} wrap>
            <Typography>{item.details}</Typography>
          </ListPaperColumn>
        }
      </Stack>
      {isNextServiceEnabled &&
        <NextServiceHistoryDisplay item={item} serviceTypes={serviceTypes} serviceMetrics={serviceMetrics} />}
    </ServiceHistoryDialog>
  );
};

export const renderMetrics = (metrics: Array<{ serviceMetricId: string; display: string; value: number | string; unit: string }>): React.ReactNode => {
  const rendered = metrics.map((metric, i) => (
    metric.value !== 0 && (
      <ListPaperColumn key={i} wrap={true} data-testid={testid`service-details-metric-${metric.serviceMetricId}`} label={metric.display} sx={{ overflow: 'hidden', '& > label': { textTransform: 'capitalize' } }}>
        <FormatUnit value={metric.value} unit={metric.unit} />
      </ListPaperColumn>
    )
  ));
  return !rendered.length ? <></> : rendered;
};
