/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import { RoleFunction } from '@eagle/common';
import { AlertResponse } from '@eagle/core-data-types';
import { FC, ReactNode, useEffect, useMemo } from 'react';
import { from, of, Subject } from 'rxjs';
import { catchError, debounceTime, finalize, switchMap, tap } from 'rxjs/operators';
import { useAuthenticated } from '../../auth';
import { useApiErrorHandler, useObservable } from '../../hooks';
import { Query } from '../../pages/list/types';
import { SearchProvider, useSearch } from '../../pages/list/use-search';
import { CacheDataTypes } from '../../types';
import { Pagination } from '../entity-search/types';
import { NoPermissionsCardBody } from '../permissions';
import { FindItemsDeferredPaginatedResponse } from './types';
import { AlertsTableView } from './view';

export interface Props {
  onQueryChanged: (query: Query, pagination: Pagination, isDaysLimit: boolean) => FindItemsDeferredPaginatedResponse<AlertResponse>;
  renderTitle?: (alerts: AlertResponse[], matchCount: number) => ReactNode;
  showFeature?: boolean;
  showGroups?: boolean;
  showPerson?: boolean;
  showThing?: boolean;
  smallTable?: boolean;
  isRecentAlertsView: boolean;
  updateIsRecentAlertsView: (val: boolean) => void;
}

const InternalAlertsTableController: FC<Props> = ({
  onQueryChanged,
  showPerson = true,
  showThing = true,
  showFeature = true,
  showGroups = true,
  renderTitle,
  smallTable,
  isRecentAlertsView,
  updateIsRecentAlertsView,
}): JSX.Element => {
  const {
    filters,
    isLoading,
    pagination,
    result,
    setIsLoading,
    setPagination,
    setResult,
    text,
  } = useSearch();
  const { userInfo } = useAuthenticated();
  const { handleGetError } = useApiErrorHandler();
  const alertPermissions = userInfo.hasRoleFunction(RoleFunction.ALERT_VIEWER);

  if (!alertPermissions) return <NoPermissionsCardBody data-testid='no-permissions-alerts-table' />;

  const onError = (error: Error): void => {
    handleGetError(error, CacheDataTypes.ALERT, undefined, error.message);
  };

  const handleShowMore = (): void => {
    updateIsRecentAlertsView(false);
  };

  const { handleQueryChanged, observable } = useMemo(() => {
    const subject = new Subject<Query>();

    return {
      handleQueryChanged: (query: Query): void => subject.next(query),
      observable: subject.pipe(
        tap(() => setIsLoading(true)),
        debounceTime(350),
        switchMap((query: Query) => {
          const deferred = onQueryChanged(query, pagination, isRecentAlertsView);
          if (!deferred) return of(undefined);

          let complete = false;

          return from(deferred.promise).pipe(
            tap(({ count, items }) => {
              setResult({ itemCount: items.length, matchCount: count ?? 0 });
              complete = true;
            }),
            catchError((err: Error) => {
              onError(err);
              return of(undefined);
            }),
            finalize(() => complete || deferred.cancel()),
          );
        }),
        tap(() => setIsLoading(false)),
      ),
    };
  }, [onQueryChanged, pagination, isRecentAlertsView]);
  const data = useObservable(observable, onError);

  useEffect(() => {
    handleQueryChanged({ filters, pagination, search: text });
  }, [filters, pagination, text, isRecentAlertsView]);

  return (
    <AlertsTableView
      alerts={data?.items || []}
      isLoading={isLoading}
      matchCount={result?.matchCount ?? 0}
      pagination={pagination}
      renderTitle={renderTitle}
      setPagination={setPagination}
      showGroups={showGroups}
      showPerson={showPerson}
      showThing={showThing}
      showFeature={showFeature}
      smallTable={smallTable}
      handleShowMore={handleShowMore}
      isRecentAlertsView={isRecentAlertsView}
    />
  );
};

export const AlertsTableController = (props: Props): JSX.Element => {
  return (
    <SearchProvider dataKey="alert-table" pagination={{ limit: 5, skip: 0 }}>
      <InternalAlertsTableController {...props} />
    </SearchProvider>
  );
};
