import { Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { StaticDateTimePicker as BaseStaticDateTimePicker, TimeStepOptions } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';

import { ElementType, FC, useEffect, useState } from 'react';
import { Nullable } from '../../types';
import { CalendarIcon as DefaultCalendarIcon } from '../icons';

interface StaticDateTimePickerProps {
  defaultValue?: DateTime | null;
  futureDates?: boolean;
  icon?: ElementType;
  label?: string;
  onAccept?: ((value: DateTime | null) => void);
  onClose?: () => void;
  timeSteps?: TimeStepOptions;
  minDateTime?: DateTime;
  maxDateTime?: DateTime;

}

export const StaticDateTimePicker: FC<StaticDateTimePickerProps> = ({
  defaultValue = null,
  futureDates,
  icon,
  label,
  onAccept,
  onClose,
  timeSteps,
  minDateTime,
  maxDateTime,
}) => {
  const [value, setValue] = useState<Nullable<DateTime>>(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const CalendarIcon = icon ?? DefaultCalendarIcon;
  return (
    <Stack>
      <Box sx={{ p: 2 }}>
        {label && <Typography variant='caption'>{label}</Typography>}
        <Typography variant='h5'>{value?.toFormat('MMM d, yyyy hh:mm a') ?? '--'}</Typography>
      </Box>
      <Box>
        <BaseStaticDateTimePicker
          ampm
          disableFuture={!futureDates}
          minutesStep={timeSteps?.minutes}
          onAccept={onAccept}
          onChange={setValue}
          onClose={onClose}
          minDateTime={minDateTime}
          maxDateTime={maxDateTime}
          slotProps={{
            toolbar: {
              hidden: true,
            },
            tabs: {
              dateIcon: <CalendarIcon />,
            },
          }}
          value={value}
        />
      </Box>
    </Stack>
  );
};
