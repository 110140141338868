import { JsonObject } from '@eagle/common';
import { AccountType, InitialUser } from '@eagle/core-data-types';
import { Card, Stack, Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { T_ONE } from '../../constants';
import { testid } from '../../util';
import { DataRow, PropertiesRows } from '../static-data';

interface StakeHolderAccountCardProps {
  accountType?: AccountType;
  display: string;
  properties: JsonObject | null;
  role: string;
  initialUsers: InitialUser[] | null;
}

export const StakeHolderAccountCard: FC<StakeHolderAccountCardProps> = ({ accountType, display, properties, role, initialUsers }) => {
  const { t } = useTranslation(['common']);
  const theme = useTheme();
  return (
    <Card sx={{ p: 1, backgroundColor: theme.palette.grey[100] }}>
      <DataRow
        data-testid={testid`${role}-selected-account-display`}
        label={t('common:common.labels.display')}
        value={display}
      />
      {accountType && <PropertiesRows properties={properties ?? {}} propertyDefinitions={accountType.properties} />}
      {initialUsers && <Stack sx={{ py: 1 }}>
        <Typography variant="dataCardTitle" sx={{ px: 1, pb: 0 }}> {t('common:page.thing-details.update-stage.hint.initial-users')}</Typography>
        {initialUsers?.length ? <>
          {initialUsers.map((user, index) => (
            <DataRow
              key={`initial-user-${index}`}
              data-testid={testid`${role}-selected-account-initial-user-${index}`}
              description={t('common:terms.user', { count: T_ONE })}
              label={user.display}
              value={user.email}
            />
          ))}
        </>
          : <Typography variant="body1" sx={{ px: 1 }}> {t('common:page.thing-details.update-stage.hint.no-users-supplied')}</Typography>
        }
      </Stack>
      }
    </Card>
  );
};
