import { Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { compact } from 'lodash';
import { FC, useMemo } from 'react';
import { useOptionalSearch } from '../../pages/list/use-search';

export const BoldMatchedText: FC<{ text: string; query?: string; sx?: SxProps<Theme> }> = ({ text, query, sx }) => {
  const searchContext = useOptionalSearch();
  const searchString = query || searchContext?.text || '';

  const terms = useMemo(() => searchString.trim().split(/\s+/g).filter((t) => t), [searchString]);

  const { splitRegex, regex } = useMemo(() => {
    const escapedTerms = terms.map((term) => term.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'));
    return {
      splitRegex: new RegExp(`(${escapedTerms.join('|')})`, 'gi'),
      regex: new RegExp(`^(${escapedTerms.join('|')})$`, 'i'),
    };
  }, [terms]);

  if (!text) return <></>;
  if (terms.length === 0) return <>{text}</>;

  const stringArray = compact(text.split(splitRegex));

  return (
    <Typography component="span" noWrap>
      {stringArray.map((matches, i) => (
        <Typography
          component="span"
          key={`${matches}-${i}-${matches.length}`}
          sx={{ fontWeight: regex.test(matches) ? '800' : 'inherit', ...sx }}
        >
          {matches}
        </Typography>
      ))}
    </Typography>
  );
};
