import { IconButton, Tooltip, TooltipProps } from '@mui/material';
import { FC, useState } from 'react';
import { useSmallScreen } from '../../hooks';

interface IconWithTooltipProps extends Omit<TooltipProps, 'children'> {
  'data-testid'?: string;
  icon: JSX.Element;
}

export const IconWithTooltip: FC<IconWithTooltipProps> = ({
  icon,
  ...rest
}) => {
  const smallScreen = useSmallScreen();
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => smallScreen && setTooltipOpen((prev) => !prev);

  return (
    <Tooltip
      {...rest}
      disableFocusListener={smallScreen}
      disableTouchListener={smallScreen}
      {...(smallScreen && {
        open: tooltipOpen,
        onOpen: () => setTooltipOpen(true),
        onClose: () => setTooltipOpen(false),
        slotProps: { popper: { disablePortal: true } },
      })}
    >
      <IconButton disableRipple onClick={toggleTooltip} sx={{ '&:hover': { backgroundColor: 'transparent' } }}>
        {icon}
      </IconButton>
    </Tooltip>
  );
};
