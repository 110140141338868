import { StakeholderAccountResult } from '@eagle/core-data-types';
import { FC } from 'react';
import { useAuthenticated } from '../../auth';
import { usePromise } from '../../hooks';
import { ErrorMessage } from '../error-message';
import { useStakeholderAccountTypeQuery } from '../life-cycle-stage-menu/life-cycle.utils';
import { MiddleSpinner } from '../middle-spinner';
import { StakeHolderAccountCard } from './stakeholder-account-card';
import { StakeholderCardBase } from './stakeholder-card-base';

interface StakeholderConfirmCardProps {
  accountId: string;
  hideRole?: boolean;
  role: string;
  sharedThingId: string;
}

export const StakeholderConfirmCard: FC<StakeholderConfirmCardProps> = ({ accountId, hideRole, role, sharedThingId }) => {
  const { restClient } = useAuthenticated();

  const [account, accountError, accountStatus] = usePromise<StakeholderAccountResult>(
    async () => {
      return restClient.sharedThing.getStakeholderAccount(sharedThingId, role, accountId);
    },
    [sharedThingId, role, accountId, restClient],
  );

  const { data: accountType, isLoading: isAccountTypeLoading } = useStakeholderAccountTypeQuery(sharedThingId, role, accountId, !!account?.accountTypeId);

  if (accountStatus === 'pending' || isAccountTypeLoading) {
    return <MiddleSpinner />;
  }

  if (accountError) {
    return <ErrorMessage error={accountError || new Error('Unknown error')} />;
  }

  return (
    <StakeholderCardBase hideRole={hideRole} role={role}>
      <StakeHolderAccountCard
        accountType={accountType}
        display={account.display}
        properties={account.properties}
        role={role}
        initialUsers={null}
      />
    </StakeholderCardBase>
  );
};
