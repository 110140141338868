import { ServiceHistoryUpdatableProps, ServiceMetric, ServiceType } from '@eagle/core-data-types';
import { Stack, TextField, Typography } from '@mui/material';
import { isUndefined } from 'lodash';
import { DateTime } from 'luxon';
import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_TIME_ZONE } from '../../constants';
import { Nullable, SetState, Undefinable } from '../../types';
import { DateErrors, DateTimeRangeError } from '../../util';
import { DatePicker } from '../date-time-range-picker/date-picker';
import { useBoolFlag, useNumberFlag } from '../flags';
import { FormatDate } from '../format';
import { ListPaperColumn } from '../list-item';
import { renderMetrics } from './service-history-details-dialog';
import { ServiceHistoryExtended } from './service-history.types';
import { ServiceMetricFields } from './service-metric-fields';
import { ServiceTypeSelect } from './service-type-select';
import { useNextServiceQuery } from './service.utils';
import { NextServiceData } from './update-create-service-history-dialog';

interface Props {
  item?: ServiceHistoryExtended;
  'data-testid'?: string;
  serviceMetrics: ServiceMetric[];
  serviceTypes: ServiceType[];
  setValid: SetState<boolean>;
  setServiceHistoryData: SetState<{
    currentService: ServiceHistoryUpdatableProps;
    nextService: NextServiceData;
    isNextServiceExist: boolean;
  }>;
  nextServiceData: NextServiceData;
  isNextServiceOpen: boolean;
}

const CHARACTER_LIMIT = 200;

export const NextServiceHistory: FC<Props> = ({
  item,
  serviceMetrics,
  serviceTypes,
  setValid,
  setServiceHistoryData,
  nextServiceData,
  isNextServiceOpen,
}) => {
  const { t } = useTranslation(['common', 'admin']);
  const isNewService = !item;
  const [dateError, setDateError] = useState<Undefinable<string>>(undefined);
  const [metricsErrors, setMetricsErrors] = useState<Record<string, string>>({});

  const { data: nextService, isLoading } = useNextServiceQuery({ serviceHistoryId: item?.serviceHistoryId || '' }, !isNewService);

  const handleDateChange = (date: Nullable<DateTime>): void => {
    setDateError(undefined);
    if (!date) {
      setDateError(DateTimeRangeError.INVALID_DATE);
      return;
    }

    if (date.invalidReason) {
      switch (date.invalidReason) {
        case DateErrors.UNPARSABLE:
          setDateError(DateTimeRangeError.INVALID_FORMAT);
          break;
        default:
          setDateError(DateTimeRangeError.INVALID_DATE);
          break;
      }
      return;
    }

    if (date.isValid && date < DateTime.now()) {
      setDateError(DateTimeRangeError.PAST_DATE);
      return;
    }

    setDateError(undefined);
    setServiceHistoryData((prev) => ({
      ...prev,
      nextService: {
        ...prev.nextService,
        occurred: date ? date.toJSDate() : null,
      },
    }));
  };

  const handleMetricChange = (metricId: string, value: string | number | undefined): void => {
    setServiceHistoryData((prev) => ({
      ...prev,
      nextService: {
        ...prev.nextService,
        serviceMetrics: {
          ...prev.nextService.serviceMetrics,
          [metricId]: value !== '' ? value : undefined,
        },
      },
    }));
  };

  useEffect(() => {
    const processedMetrics = nextService?.serviceMetrics ? Object.keys(nextService.serviceMetrics).reduce<Record<string, number>>((acc, metricId) => {
      if (item?.thingServiceMetrics.some((m) => m.serviceMetricId === metricId)) {
        acc[metricId] = nextService.serviceMetrics[metricId] as number;
      }
      return acc;
    }, {}) : {};

    setServiceHistoryData((prev) => ({
      ...prev,
      nextService: {
        serviceTypeId: nextService?.serviceTypeId || '',
        details: nextService?.details || '',
        occurred: nextService?.occurred ? new Date(nextService.occurred) : null,
        serviceMetrics: processedMetrics,
      },
      isNextServiceExist: !!nextService,
    }));
    setMetricsErrors({});
    setDateError(undefined);
  }, [nextService, item, isNextServiceOpen, setServiceHistoryData]);

  useEffect(() => {
    setValid(!Object.values(metricsErrors).some((error) => error !== '') && !dateError);
  }, [nextServiceData, metricsErrors, dateError]);

  useEffect(() => {
    setMetricsErrors({});
    setDateError(undefined);
  }, []);

  return (
    <Stack spacing={2} sx={{ pt: 2, width: 1, borderTop: 1, borderColor: 'divider' }}>
      <Typography variant="h6" sx={{ pb: 1 }} data-testid="next-service-title">{t('common:page.thing-detail.service-history-dialog.next-service-title.labels')}</Typography>
      <Stack spacing={2} sx={{ filter: isLoading ? 'blur(1px)' : '', opacity: isLoading ? 0.66 : 1 }}>
        <ServiceTypeSelect
          serviceTypes={serviceTypes}
          onChange={(value) => {
            setServiceHistoryData((prev) => ({
              ...prev,
              nextService: {
                ...prev.nextService,
                serviceTypeId: value,
              },
            }));
          }}
          value={nextServiceData.serviceTypeId}
        />
        <DatePicker
          key="next-service-date-picker"
          data-testid="next-service-date-picker"
          label={t('common:page.thing-detail.service-history-dialog.date-of-service.labels')}
          size="small"
          date={isUndefined(nextServiceData.occurred) || nextServiceData.occurred === null ? null : DateTime.fromISO(String(DateTime.fromJSDate(nextServiceData.occurred)), { zone: DEFAULT_TIME_ZONE })}
          disablePast
          onChange={handleDateChange}
          placeholder={isUndefined(nextServiceData.occurred) ? t('common:component.properties.hint.not-set') : ''}
          PopperProps={{ disablePortal: true }}
          DialogProps={{ disablePortal: true }}
          required
          helperText={dateError && <Typography variant="caption" sx={{ color: 'red' }}>{t(`common:component.date-picker.hint.${dateError}`)}</Typography>}
          dateError={dateError}
          desktopModeMediaQuery='@media (min-width: 0px)'
        />
        <ServiceMetricFields
          onChange={handleMetricChange}
          serviceMetricValues={nextServiceData.serviceMetrics}
          serviceMetrics={serviceMetrics}
          setMetricsErrors={setMetricsErrors}
        />
        <TextField
          label={t('common:page.thing-detail.service-history-dialog.enter-service-details.labels')}
          multiline
          rows={5}
          value={nextServiceData.details}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value;
            setServiceHistoryData((prev) => ({
              ...prev,
              nextService: {
                ...prev.nextService,
                details: value,
              },
            }));
          }}
          data-testid="next-service-details-input"
          helperText={`${nextServiceData.details?.length || 0}/${CHARACTER_LIMIT}`}
          inputProps={{ maxLength: CHARACTER_LIMIT }}
        />
      </Stack>
    </Stack>
  );
};

interface DisplayProps {
  item: ServiceHistoryExtended;
  serviceTypes: ServiceType[];
  serviceMetrics: ServiceMetric[];
}

export const NextServiceHistoryDisplay: FC<DisplayProps> = ({ item, serviceTypes, serviceMetrics }) => {
  const { t } = useTranslation(['common', 'admin']);
  const displayServiceTypeFlag = useBoolFlag('portals-servicing-module-display-service-type-details-feature-temporary');
  const numberOfLinesServiceType = useNumberFlag('portals-servicing-module-display-service-type-details-number-of-lines-feature-temporary');
  const { data: nextService, isLoading } = useNextServiceQuery({ serviceHistoryId: item.serviceHistoryId }, true);

  const thingNextServiceMetrics = useMemo(() => {
    return serviceMetrics.map((metric) => ({
      serviceMetricId: metric.serviceMetricId,
      display: metric.display,
      type: metric.type,
      unit: metric.unit,
      value: (nextService?.serviceMetrics[metric.serviceMetricId] || 0) as string | number,
    }));
  }, [serviceMetrics, nextService]);

  if (!nextService) return <></>;

  const serviceType = serviceTypes.find((type) => type.serviceTypeId === nextService.serviceTypeId);

  return (
    <Stack spacing={2} sx={{ mt: 2, pt: 2, width: 1, borderTop: 1, borderColor: 'divider' }} data-testid="next-service-card">
      <Typography variant="h6" data-testid="next-service-title">{t('common:page.thing-detail.service-history-dialog.next-service-title.labels')}</Typography>
      <ListPaperColumn label={t('common:page.thing-detail.service-history.service-type.labels')}>{serviceType?.display}</ListPaperColumn>
      <Stack spacing={2} sx={{ filter: isLoading ? 'blur(1px)' : '', opacity: isLoading ? 0.66 : 1 }}>
        {displayServiceTypeFlag && item.serviceTypeDetails
          && <ListPaperColumn label={t('common:page.thing-detail.service-history.service-type-details.labels')}>
            <Typography
              sx={{
                overflowWrap: 'anywhere',
                whiteSpace: 'pre-line',
                ...(numberOfLinesServiceType
                  ? {
                    display: '-webkit-box',
                    overflow: 'hidden',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: numberOfLinesServiceType,
                  }
                  : {}
                ),
              }}
            >
              {serviceType?.details}
            </Typography>
          </ListPaperColumn>
        }
        <ListPaperColumn label={t('common:page.thing-detail.service-history.date.labels')}><FormatDate value={nextService.occurred} /></ListPaperColumn>
        {renderMetrics(thingNextServiceMetrics)}
        {item.details &&
          <ListPaperColumn label={t('common:page.thing-detail.service-history-dialog.service-details.label')} wrap>
            <Typography>{nextService.details}</Typography>
          </ListPaperColumn>
        }
      </Stack>
    </Stack>
  );
};
