import { LifecycleTemplate, Thing, ThingLifeCycleStateResponse, ThingLifeCycleStateResponseStakeholder } from '@eagle/core-data-types';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SERVICE_CENTER_ROLE } from '../../constants';
import { useSwitchAwareConfig } from '../../hooks';
import { FieldLoading } from '../field';
import { useSharedThingNextStagesQuery, useStakeholderAccountQuery } from '../life-cycle-stage-menu';
import { StageConfirmDialog } from '../life-cycle-stage-menu/stage-confirm-dialog';
import { DataRow } from '../static-data';

interface ServiceHistoryServiceCenterProps {
  lifeCycleTemplate: LifecycleTemplate;
  onChanged?: VoidFunction;
  currentStageId: string;
  serviceCenterAccount: ThingLifeCycleStateResponseStakeholder | null | undefined;
  stakeholderRoles: string[];
  thing: Thing;
  sharedThingId: string;
  lifeCycleState: ThingLifeCycleStateResponse;
}

export const ServiceHistoryServiceCenter: FC<ServiceHistoryServiceCenterProps> = ({
  lifeCycleTemplate,
  onChanged,
  stakeholderRoles,
  thing,
  currentStageId,
  serviceCenterAccount,
  sharedThingId,
  lifeCycleState,
}) => {
  const { t } = useTranslation(['common']);
  const [isOpen, setIsOpen] = useState(false);
  const { productName } = useSwitchAwareConfig();
  const theme = useTheme();

  const templateHasServiceCenterStakeholder = lifeCycleTemplate.stakeholderAccounts[SERVICE_CENTER_ROLE];
  if (!templateHasServiceCenterStakeholder) {
    return null;
  }

  const currentStage = lifeCycleTemplate.stages.find((stage) => stage.stageId === currentStageId);
  if (!currentStage) {
    return null;
  }

  const { data: nextStages } = useSharedThingNextStagesQuery(sharedThingId, !!thing.sharedThingId);
  const currentStageData = nextStages?.find((stage) => stage.stageId === currentStageId);
  const editableStakeholders = currentStageData?.editableStakeholders || [];

  const isServiceCenterEditable = editableStakeholders?.includes(SERVICE_CENTER_ROLE) || false;
  const isButtonHidden = !isServiceCenterEditable;

  return (
    <>
      <Box px={3}>
        <DataRow
          data-testid="service-center"
          label={t('common:page.thing-detail.service-history.service-center.label.labels')}
          action={isButtonHidden ? undefined : (
            <Button
              data-testid="change-service-center-button"
              onClick={() => { setIsOpen(true); }}
            >
              {t('common.action.change')}
            </Button>
          )}
          value={!serviceCenterAccount ? (
            <Typography component="span" sx={{ color: theme.palette.text.disabled, fontStyle: 'italic' }}>
              {t('common:page.thing-detail.service-history.service-center.none.hint')}
            </Typography>
          ) : (
            <Typography component="span">{serviceCenterAccount.display}</Typography>
          )}
        />
      </Box>
      <ServiceCenterStageConfirmDialog
        editableStakeholders={editableStakeholders || []}
        isOpen={isOpen}
        lifecycleTemplate={lifeCycleTemplate}
        lifeCycleState={lifeCycleState}
        onSuccess={onChanged}
        productName={productName}
        setIsOpen={setIsOpen}
        stageId={currentStageId}
        stakeholderRoles={stakeholderRoles}
        sharedThingId={sharedThingId}
        thing={thing}
      />
    </>
  );
};

interface ServiceCenterStageConfirmDialogProps {
  editableStakeholders: string[];
  isOpen: boolean;
  lifecycleTemplate: LifecycleTemplate;
  lifeCycleState: ThingLifeCycleStateResponse;
  onSuccess?: VoidFunction;
  productName: string | undefined;
  setIsOpen: (isOpen: boolean) => void;
  sharedThingId: string;
  stageId: string;
  stakeholderRoles: string[];
  thing: Thing;
}

export const ServiceCenterStageConfirmDialog: FC<ServiceCenterStageConfirmDialogProps> = ({
  editableStakeholders,
  isOpen,
  lifecycleTemplate,
  lifeCycleState,
  onSuccess,
  productName,
  setIsOpen,
  sharedThingId,
  stageId,
  stakeholderRoles,
  thing,
}) => {
  const { t } = useTranslation(['common']);

  return (
    <StageConfirmDialog
      dialogTitle={t('common:page.thing-detail.service-history.service-center.dialog-title.labels')}
      lifecycleTemplate={lifecycleTemplate}
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      onSuccess={onSuccess}
      config={{
        renderConfirmation: (state) => {
          const serviceCenterStakeholder = state.stakeholders.find((stakeholder) => stakeholder.role === SERVICE_CENTER_ROLE);
          if (!serviceCenterStakeholder || !serviceCenterStakeholder.accountId) {
            return null;
          }

          if (!serviceCenterStakeholder.initialAccountId) {
            return <ConfirmationWithoutInitialAccount
              toAccountId={serviceCenterStakeholder.accountId}
              sharedThingId={sharedThingId} />;
          }

          return (
            <Confirmation
              fromAccountId={serviceCenterStakeholder.initialAccountId}
              toAccountId={serviceCenterStakeholder.accountId}
              sharedThingId={sharedThingId} />
          );
        },
        stakeholderRolesToDisplay: [SERVICE_CENTER_ROLE],
        simplifiedAccountSelect: true,
        successMessage: t('common:page.thing-detail.service-history.service-center.update.success.hint'),
        stakeholders: {
          [SERVICE_CENTER_ROLE]: {
            requiredToChange: true,
            selectPlaceholder: t('common:page.thing-detail.service-history.service-center.input-placeholder.hint'),
            removeConfirmation: {
              confirmText: t('common:page.thing-detail.service-history.service-center.third-party.confirm.labels', { productName }),
              checkboxLabel: t('common:page.thing-detail.service-history.service-center.third-party.checkbox.labels'),
            },
          },
        },
        updateStageLabel: t('common:common.action.update'),
      }}
      stakeholderRoles={stakeholderRoles}
      stageId={stageId}
      thing={thing}
      lifeCycleState={lifeCycleState}
      sharedThingId={sharedThingId}
      editableStakeholders={editableStakeholders}
    />
  );
};

interface ServiceCenterDisplayProps {
  accountId: string;
  sharedThingId: string;
}

export const ServiceCenterDisplay: FC<ServiceCenterDisplayProps> = ({ accountId, sharedThingId }) => {
  const { data: account, status } = useStakeholderAccountQuery({ accountId, role: SERVICE_CENTER_ROLE, sharedThingId });

  if (status !== 'success') {
    return <FieldLoading />;
  }

  return (
    <Typography component="span">{account.display}</Typography>
  );
};

interface ConfirmationProps {
  fromAccountId: string;
  toAccountId: string;
  sharedThingId: string;
}

const Confirmation: FC<ConfirmationProps> = ({ fromAccountId, toAccountId, sharedThingId }) => {
  const { t } = useTranslation(['common']);
  const { data: fromAccount, status: fromAccountStatus } = useStakeholderAccountQuery({ accountId: fromAccountId, role: SERVICE_CENTER_ROLE, sharedThingId });
  const { data: toAccount, status: toAccountStatus } = useStakeholderAccountQuery({ accountId: toAccountId, role: SERVICE_CENTER_ROLE, sharedThingId });

  if (fromAccountStatus !== 'success' || toAccountStatus !== 'success') {
    return null;
  }

  return (
    <Typography>{t('common:page.thing-detail.service-history.service-center.confirm-selection.labels', { fromAccount: fromAccount.display, toAccount: toAccount.display })}</Typography>
  );
};

const ConfirmationWithoutInitialAccount: FC<Omit<ConfirmationProps, 'fromAccountId'>> = ({ toAccountId, sharedThingId }) => {
  const { t } = useTranslation(['common']);
  const { data: toAccount, status: toAccountStatus } = useStakeholderAccountQuery({ accountId: toAccountId, role: SERVICE_CENTER_ROLE, sharedThingId });

  if (toAccountStatus !== 'success') {
    return null;
  }

  return <Typography>{t('common:page.thing-detail.service-history.service-center.confirm-selection-without-initial.labels', { toAccount: toAccount.display })}</Typography>;
};
