import { Pagination } from '@eagle/api-types';
import { Person, PersonType, Thing, ThingType } from '@eagle/core-data-types';
import Axios from 'axios';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthenticated } from '../../auth';
import { T_MANY } from '../../constants';
import { FindItemsDeferredResult } from '../../pages';
import { CacheDataTypes, Undefinable } from '../../types';
import { FILTER_OUT } from '../../util';
import { AssignDialogPropertyColumn } from '../assign-dialog';
import { EntityItemFields } from '../entity-journey/entity-journey-types';
import { useHistorySearch } from '../entity-journey/use-history-search';
import { FetchOneOfAll } from '../fetch';
import { ListPaperColumn } from '../list-item';
import { SimpleElement } from '../simple-element';
import { EntityData } from './assign-multiple-dialog.types';
import { AssignMultipleDialog } from './index';

export enum ThingPersonDialogOperation {
  ADD = 'add',
  CHANGE = 'change',
  DUPLICATE = 'duplicate',
}

export enum ThingPersonDialogTabs {
  THINGS = 'things',
  PEOPLE = 'people',
}

interface Props {
  closeDialog: () => void;
  'data-testid'?: string;
  entityId?: string;
  open: boolean;
  operationType?: ThingPersonDialogOperation;
  setEntityId?: (id: string) => void;
}

export const AddThingPersonDialog: FC<Props> = ({
  closeDialog,
  entityId,
  open,
  operationType = ThingPersonDialogOperation.ADD,
  setEntityId,
  ...props
}): JSX.Element => {
  const { restClient } = useAuthenticated();
  const { addEntity, changeEntity, duplicateEntity, entityItems, entityTypeTab } = useHistorySearch();
  const { t } = useTranslation(['common', 'terms']);

  const handleAddEntity = (entityItemFields: EntityItemFields): (newId: string) => void => {
    return (newId: string): void => {
      if (!entityId || !setEntityId) return addEntity(newId, entityItemFields);
      setEntityId(newId);
      switch (operationType) {
        case ThingPersonDialogOperation.CHANGE: return changeEntity(entityId, newId, entityItemFields);
        case ThingPersonDialogOperation.DUPLICATE: return duplicateEntity(entityId, newId, entityItemFields);
        default: return addEntity(newId, entityItemFields);
      }
    };
  };

  const renderThingItemHeader = (item: Thing): JSX.Element => (
    <ListPaperColumn
      label={<FetchOneOfAll
        id={item.thingTypeId}
        dataType={CacheDataTypes.THING_TYPE}
        renderFactory={(type: ThingType) => <SimpleElement data={type} />}
      />}
      width="50%"
    >
      {item.display}
    </ListPaperColumn>
  );

  const renderPersonItemHeader = (item: Person): JSX.Element => (
    <ListPaperColumn
      label={<FetchOneOfAll
        id={item.personTypeId}
        dataType={CacheDataTypes.PERSON_TYPE}
        renderFactory={(type: PersonType) => <SimpleElement data={type} />}
      />}
      width="50%"
    >
      {item.display}
    </ListPaperColumn>
  );

  const renderThingAdditionalContent = (item: Thing): JSX.Element => (
    <FetchOneOfAll
      dataType={CacheDataTypes.THING_TYPE}
      id={item.thingTypeId}
      notFoundFactory={() => <></>}
      renderFactory={(thingType: ThingType) => <AssignDialogPropertyColumn item={item} type={thingType} />}
    />
  );

  const renderPersonAdditionalContent = (item: Person): JSX.Element => (
    <FetchOneOfAll
      dataType={CacheDataTypes.PERSON_TYPE}
      id={item.personTypeId}
      notFoundFactory={() => <></>}
      renderFactory={(personType: PersonType) => <AssignDialogPropertyColumn item={item} type={personType} />}
    />
  );

  const findThings = (pagination: Undefinable<Pagination>, search: string): FindItemsDeferredResult<Thing> => {
    const cancelToken = Axios.CancelToken.source();
    const itemType = t('terms:thing', { count: T_MANY });

    return {
      cancel: () => cancelToken.cancel(),
      promise: restClient.thing.getAllV1Paged({
        ...pagination,
        filter: FILTER_OUT.deleted,
        limit: 3,
        ...(search ? { search } : {}),
      }, { cancelToken: cancelToken.token }).then((response) => {
        const resultDescription = search && t('common:common.hint.list.search', { entity: itemType, search });

        return {
          result: {
            results: response.entities,
            itemCount: isNaN(response.count) ? 0 : response.count,
          },
          resultDescription,
        };
      }),
    };
  };

  const findPeople = (pagination: Undefinable<Pagination>, search: string): FindItemsDeferredResult<Person> => {
    const cancelToken = Axios.CancelToken.source();
    const itemType = t('terms:person', { count: T_MANY });

    return {
      cancel: () => cancelToken.cancel(),
      promise: restClient.person.getAllV1Paged({
        ...pagination,
        filter: FILTER_OUT.deleted,
        limit: 3,
        ...(search ? { search } : {}),
      }, { cancelToken: cancelToken.token }).then((response) => {
        const resultDescription = search && t('common:common.hint.list.search', { entity: itemType, search });

        return {
          result: {
            results: response.entities,
            itemCount: isNaN(response.count) ? 0 : response.count,
          },
          resultDescription,
        };
      }),
    };
  };

  const entityData: EntityData[] = [
    {
      findItems: findThings,
      handleAddItem: handleAddEntity({
        entity: CacheDataTypes.THING,
        entityType: CacheDataTypes.THING_TYPE,
        entityTypeKey: 'thingTypeId',
      }),
      idsToCheck: entityItems.filter((item) => item.entity === CacheDataTypes.THING).map((item) => item.id),
      renderAdditionalContent: renderThingAdditionalContent,
      renderItemHeader: renderThingItemHeader,
      tab: ThingPersonDialogTabs.THINGS,
    },
    {
      findItems: findPeople,
      handleAddItem: handleAddEntity({
        entity: CacheDataTypes.PERSON,
        entityType: CacheDataTypes.PERSON_TYPE,
        entityTypeKey: 'personTypeId',
      }),
      idsToCheck: entityItems.filter((item) => item.entity === CacheDataTypes.PERSON).map((item) => item.id),
      renderAdditionalContent: renderPersonAdditionalContent,
      renderItemHeader: renderPersonItemHeader,
      tab: ThingPersonDialogTabs.PEOPLE,
    },
  ];

  const getHeader = (): string => {
    switch (operationType) {
      case ThingPersonDialogOperation.CHANGE: return t('common:component.assign-multiple-dialog.labels.change-entity');
      case ThingPersonDialogOperation.DUPLICATE: return t('common:component.assign-multiple-dialog.labels.duplicate-entity');
      default: return t('common:component.assign-multiple-dialog.labels.add-entity-continue');
    }
  };

  return (
    <AssignMultipleDialog
      buttonDisplay={t(`common:common.action.${operationType}`)}
      closeDialog={closeDialog}
      defaultTab={(entityTypeTab === CacheDataTypes.PERSON_TYPE)
        ? ThingPersonDialogTabs.PEOPLE
        : ThingPersonDialogTabs.THINGS
      }
      dialogHeader={getHeader()}
      entityData={entityData}
      open={open}
      data-testid={props['data-testid']}
    />
  );
};
