/* eslint-disable react-hooks/exhaustive-deps */
import { SharedThing } from '@eagle/core-data-types';
import { Skeleton } from '@mui/material';
import { Box } from '@mui/system';
import { FC } from 'react';
import { useAuthenticated } from '../../auth';
import { usePromise } from '../../hooks';
import { ListPaperColumn } from '../list-item';
import { BoldMatchedText } from '../text';

interface Props {
  sharedThing: SharedThing;
  stakeholder: string;
  label?: string;
}

export const StakeholderColumn: FC<Props> = ({ sharedThing, stakeholder, label }) => {
  const { restClient } = useAuthenticated();
  const stakeholderId = sharedThing.lifecycleState?.stakeholders?.[stakeholder];
  const columnLabel = label || stakeholder;

  const [account, accountError, accountState] = usePromise(
    async () => {
      if (!stakeholderId) return;
      return restClient.sharedThing.getStakeholderAccount(sharedThing._id, stakeholder, stakeholderId);
    },
    [restClient, stakeholderId],
  );

  if (!stakeholderId || accountError) {
    return <Box />;
  }

  return <ListPaperColumn
    data-testid={`transfers-list-column-${stakeholder}`}
    label={columnLabel}
    sx={{
      overflow: 'visible',
      '& > label': {
        textTransform: 'capitalize',
      },
      '& > div': {
        wordBreak: 'break-word',
        whiteSpace: 'normal',
        overflow: 'visible',
        textOverflow: 'unset',
        width: '100%',
      },
    }}>
    {accountState === 'pending'
      ? <Skeleton variant="text" sx={{ width: '25%', height: 24 }} />
      : <BoldMatchedText text={account?.display ?? ''} sx={{
        width: '25%',
        height: 24,
        wordBreak: 'break-word',
        whiteSpace: 'normal',
        overflow: 'visible',
        textOverflow: 'unset',
      }} />
    }
  </ListPaperColumn>;
};
