import { PropertyDefinition, TypeDefinitionTypes } from '@eagle/common';
import { SharedThing } from '@eagle/core-data-types';
import { Card, CardContent, Checkbox, Grid, useTheme } from '@mui/material';
import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { EntityTypes, useEntityDisplay } from '../../hooks/use-entity-display';
import { isValueObject } from '../../util';
import { getMaybePropertyValue } from '../format';
import { CheckCircleIcon } from '../icons';
import { ListPaperColumn } from '../list-item';

interface Props {
  sharedThing: SharedThing;
  onSelectedSharedThing: (sharedThing: SharedThing, selected: boolean) => void;
  showDetails: boolean;
  sharedThingTypeVehicleDefinitions?: Record<string, PropertyDefinition>;
}

interface DetailProps {
  xs: number;
  propertyKey: string;
  sharedThing: SharedThing;
  sharedThingTypeVehicleDefinitions?: Record<string, PropertyDefinition>;
}

const commonListProps = {
  sx: { justifyContent: 'stretch' },
  width: '100%',
  wordWrap: true,
  wrap: true,
  role: 'listitem',
};

const Detail: FC<DetailProps> = ({ xs, propertyKey, sharedThing, sharedThingTypeVehicleDefinitions }) => {
  const { t } = useTranslation(['common']);
  const label = sharedThingTypeVehicleDefinitions?.[propertyKey]?.label || t('common:component.shared-thing-card.hint.no-label');
  const rawValue = String(getMaybePropertyValue(sharedThing.properties[propertyKey]));
  const entityId = isValueObject(rawValue) ? String(rawValue.value) : String(rawValue);


  const isReference = sharedThingTypeVehicleDefinitions?.[propertyKey]?.type === TypeDefinitionTypes.REFERENCE;
  const isEntityType = ['brand', 'country', 'model', 'variant'].includes(propertyKey);

  const { display, loading } = useEntityDisplay(
    isEntityType ? (propertyKey as EntityTypes) : 'brand',
    isReference && isEntityType ? entityId : undefined,
  );

  const displayValue = isReference && isEntityType
    ? (loading ? t('common:common.labels.loading') : (display || entityId))
    : entityId || t('common:component.shared-thing-card.hint.no-value');

  return (
    <Grid item xs={xs}>
      <ListPaperColumn
        label={label}
        {...commonListProps}
      >
        {displayValue}
      </ListPaperColumn>
    </Grid>
  );
};

export const SharedThingCard: FC<Props> = memo(({ sharedThing, onSelectedSharedThing, showDetails, sharedThingTypeVehicleDefinitions }) => {
  const theme = useTheme();
  const { t } = useTranslation(['common']);

  return (
    <Card sx={{ height: 'min-content', mt: 2 }} role="region" aria-label={t('common:page.thing-detail.title').toString()}>
      <CardContent sx={{ m: 1, p: 1 }}>
        <Grid
          container
          spacing={2}
          role="list"
          aria-label={t('common:common.labels.properties').toString()}
        >
          {Object.entries(sharedThing.properties).map(([propertyKey], index) => (
            <React.Fragment key={propertyKey}>
              {index === 0 ? (
                <>
                  <Detail
                    xs={10}
                    propertyKey={propertyKey}
                    sharedThing={sharedThing}
                    sharedThingTypeVehicleDefinitions={sharedThingTypeVehicleDefinitions}
                  />
                  <Grid item xs={2}>
                    <Checkbox
                      tabIndex={0}
                      icon={<CheckCircleIcon sx={{ color: theme.palette.grey[300] }} />}
                      checkedIcon={<CheckCircleIcon sx={{ color: theme.palette.success.light }} />}
                      checked={showDetails}
                      onChange={(event) =>
                        onSelectedSharedThing(sharedThing, event.target.checked)
                      }
                    />
                  </Grid>
                </>
              ) : (
                showDetails && (
                  <Detail
                    xs={12}
                    propertyKey={propertyKey}
                    sharedThing={sharedThing}
                    sharedThingTypeVehicleDefinitions={sharedThingTypeVehicleDefinitions}
                  />
                )
              )}
            </React.Fragment>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
});

SharedThingCard.displayName = 'SharedThingCard';
