import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Undefinable } from '../types';
import { getErrorReason } from '../util';

export type ErrorHandler = (
  err: unknown,
  dataType: string,
  display: Undefinable<string>,
  fallback?: string,
) => void;

interface ApiErrorHandlers {
  handleUpdateError: ErrorHandler;
  handleCreateError: ErrorHandler;
  handleGetError: ErrorHandler;
  handleDeleteError: ErrorHandler;
}

export const useApiErrorHandler = (): ApiErrorHandlers => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['common']);

  const handleUpdateError = useCallback((
    err: unknown,
    dataType: string,
    display: Undefinable<string>,
    fallback?: string,
  ) => {
    const { path, reason } = getErrorReason(err);

    if (path && reason) {
      enqueueSnackbar(
        t('common:common.hint.update-failure-v2', { type: dataType, path, reason }),
        { variant: 'error' },
      );
    } else {
      enqueueSnackbar(
        fallback ?? t('common:common.hint.update-failure', { entity: display }),
        { variant: 'error' },
      );
    }
  }, [enqueueSnackbar, t]);

  const handleCreateError = useCallback((
    err: unknown,
    dataType: string,
    display: Undefinable<string>,
    fallback?: string,
  ) => {
    const { path, reason } = getErrorReason(err);

    if (path && reason) {
      enqueueSnackbar(
        t('common:common.hint.create-failure-v2', { type: dataType, path, reason }),
        { variant: 'error' },
      );
    } else {
      enqueueSnackbar(
        fallback ?? t('common:common.hint.create-failure', { display }),
        { variant: 'error' },
      );
    }
  }, [enqueueSnackbar, t]);

  const handleGetError = useCallback((
    err: unknown,
    dataType: string,
    _display: Undefinable<string>,
    fallback?: string,
  ) => {
    const { path, reason } = getErrorReason(err);

    if (path && reason) {
      enqueueSnackbar(
        t('common:common.hint.get-failure-v2', { type: dataType, path, reason }),
        { variant: 'error' },
      );
    } else if (fallback) {
      enqueueSnackbar(fallback, { variant: 'error' });
    }
  }, [enqueueSnackbar, t]);

  const handleDeleteError = useCallback((
    err: unknown,
    dataType: string,
    display: Undefinable<string>,
    fallback?: string,
  ) => {
    const { path, reason } = getErrorReason(err);

    if (path && reason) {
      enqueueSnackbar(
        t('common:common.hint.delete-failure-v2', { type: dataType, path, reason }),
        { variant: 'error' },
      );
    } else {
      enqueueSnackbar(
        fallback ?? t('common:component.delete-entity-dialog.hint.failure', { display }),
        { variant: 'error' },
      );
    }
  }, [enqueueSnackbar, t]);

  return { handleUpdateError, handleCreateError, handleGetError, handleDeleteError };
};
