import { UserGroupGrant } from '@eagle/core-data-types';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { T_MANY } from '../../constants';
import { CacheDataTypes } from '../../types';
import { GroupChipElement } from '../group-chip-element';
import { ListPaperColumn } from '../list-item';

interface Props {
  'data-testid'?: string;
  groups: UserGroupGrant[];
}

export const UserGroupColumn: FC<Props> = ({ groups, ...props }) => {
  const { t } = useTranslation(['common', 'track']);

  const groupIds = groups.map((grantGroups) => grantGroups.userGroupId);

  return <ListPaperColumn data-testid={props['data-testid']} label={t('common:terms.user-group', { count: T_MANY })} sx={{ overflow: 'hidden' }}>
    <GroupChipElement type={CacheDataTypes.USER_GROUP} path='user/groups' groupIds={groupIds} />
  </ListPaperColumn>;
};
