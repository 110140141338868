/* eslint-disable react-hooks/rules-of-hooks */
import { Theme, useTheme } from '@mui/material';
import { Box, SxProps } from '@mui/system';
import { Control } from 'leaflet';
import { FC, useEffect, useMemo, useState } from 'react';
import { useMap } from 'react-leaflet';
import { HERE_MAP_API_KEY } from '../../constants';
import { useConfig, useMapContext } from '../../hooks';
import { BaseLayers, MapStorageKeys, ToggleLayers } from '../../types';
import { useLocalStorage } from '../../util/local-storage';
import { useSubscribeToMapDimensions } from '../../util/maps';
import { useFlags } from '../flags';
import { useMapLayers } from './hooks';
import { LayerSelection } from './layer-selection/layer-selection';
import { baseLayers, toggleLayers } from './layer-selection/map-layers-default';
import { VectorBaseMap } from './vector-base-map';

const JAPAN_LOCALE = 'ja';
const KOREA_LOCALE = 'ko';
export const DEFAULT_LAYER = (navigator.language === JAPAN_LOCALE || navigator.language === KOREA_LOCALE)
  ? BaseLayers.parcelBoundaries
  : BaseLayers.classic;

export interface LayerTypes {
  layer: BaseLayers;
}

interface Props {
  disabledLayers?: ToggleLayers[];
  drawerLayerSelection?: boolean;
  layerSelectionButtonSx?: SxProps<Theme>;
  shouldShowLayerSelection?: boolean;
}

export const MapLayerController: FC<Props> = ({ disabledLayers = [], drawerLayerSelection = false, layerSelectionButtonSx, shouldShowLayerSelection = true }) => {
  const config = useConfig();
  const flags = useFlags();
  const flaggedLayers = toggleLayers(flags);
  const flaggedBaseLayers = useMemo(() => baseLayers(flags), [flags]);
  const map = useMap();
  const theme = useTheme();
  const { selectedLayers, setSelectedLayers, shouldUseLocalStorage } = useMapLayers();
  const [storedBaseLayer, setBaseLayer] = shouldUseLocalStorage ? useLocalStorage<LayerTypes>(MapStorageKeys.MAP_BASE_LAYER, { layer: DEFAULT_LAYER }) : useState<LayerTypes>({ layer: DEFAULT_LAYER });
  const baseLayer = useMemo(() => {
    if (flaggedBaseLayers.length) {
      if (flaggedBaseLayers.find((item) => item.label === storedBaseLayer.layer)) return storedBaseLayer;
      return { layer: flaggedBaseLayers[0].label };
    }
    return { layer: BaseLayers.classic };
  }, [flaggedBaseLayers, storedBaseLayer]);

  const [open, setOpen] = useState(false);
  const { setMapDimming } = useMapContext();

  const { zoomLevel, width, height } = useSubscribeToMapDimensions();

  useEffect(() => {
    if (map) {
      map.getContainer().style.cursor = 'grab';

      map.on('movestart', () => {
        map.getContainer().style.cursor = 'grabbing';
      });

      map.on('moveend', () => {
        map.getContainer().style.cursor = 'grab';
      });

      return () => {
        map.off('movestart');
        map.off('moveend');
      };
    }
  }, [map]);

  const activeLayers = flaggedLayers.filter((layer) => {
    if (layer.minZoomLevel && zoomLevel < layer.minZoomLevel) {
      return false;
    }
    if (layer.maxBoundingBoxSize && (width > layer.maxBoundingBoxSize || height > layer.maxBoundingBoxSize)) {
      return false;
    }
    if (disabledLayers.includes(layer.label)) return false;

    return true;
  });

  const handleToggleChange = (layer: ToggleLayers): void => {
    if (selectedLayers.includes(layer)) {
      setMapDimming(layer, false);
      setSelectedLayers(selectedLayers.filter((item) => item !== layer));
      return;
    }
    setSelectedLayers([...selectedLayers, layer]);
  };

  const handleBaseChange = (label: BaseLayers): void => {
    setBaseLayer({ layer: label });
    setOpen(false);
    map.attributionControl.remove();
    map.addControl(new Control.Attribution());
  };

  const layerComponent = useMemo(() => {
    const possibleLayers = flaggedBaseLayers.length ? flaggedBaseLayers : baseLayers();
    const selectedLayer = possibleLayers.find((mapLayer) => mapLayer.label === baseLayer.layer);

    if (!selectedLayer) return;

    if (selectedLayer.label === BaseLayers.classic) {
      return <>{selectedLayer.layerComponent}</>;
    }

    return <>
      <VectorBaseMap mapStyle={selectedLayer.mapStyle(theme, config.hereMaps?.apiKey ?? HERE_MAP_API_KEY)} />
      {selectedLayer.layerComponent}
    </>;
  }, [flaggedBaseLayers, baseLayer.layer, config.hereMaps?.apiKey, theme]);

  return <>
    {/* Used solely by automated tests to read the zoom level */}
    <span data-testid="map-zoom-level" id="map-zoom-level" data-zoomLevel={zoomLevel} />
    {layerComponent}
    {selectedLayers.map((checkItem, i: number) => {
      if (!checkItem) return;
      return (
        <Box key={i}>
          {activeLayers.find((layer) => layer.label === checkItem)?.layerComponent}
        </Box>
      );
    })}
    {shouldShowLayerSelection &&
      <LayerSelection
        handleBaseChange={handleBaseChange}
        handleToggleChange={handleToggleChange}
        mapBaseLayers={flaggedBaseLayers}
        activeLayers={activeLayers}
        mapToggleLayers={flaggedLayers}
        open={open}
        selectedBaseLayer={baseLayer.layer}
        selectedToggleLayers={selectedLayers}
        setOpen={setOpen}
        drawerLayerSelection={drawerLayerSelection}
        buttonSx={layerSelectionButtonSx ?? ((theme) => ({ position: 'absolute', right: theme.spacing(3), bottom: 100, zIndex: 500 }))}
      />
    }
  </>;
};
