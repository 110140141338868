/* eslint-disable react-hooks/rules-of-hooks */
import { createContext, FC, PropsWithChildren, useContext, useState } from 'react';
import { MapStorageKeys, SetState, ToggleLayers, Undefinable } from '../../../types';
import { useLocalStorage } from '../../../util';
import { AppliedFilter } from '../../entity-search';

interface Context {
  displayThingsLayerSelection: boolean;
  geofenceFilters: AppliedFilter[];
  isThingsLayerSelected: boolean;
  pointOfInterestFilters: AppliedFilter[];
  selectedLayers: ToggleLayers[];
  setGeofenceFilters: SetState<AppliedFilter[]>;
  setIsThingsLayerSelected: SetState<boolean>;
  setPointOfInterestFilters: SetState<AppliedFilter[]>;
  setSelectedLayers: SetState<ToggleLayers[]>;
  shouldUseLocalStorage: boolean;
}

const context = createContext<Undefinable<Context>>(undefined);

interface MapLayersProviderProps extends PropsWithChildren {
  displayThingsLayerSelection?: boolean;
  initialIsThingsLayerSelected?: boolean;
  shouldUseLocalStorage?: boolean; // Add useLocalStorage prop
}

export const MapLayersProvider: FC<MapLayersProviderProps> = ({ children, displayThingsLayerSelection: displayThingsLayerSelection = false, initialIsThingsLayerSelected = true, shouldUseLocalStorage = true }) => {
  const [geofenceFilters, setGeofenceFilters] = useLocalStorage<AppliedFilter[]>(MapStorageKeys.GEOFENCE, []);
  const [pointOfInterestFilters, setPointOfInterestFilters] = useLocalStorage<AppliedFilter[]>(MapStorageKeys.POINT_OF_INTEREST, []);
  const [selectedLayers, setSelectedLayers] = shouldUseLocalStorage ? useLocalStorage<ToggleLayers[]>(MapStorageKeys.MAP_TOGGLE_LAYERS, []) : useState<ToggleLayers[]>([]);
  const [isThingsLayerSelected, setIsThingsLayerSelected] = useState(initialIsThingsLayerSelected);

  return (
    <context.Provider
      value={{
        displayThingsLayerSelection,
        geofenceFilters,
        isThingsLayerSelected,
        pointOfInterestFilters,
        selectedLayers,
        setGeofenceFilters,
        setIsThingsLayerSelected,
        setPointOfInterestFilters,
        setSelectedLayers,
        shouldUseLocalStorage,
      }}
    >
      {children}
    </context.Provider>
  );
};

export const useMapLayers = (): Context => {
  const data = useContext(context);
  if (!data) throw new Error('Missing MapLayersProvider in tree above useMapLayers');
  return data;
};
