import { LastThingEvent } from '@eagle/core-data-types';
import { TableCell, Typography } from '@mui/material';
import { get, isNull, isString } from 'lodash';
import { DateTime, Duration, DurationLike } from 'luxon';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { LastThingEvents } from '../../hooks';
import { I18n } from '../../providers';
import { formatDistanceString, getEventTranslation } from '../../util';
import { FormatNumber, FormatTimestamp } from '../format';
import { Table } from '../table';
import { TableRow } from '../table-row';
import { DateTimeTooltip } from '../tooltip';

interface EventSettings {
  feature: string;
  label?: string;
  eventTypeId: string;
  path: string;
  formatter?: string;
}

interface RecentEventCardProps {
  'data-testid'?: string;
  headers?: ReactNode;
  eventRows: EventSettings[];
  lastEvents: LastThingEvents;
  title?: string;
  range?: DurationLike;
}

interface EventRowProps {
  feature: string;
  lastEventFeature: LastThingEvent;
  path: string;
  label?: string;
  formatter?: string;
  range?: DurationLike;
}

const DEFAULT_RANGE = { days: 30 };

const formatValue = (value: number, formatter: string): ReactNode => {
  switch (formatter) {
    case 'distance':
      return <>{formatDistanceString(value, 'kilometer', 'short')}</>;
    case 'duration': {
      const duration = Duration.fromObject({ minutes: value }, { locale: I18n.getInstance().language }).shiftTo('hours', 'minutes').toHuman({ unitDisplay: 'long' });
      return <>{`${duration}`}</>;
    }
    case 'percentage':
      return <FormatNumber type='number' value={value} format='percentage-100' />;
    default:
      return <>{value}</>;
  }
};

const NoEventsCard: FC<{ title?: string; 'data-testid'?: string }> = ({ title, ...props }) => {
  const { t } = useTranslation(['common']);
  return <Table data-testid={props['data-testid']} title={title || t('common:component.recent_metrics.label')} rows={<TableRow><TableCell><Typography sx={{ fontStyle: 'italic' }}>{t('common:common.hint.list.no-results')}</Typography></TableCell></TableRow>} />;
};

const NoRecentRow: FC<{ label: string; duration: DurationLike }> = ({ label, duration }) => {
  const { t } = useTranslation(['common']);
  const durationKey = Object.keys(duration)[0] as keyof DurationLike;
  const durationValue = duration[durationKey] as string;
  return <TableRow>
    <TableCell>{label}</TableCell>
    <TableCell><Typography variant='body2' sx={{ fontStyle: 'italic', color: 'text.secondary' }} >{t('common:component.recent-events-card.hint.no-data', { value: durationValue, measurement: durationKey })}</Typography></TableCell>
    <TableCell align='right'>&nbsp;</TableCell>
  </TableRow>;
};

const EventRow: FC<EventRowProps> = ({ feature, lastEventFeature, path, label, formatter, range = DEFAULT_RANGE }) => {
  const { featureLabel } = getEventTranslation(feature, lastEventFeature.eventTypeId);
  const reading = get(lastEventFeature, path) as string | number;
  const rowLabel = label || featureLabel || '';
  const rangeLimit = DateTime.now().minus(range).toJSDate();

  if (isNull(reading) || (lastEventFeature.occurred.getTime() <= rangeLimit.getTime())) {
    return <NoRecentRow label={rowLabel} duration={range} />;
  }

  const readingNumber: number = isString(reading) ? parseFloat(reading) : reading;
  const value = formatter ? formatValue(readingNumber, formatter) : readingNumber;

  return (
    <TableRow>
      <TableCell>{rowLabel}</TableCell>
      <TableCell>{value}</TableCell>
      <TableCell align='right'>
        <DateTimeTooltip variant='body2' sx={{ display: 'inline' }} display={<FormatTimestamp format='relative' value={lastEventFeature.occurred} />} title={<FormatTimestamp format='long' value={lastEventFeature.occurred} />} />
      </TableCell>
    </TableRow>
  );
};

export const RecentEventCard: FC<RecentEventCardProps> = ({ eventRows, lastEvents, title, headers, range = DEFAULT_RANGE, ...props }) => {
  const { t } = useTranslation(['common']);
  const rows: ReactNode[] = [];

  if (!lastEvents) {
    return <NoEventsCard />;
  }

  eventRows.forEach((row) => {
    const eventTypeId = row.eventTypeId;
    const features = Object.keys(lastEvents.byFeature);

    const matchingFeatures = features.filter((feature) =>
      feature === row.feature &&
      lastEvents.byFeature[feature][eventTypeId]?.eventTypeId === eventTypeId,
    );

    matchingFeatures.forEach((feature) => {
      const lastEventFeature = lastEvents.byFeature[feature][eventTypeId];
      rows.push(<EventRow key={`${feature}-${eventTypeId}`} feature={feature} lastEventFeature={lastEventFeature} path={row.path} label={row.label} formatter={row.formatter} />);
    });
  });

  if (rows.length === 0) {
    return <NoEventsCard />;
  }

  return <Table data-testid={props['data-testid']} headers={headers} title={title || t('common:component.recent_metrics.label')} rows={rows} />;
};
