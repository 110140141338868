/* eslint-disable react-hooks/exhaustive-deps */
import { Person } from '@eagle/core-data-types';
import { Box, Button, Dialog, Divider, Stack, Typography } from '@mui/material';
import Axios from 'axios';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthenticated } from '../../auth';
import { API_CALL_TEXT_LENGTH, T_ONE } from '../../constants';
import { useSmallScreen } from '../../hooks';
import { FindItemsDeferredResult, Query } from '../../pages';
import { SearchProvider, useSearch } from '../../pages/list/use-search';
import { FILTER_OUT, getListResultDescription, trackEvent } from '../../util';
import { DialogList } from '../dialog-list';
import { SearchResults } from './search-results';
import { SlideTransitionComponent } from './slide-transition-component';
import { AssignPersonDialogProps, PersonRole } from './types';

export const AssignPersonDialogView: FC<AssignPersonDialogProps> = ({ assignedPersons, handleClose, open, tFunction, thing, ...props }) => {
  const { restClient } = useAuthenticated();
  const smallScreen = useSmallScreen();
  const [currentAssignedPersons, setCurrentAssignedPersons] = useState<PersonRole[]>(assignedPersons);
  const { setText } = useSearch();
  const { t } = useTranslation(['common', 'terms']);

  useEffect(() => {
    setCurrentAssignedPersons(assignedPersons);
  }, [assignedPersons]);

  const findPersons = ({ pagination, search }: Query): FindItemsDeferredResult<Person> => {
    const cancelToken = Axios.CancelToken.source();

    return {
      cancel: () => cancelToken.cancel(),
      promise: restClient.person.getAllV1Paged({
        ...pagination, ...(search ? { search } : {}), filter: FILTER_OUT.deleted,
      }, { cancelToken: cancelToken.token }).then((response) => {
        const resultDescription = getListResultDescription({ count: response.count, entityKey: 'terms:person', search, t });

        if (search) {
          trackEvent('keyword_search', 'returned_results', 'dialog_person_list', { 'results_type': 'person', 'results_count': response.count, 'search_term': search });
        }

        return {
          result: {
            results: response.entities,
            itemCount: response.count,
          },
          resultDescription,
        };
      }),
    };
  };

  const onCloseHandler = (): void => {
    handleClose();
    setText('');
  };

  useEffect(() => {
    setText('');
  }, []);

  return (
    <Dialog
      data-testid={props['data-testid']}
      fullScreen={smallScreen}
      fullWidth
      onClose={onCloseHandler}
      open={open}
      sx={{ maxHeight: '55vh', height: 'max-content', margin: 'none', '& .MuiDialog-container': { height: 'auto' } }}
      TransitionComponent={SlideTransitionComponent}
    >
      <Stack
        direction='column'
        divider={<Divider orientation='horizontal' flexItem />}
        spacing={1}
        useFlexGap
      >
        <Typography
          sx={{ marginLeft: '24px', marginTop: '18px', marginBottom: '10px', fontWeight: 'bold' }}
          variant={smallScreen ? 'body2' : 'body1'}
        >
          {tFunction('common:component.assign-dialog.heading.main', {
            entity: tFunction('terms:person', { count: T_ONE }),
            action: tFunction('common:common.action.add'),
            name: thing.display,
          })}
        </Typography>
        <DialogList<Person>
          id='dialog_person'
          limit={3}
          onQueryChanged={findPersons}
          renderContent={(items, isLoading, text) => (
            <SearchResults
              data-testid='assign-person-search-results'
              assignedPersons={currentAssignedPersons}
              assignPerson={(personId: string, role: string) => {
                const newPersonRole: PersonRole = { personId, role };
                if (!role || role === 'driver') {
                  const nonDriverPersons = currentAssignedPersons.filter((person) => person.role !== 'driver');
                  setCurrentAssignedPersons([...nonDriverPersons, newPersonRole]);
                } else {
                  setCurrentAssignedPersons([...currentAssignedPersons, newPersonRole]);
                }
              }}
              initialInstructions={tFunction('common:component.lookup.hint.initial')}
              isLoading={isLoading}
              lessThanCountCharacters={tFunction('common:component.search.hint.less-than-count', { count: API_CALL_TEXT_LENGTH })}
              noResultsInstructions={tFunction('common:common.hint.list.no-results')}
              searchResults={items}
              thingId={thing._id}
              searchText={text}
            />
          )}
        />
        <Box style={{ marginRight: '25px', marginBottom: '10px', display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            onClick={onCloseHandler}
            sx={{ userSelect: 'none' }}
            variant='text'
          >
            {tFunction('common:common.action.close')}
          </Button>
        </Box>
      </Stack>
    </Dialog>
  );
};

export const AssignPersonDialog: FC<AssignPersonDialogProps> = (props) => {
  return (
    <SearchProvider dataKey="assign-person-dialog">
      <AssignPersonDialogView {...props} />
    </SearchProvider>
  );
};
