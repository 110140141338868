import { Card, ClickAwayListener, Fade, Stack, TextField, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { FC, KeyboardEvent, ReactElement, useState } from 'react';
import { makeStyles } from '../../theme';
import { PossibleReadonly, Undefinable } from '../../types';
import { FlexBox } from '../flex-box';
import { IconValue } from './icon-value';
import { PickerIcon } from './types';

interface Props {
  'data-testid'?: string;
  disabled?: boolean;
  icons: PossibleReadonly<PickerIcon[]>;
  mode?: 'standard' | 'inline';
  onChange?: (value: Undefinable<PickerIcon>) => void;
  placeholder: string;
  size?: 'small' | 'medium';
  value: Undefinable<PickerIcon>;
}

const useStyles = makeStyles()((theme) => ({
  dropdown: {
    bottom: '-10px',
    maxWidth: '266px',
    padding: '8px',
    position: 'absolute',
    right: 0,
    transform: 'translateY(95%)',
    zIndex: 1000,
  },
  input: {
    borderRadius: `${theme.shape.borderRadius}px`,
    cursor: 'pointer',
    outline: 'none',
    position: 'relative',
    '&:focus': {
      outline: `${theme.palette.primary.main} 2px solid`,
    },
    '&:focus-within': {
      outline: `${theme.palette.primary.main} 2px solid`,
    },
  },
  selectedIcon: {
    alignItems: 'center',
    borderRight: `${theme.palette.grey[400]} 1px solid`,
    color: theme.palette.grey[600],
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    width: '40px',
  },
}));

export const IconPicker: FC<Props> = ({
  disabled,
  icons,
  mode = 'standard',
  onChange,
  placeholder,
  size,
  value,
  ...props
}) => {
  const theme = useTheme();
  const { classes } = useStyles();
  const [open, setOpen] = useState(false);

  const onClick = (): void => {
    if (disabled) return;
    setOpen(!open);
  };

  const onSelectIcon = (icon: Undefinable<PickerIcon>): void => {
    if (disabled) return;
    onChange?.(icon);
  };

  const onKeyDown = (event: KeyboardEvent<HTMLDivElement>): void => {
    if (disabled) return;
    switch (event.key) {
      case 'Enter': return setOpen(!open);
      case 'Escape': return setOpen(false);
    }
  };

  const renderIconGrid = (): JSX.Element => (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="start"
      sx={{
        flexWrap: 'wrap',
        gap: '8px',
      }}
    >
      <IconValue
        disabled={disabled}
        highlight={!value}
        onClick={onSelectIcon}
      />
      {icons.map((icon) => (
        <IconValue
          key={icon.name}
          disabled={disabled}
          highlight={value?.name === icon.name}
          icon={icon}
          onClick={onSelectIcon}
        />
      ))}
    </Stack>
  );

  if (mode === 'inline') {
    return (
      <Box sx={{ boxSizing: 'border-box', position: 'relative' }} data-testid={props['data-testid']}>
        <Typography sx={{ mb: 1 }}>{placeholder}</Typography>
        {renderIconGrid()}
      </Box>
    );
  }

  return (
    <Box sx={{ boxSizing: 'border-box', position: 'relative' }} data-testid={props['data-testid']}>
      <FlexBox
        className={classes.input}
        flexDirection="row"
        onClick={onClick}
        onKeyDown={onKeyDown}
        sx={{
          opacity: disabled ? 0.75 : 1,
          outline: open ? `${theme.palette.primary.main} 2px solid` : `${theme.palette.grey[400]} 1px solid`,
          pointerEvents: disabled ? 'none' : 'auto',
        }}
        tabIndex={disabled ? -1 : 0}
      >
        <Box
          className={classes.selectedIcon}
          sx={{
            background: value ? '' : `linear-gradient(-${size === 'small' ? '45' : '55'}deg, transparent 49%, ${theme.palette.grey[600]} 50%, transparent 51%)`,
          }}
        >
          {icons.map(({ icon, name }, i) => (
            <Fade
              in={value && icon === value.icon}
              key={`${name}-${i}`}
              style={{
                alignItems: 'center',
                display: 'flex',
                position: 'absolute',
                textAlign: 'center',
              }}
            >
              {icon as ReactElement}
            </Fade>
          ))}
        </Box>
        <TextField
          disabled
          label={value ? value.name : placeholder}
          size={size}
          sx={{
            pointerEvents: 'none',
            '& > label': { color: `${theme.palette.text.secondary} !important` },
            '& fieldset': { border: 'none' },
          }}
          tabIndex={-1}
          value=""
          variant="outlined"
        />
      </FlexBox>
      {open &&
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <Card className={classes.dropdown} elevation={3}>
            {renderIconGrid()}
          </Card>
        </ClickAwayListener>
      }
    </Box>
  );
};
