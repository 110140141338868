import { ThingLastLocation } from '@eagle/core-data-types';
import L from 'leaflet';
import { createContext, Dispatch, FC, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { MapDiscoverItem } from '../components';
import { CLUSTER_BOUNDS_PADDING, MAP_FLY_TO_DURATION } from '../constants';
import { MapPopupSize, MapPosition, MapStorageKeys, Nullable, ToggleLayers, Undefinable } from '../types';
import { getZoomFromArea, trackEvent, useLocalStorage } from '../util';
import { useCustomRoutes } from './use-custom-routes';

type DimmingByLayer = Partial<Record<ToggleLayers | 'things', boolean>>;

interface EntityMap {
  handleMarkerClick: (id: string) => void;
  mapDimming: boolean;
  onAddressSelected: (address: MapDiscoverItem, map: L.Map) => void;
  onGeofenceSelected: (geofenceCoords: L.LatLng[], map: L.Map) => void;
  onItemSelected: (id: string, position: MapPosition) => void;
  popupSize: MapPopupSize;
  savedSearchPosition: Nullable<MapPosition>;
  setMapDimming: (layer: keyof DimmingByLayer, dimmed: boolean) => void;
  setPopupSize: (size: MapPopupSize) => void;
  setSavedSearchPosition: (position: Nullable<MapPosition>) => void;
  setSelectedThingLocation: Dispatch<SetStateAction<Undefinable<ThingLastLocation>>>;
  selectedThingLocation: Undefinable<ThingLastLocation>;
}

export const mapContext = createContext<Undefinable<EntityMap>>(undefined);

export const MapProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { thingId } = useParams();
  const { things } = useCustomRoutes();
  const [mapDimmingByLayer, setMapDimmingByLayer] = useState<DimmingByLayer>({});
  const [popupSize, setPopupSize] = useState<MapPopupSize>({ height: 0, width: 0 });
  const [savedSearchPosition, setSavedSearchPosition] = useLocalStorage<Nullable<MapPosition>>(MapStorageKeys.THING_SEARCH_MAP, null);
  const [selectedThingLocation, setSelectedThingLocation] = useState<Undefinable<ThingLastLocation>>();
  const navigate = useNavigate();
  const location = useLocation();

  const handleMarkerClick = (id: string): void => {
    if (id !== thingId) {
      navigate(`/map/${things}/${id}`, {
        replace: false,
        state: {
          previousLocations: undefined,
          shouldFly: false,
        },
      });
      return;
    }
    navigate(`/map/${things}`, {
      state: {
        shouldFly: false,
      },
    });
  };

  const onItemSelected = (id: string, position: MapPosition): void => {
    navigate(`/map/${things}/${id}`, {
      replace: id === thingId,
      state: {
        previousLocations: location,
        shouldFly: true,
      },
    });
    if (thingId && id !== thingId) return;
    setSavedSearchPosition(position);
  };

  const onAddressSelected = (address: MapDiscoverItem, map: L.Map): void => {
    if (!address) return;
    const zoom = getZoomFromArea(address.resultType);
    map.setView(address.position, zoom, { animate: true, duration: MAP_FLY_TO_DURATION });
    trackEvent('keyword_search', 'selected_location', 'thing_map', { 'location': address.title });
  };

  const onGeofenceSelected = (geofenceCoords: L.LatLng[], map: L.Map): void => {
    const mapCoords = L.latLngBounds(geofenceCoords).pad(CLUSTER_BOUNDS_PADDING);
    map.flyToBounds(mapCoords, { animate: true, duration: MAP_FLY_TO_DURATION });
    trackEvent('keyword_search', 'selected_geofence', 'thing_map');
  };

  const setMapDimming = (layer: keyof DimmingByLayer, value: boolean): void => {
    setMapDimmingByLayer((values) => ({ ...values, [layer]: value }));
  };

  useEffect(() => {
    setMapDimming('things', !!thingId);
  }, [thingId]);

  return (
    <mapContext.Provider
      value={{
        handleMarkerClick,
        mapDimming: Object.values(mapDimmingByLayer).includes(true),
        onAddressSelected,
        onGeofenceSelected,
        onItemSelected,
        popupSize,
        savedSearchPosition,
        setMapDimming,
        setPopupSize,
        setSavedSearchPosition,
        setSelectedThingLocation,
        selectedThingLocation,
      }}
    >
      {children}
    </mapContext.Provider>
  );
};

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export const useMapContext = function (): EntityMap {
  const data = useContext(mapContext);
  if (!data) throw new Error('Missing MapProvider in tree above useMapContext');
  return data;
};
