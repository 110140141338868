import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useAuthenticated } from '../../auth';
import { MicroPortalErrorFallback, MicroPortalLoadingFallback } from '../../components';
import { createMicroPortalUrl, createParams, getMicroPortalToken } from '../../util';

export const MicroPortalAuthenticate: FC = () => {
  const { app: appParam } = useParams();
  const [search] = useSearchParams();
  const { axios, account } = useAuthenticated();
  const [error, setError] = useState<boolean>(false);
  const [app, setApp] = useState<string>();
  const { t } = useTranslation(['common']);
  const devMode = search.get('devHost') || undefined;

  useEffect(() => {
    if (appParam) {
      setApp(appParam);
    }
  }, [appParam]);

  useEffect(() => {
    let mounted = true;

    if (!app) {
      return;
    }

    getMicroPortalToken(axios, app).then((auth) => {
      if (auth) {
        const src = createMicroPortalUrl(app, createParams({ auth, domain: account.homeDomain }), devMode);
        window.location.href = src;
      } else {
        setError(true);
      }
    }).catch(() => {
      if (!mounted) return;
      setError(true);
    }).finally(() => {
      if (!mounted) return;
    });

    return () => {
      mounted = false;
    };
  }, [t, axios, app, setError, account, devMode]);

  if (error) {
    return <><MicroPortalErrorFallback /></>;
  }

  return <MicroPortalLoadingFallback app={app || 'App'} />;
};
