import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { CommonEntity } from '../../types';
import { FetchOne } from '../fetch';

interface EntityDisplayMultipleProps {
  ids: string[];
  'data-testid'?: string;
  dataType: string;
  renderer: (id: string, dataType: string) => JSX.Element;
  noResultsDisplay?: ReactNode;

}

const defaultRenderFactory = (id: string, dataType: string): JSX.Element => {
  return <FetchOne
    dataType={dataType}
    id={id}
    renderFactory={(entity: CommonEntity) => <>{entity.display}</>}
  />;
};

export const EntityDisplayMultiple: FC<EntityDisplayMultipleProps> = ({ ids, dataType, renderer = defaultRenderFactory, noResultsDisplay, ...props }) => {
  const { t } = useTranslation(['common']);
  if (!ids.length) return noResultsDisplay ? <>{noResultsDisplay}</> : <></>;

  return <span data-testid={props['data-testid']}>
    <span>{renderer(ids[0], dataType)}</span>
    {ids.length > 1 && <span>, {renderer(ids[1], dataType)}</span>}
    {ids.length > 2 && <span>, {t('common:common.labels.othersWithCount', { count: ids.length - 1 })}</span>}
  </span>;
};
