import { useCallback, useEffect, useState } from 'react';
import { useAuthenticated } from '../auth';

interface EntityType {
  display?: string;
}

interface EntityDisplay {
  display?: string;
  loading: boolean;
  error: Error | null;
}

export type EntityTypes = 'brand' | 'country' | 'model' | 'variant';

export const useEntityDisplay = (entityType: EntityTypes, entityId?: string): EntityDisplay => {
  const { restClient } = useAuthenticated();
  const [display, setDisplay] = useState<string>('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const fetchEntity = useCallback(async () => {
    if (!entityId) {
      setLoading(false);
      return;
    }

    try {
      const entity = await restClient[entityType].get(entityId) as EntityType;
      setDisplay(entity?.display || '');
    } catch (err) {
      setError(err as Error);
    } finally {
      setLoading(false);
    }
  }, [entityId, entityType, restClient]);

  useEffect(() => {
    void fetchEntity();
  }, [fetchEntity]);

  return { display, loading, error };
};
