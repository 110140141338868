import { AlertResponse, Subscription } from '@eagle/core-data-types';
import { Box, Paper, Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHref } from 'react-router-dom';
import { T_ONE } from '../../constants';
import { getAlertTypeStyle, getEventLabelWithFlag, getFeatureLabelWithFlag, getFeatureType } from '../../util';
import { useBoolFlag } from '../flags';
import { ResultsDetailCard } from '../search-results-table';
import { SubscriptionUserRecipientDataType } from './subscriptions.types';
import { getEntityData, RecipientDisplay, SourceDisplay } from './subscriptions.util';

interface SubscriptionDetailsProps {
  disableLink?: boolean;
  displayOnCard: boolean;
  subscription: Subscription;
  subscriptionDetailPath: string;
  userRecipientDataType: SubscriptionUserRecipientDataType;
  'data-testid'?: string;
}

export const SubscriptionDetails: FC<SubscriptionDetailsProps> = ({ displayOnCard, subscription, disableLink, subscriptionDetailPath, userRecipientDataType, ...props }): JSX.Element => {
  const href = useHref(subscriptionDetailPath);
  const { alertFilters, recipient, _id: subscriptionId } = subscription;
  const { alertTypeId, feature } = alertFilters;
  const { t } = useTranslation(['admin', 'common']);
  const theme = useTheme();

  const featureTypeId = getFeatureType(alertTypeId);
  const flagUseEventV3 = useBoolFlag('portals-translation-retrieval-hook-for-event-and-alert-descriptions-feature');

  const renderContent = (): JSX.Element => <>
    <Typography variant="caption">{t('common:terms.feature')}</Typography>
    <Typography data-testid="subscription-feature">{feature && getFeatureLabelWithFlag(feature, flagUseEventV3)}</Typography>
    <Typography sx={{ mt: 1 }} variant="caption">{t('common:terms.recipient', { count: T_ONE })}</Typography>
    <RecipientDisplay displaySubText={false} recipient={recipient} userDataType={userRecipientDataType} />
    <Typography sx={{ mt: 1 }} variant="caption">{t('common:terms.from')}</Typography>
    <Box data-testid="subscription-entity">
      <SourceDisplay entityData={getEntityData(alertFilters)} />
    </Box>

    {alertTypeId && featureTypeId && feature
      && <Paper
        data-testid="subscription-alert-description"
        elevation={0}
        sx={{
          ...getAlertTypeStyle({ alertTypeId, featureTypeId, feature } as AlertResponse, theme),
          fontSize: '0.8rem',
          height: 'min-content',
          mt: 1,
          overflow: 'hidden',
          p: '0.4rem',
          textAlign: 'center',
          textOverflow: 'ellipsis',
          width: 'inherit',
        }}
      >
        {getEventLabelWithFlag(featureTypeId, alertTypeId, feature, flagUseEventV3)}
      </Paper>
    }
  </>;

  return (
    <ResultsDetailCard
      content={renderContent()}
      href={disableLink ? undefined : `${href}/${subscriptionId}`}
      sx={{ p: displayOnCard ? 2 : 1 }}
      data-testid={props['data-testid']}
    />
  );
};
