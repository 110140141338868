import { Box, Button, CircularProgress, Dialog, Drawer, IconButton, styled, Typography } from '@mui/material';
import { ResponsiveStyleValue } from '@mui/system';
import { enqueueSnackbar } from 'notistack';
import { cloneElement, FC, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAuthenticated } from '../../auth';
import { createMicroPortalRoute, createMicroPortalUrl, createParams, getMicroPortalToken } from '../../util';
import { CloseIcon } from '../icons';
import { MiddleSpinner } from '../middle-spinner';
import { MicroPortalLauncherProps, MicroPortalProps, MicroPortalType } from './micro-portal.types';

const MicroFrame = styled('iframe')({
  border: 'none',
  overflow: 'hidden',
  width: '100%',
  height: '100%',
});

const StyledCloseButton = styled(IconButton)({
  position: 'absolute',
  top: 3,
  right: 3,
  zIndex: 500,
});

export const MicroPortalErrorFallback: FC<{ error?: string }> = ({ error }) => {
  const { t } = useTranslation(['common']);
  return <Box padding={3} textAlign='center'>
    <Typography>{error || t('common:page.sign-in.hint.failure.unauthorized_client')}</Typography>
  </Box>;
};

export const MicroPortalLoadingFallback: FC<{ app: string; message?: string }> = ({ message, app }) => {
  const { t } = useTranslation(['common']);
  return <MiddleSpinner sx={{ flexDirection: 'column', gap: 2, textTransform: 'capitalize' }}>
    {message || t('component.micro-portal-launcher.launching.label', { app })}
  </MiddleSpinner>;
};

const getDrawerAnchor = (type: string): 'left' | 'right' | 'bottom' => {
  switch (type) {
    case 'drawer-left':
      return 'left';
    case 'drawer-right':
      return 'right';
    case 'drawer-bottom':
      return 'bottom';
    default:
      return 'right';
  }
};

const DEFAULT_RESPONSIVE_VALUES = {
  dialog: {
    width: {
      xs: '80vw',
      sm: '500px',
      md: '700px',
      lg: '1000px',
    },
    height: {
      xs: '100vh',
      md: '60vh',
    },
  },
  drawerLeftRight: {
    width: {
      xs: '100vw',
      md: '600px',
    },
    height: '100vh',
  },
  drawerBottom: {
    height: {
      xs: '100vh',
      md: '60vh',
    },
  },
  default: {
    width: '100%',
    height: '100%',
  },
};

interface MicroPortalBreakpointSettings {
  width?: ResponsiveStyleValue<string> | string;
  height?: ResponsiveStyleValue<string> | string;
}

const getBreakpointSettings = (type: MicroPortalType): MicroPortalBreakpointSettings => {
  switch (type) {
    case 'dialog': {
      return DEFAULT_RESPONSIVE_VALUES.dialog;
    }
    case 'drawer-left':
    case 'drawer-right': {
      return DEFAULT_RESPONSIVE_VALUES.drawerLeftRight;
    }
    case 'drawer-bottom': {
      return DEFAULT_RESPONSIVE_VALUES.drawerBottom;
    }
    default: {
      return DEFAULT_RESPONSIVE_VALUES.default;
    }
  }
};

export const MicroPortal: FC<MicroPortalProps> = ({ app, data = {}, title = '', errorFallback = MicroPortalErrorFallback, loadingFallback, token: passedToken, devHost }) => {
  const { axios, account } = useAuthenticated();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [token, setToken] = useState<string | undefined>(passedToken);
  const { t } = useTranslation(['common']);
  const loadingFallbackComponent = loadingFallback || <MicroPortalLoadingFallback app={app} />;

  useEffect(() => {
    setLoading(true);

    if (passedToken) {
      setToken(passedToken);
      setLoading(false);
      return;
    }

    let mounted = true;

    getMicroPortalToken(axios, app).then((authToken) => {
      setToken(authToken);
      setError(false);
    }).catch((error) => {
      enqueueSnackbar(error instanceof Error ? error.message : t('common:page.sign-in.hint.failure.unauthorized_client'), { variant: 'error' });
      if (!mounted) return;
      setToken(undefined);
      setError(true);
    }).finally(() => {
      if (!mounted) return;
      setLoading(false);
    });

    return () => {
      mounted = false;
    };
  }, [t, axios, app, setToken, setError, setLoading, passedToken]);

  if (loading || !account.homeDomain || !token) {
    return <>{loadingFallbackComponent}</>;
  }

  if (error) {
    return <>{errorFallback}</>;
  }

  const src = createMicroPortalUrl(app, createParams({ auth: token, domain: account.homeDomain, ...data }), devHost);
  return <MicroFrame src={src} scrolling='no' title={title} sandbox="allow-scripts allow-same-origin allow-forms" referrerPolicy="no-referrer" />;
};

const StyledLink = styled(Link)(() => ({
  textTransform: 'capitalize',
}));

export const MicroPortalLauncher: FC<MicroPortalLauncherProps> = ({ app, data = {}, type = 'href', height, width, loadingElement, element, devHost }) => {
  const { axios } = useAuthenticated();
  const [token, setToken] = useState<string | null>();
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation(['common']);
  const widthSettings = width || getBreakpointSettings(type).width;
  const heightSettings = height || getBreakpointSettings(type).height;

  const authenticate = (): void => {
    setLoading(true);

    getMicroPortalToken(axios, app).then((authToken) => {
      setToken(authToken);
    }).catch((error) => {
      enqueueSnackbar(error instanceof Error ? error.message : t('common:page.sign-in.hint.failure.unauthorized_client'), { variant: 'error' });
    }).finally(() => {
      setLoading(false);
    });
  };

  const params = { ...data };
  if (devHost) {
    params.devHost = devHost;
  }

  if (type === 'href') {
    return element ? cloneElement(element as ReactElement, { href: createMicroPortalRoute(app, createParams(params)) }) : <StyledLink to={createMicroPortalRoute(app, createParams(params))} target='_blank'>{t('component.micro-portal-launcher.launch.label', { app })}</StyledLink>;
  }

  return <>
    {loading ? loadingElement || <Button disabled startIcon={<CircularProgress color='inherit' size='1rem' />}>{t('component.micro-portal-launcher.launch.label', { app })}</Button> : element ? cloneElement(element as ReactElement, { onClick: authenticate }) : <Button onClick={authenticate} >{t('component.micro-portal-launcher.launch.label', { app })}</Button>}
    {type === 'dialog' ? <Dialog open={!!token} maxWidth='xl' >
      <StyledCloseButton size='large' onClick={() => setToken(null)}><CloseIcon /></StyledCloseButton>
      <Box height={heightSettings} width={widthSettings} overflow='hidden'>
        {token ? <MicroPortal app={app} data={data} token={token} devHost={devHost} /> : <></>}
      </Box>
    </Dialog> : <></>}
    {type.includes('drawer-') ? <Drawer anchor={getDrawerAnchor(type)} open={!!token} sx={{ zIndex: 1350 }}>
      <StyledCloseButton size='large' onClick={() => setToken(null)}><CloseIcon /></StyledCloseButton>
      <Box width={widthSettings} height={heightSettings} overflow='hidden'>
        {token ? <MicroPortal app={app} data={data} token={token} devHost={devHost} /> : <></>}
      </Box>
    </Drawer> : <></>}
  </>;
};
