/* eslint-disable react-hooks/exhaustive-deps */
import { Close, Download } from '@mui/icons-material';
import { Box, IconButton, Modal, Paper } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Nullable } from '../../types';
import { downloadFile, getFileName } from '../../util';
import { ErrorMessage } from '../error-message';
import { imageModalStyles } from './image-modal.styles';

interface Props {
  fileName?: string;
  handleClose: () => void;
  image?: URL | string;
  open: boolean;
}

export const ImageModal: FC<Props> = ({ fileName, handleClose, image, open }) => {
  const { classes } = imageModalStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['common']);
  const [error, setError] = useState<Nullable<Error>>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(<ErrorMessage error={error} />, { variant: 'error' });
      setError(null);
    }
  }, [error]);

  const handleDownload = useCallback(() => {
    if (!image) return;
    const imageUrl = typeof image === 'string' ? image : image.href;
    downloadFile(imageUrl, fileName ?? getFileName(imageUrl), () => setError(new Error(t('common:component.image-modal.hint.download-error'))));
  }, [image]);

  if (!image) {
    return <></>;
  }

  return (
    <Modal
      disablePortal
      onClose={handleClose}
      open={open}
      sx={{ zIndex: 1400 }}
    >
      <Box className={classes.imageModal}>
        <Box
          alt={fileName}
          className={classes.imageBox}
          component="img"
          onContextMenu={(e: SyntheticEvent) => e.preventDefault()}
          onLoad={() => setIsLoading(false)}
          src={typeof image === 'string' ? image : image.href}
          crossOrigin="anonymous"
        />
        {!isLoading
          && <Paper elevation={1} className={classes.actionCard}>
            <IconButton
              aria-label={t('common:common.action.download')}
              className={classes.actionButton}
              onClick={handleDownload}
            >
              <Download />
            </IconButton>
            <IconButton
              aria-label={t('common:common.action.close')}
              className={classes.actionButton}
              onClick={handleClose}
            >
              <Close />
            </IconButton>
          </Paper>
        }
      </Box>
    </Modal>
  );
};
