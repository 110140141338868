import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Nullable } from '../../types';
import { useContractListContext } from './contract-list-provider';
import { ContractManagementInitiateContractDialog } from './contract-management-initiate-contract-dialog';
import { ContractManagementTerminateContractDialog } from './contract-management-terminate-contract-dialog';
import { SelectablePlan, ThingWithContractData } from './contract-management.types';
import { getContractStatus } from './contract-management.utils';

interface ContractManagementMenuProps {
  item: ThingWithContractData;
  selectablePlans: SelectablePlan[];
}

export const ContractManagementManuallyManageMenu: FC<ContractManagementMenuProps> = ({ item, selectablePlans }) => {
  const { t } = useTranslation(['common', 'data-subscription']);
  const { state: { selectedCurrency }, refreshList } = useContractListContext();
  const status = getContractStatus(item);

  const [terminateDialogOpen, setTerminateDialogOpen] = useState(false);
  const [initiateDialogOpen, setInitiateDialogOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState<Nullable<HTMLElement>>(null);
  const open = Boolean(anchorEl);
  const manageSubscription = (event: React.MouseEvent<HTMLElement>): void => setAnchorEl(event.currentTarget);
  const handleClose = (): void => setAnchorEl(null);

  const isTerminateEnabled = status !== 'out-of-contract' && status !== 'unknown';
  const isInitiateEnabled = status === 'out-of-contract' || status === 'unknown';

  return (
    <>
      <IconButton
        onClick={manageSubscription}
        disabled={(!isTerminateEnabled && !selectablePlans.length) || (!isTerminateEnabled && !isInitiateEnabled)}
        data-testid="plan-select-manage-subscription-manually-manage-menu-button"
        aria-label="Manage Subscription"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose} data-testid="contract-management-manually-manage-menu">
        <MenuItem
          data-testid="contract-management-manually-manage-menu-item-terminate-contract"
          disabled={!isTerminateEnabled}
          onClick={() => {
            setTerminateDialogOpen(true);
            handleClose();
          }}
        >
          {t('common:page.contract-management.terminate-contract.action.labels')}
        </MenuItem>
        <MenuItem
          data-testid="contract-management-manually-manage-menu-item-initiate-contract"
          disabled={!isInitiateEnabled}
          onClick={() => {
            setInitiateDialogOpen(true);
            handleClose();
          }}
        >
          {t('common:page.contract-management.manually-initiate-contract.action.labels')}
        </MenuItem>
      </Menu>

      {terminateDialogOpen && (
        <ContractManagementTerminateContractDialog
          item={item}
          open={terminateDialogOpen}
          onClose={() => setTerminateDialogOpen(false)}
          refreshList={refreshList}
        />
      )}
      {initiateDialogOpen && (
        <ContractManagementInitiateContractDialog
          item={item}
          open={initiateDialogOpen}
          onClose={() => setInitiateDialogOpen(false)}
          refreshList={refreshList}
          selectablePlans={selectablePlans}
          selectedCurrency={selectedCurrency}
        />
      )}
    </>
  );
};
