import { Alert, FormControlLabel, MenuItem, Select, Stack, Switch, Typography } from '@mui/material';
import { isUndefined } from 'lodash';
import { DateTime } from 'luxon';
import { useSnackbar } from 'notistack';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthenticated } from '../../auth';
import { ConfirmDialog, Dialog, TextEditor } from '../../components';
import { DatePicker } from '../../components/date-time-range-picker/date-picker';
import { DEFAULT_TIME_ZONE } from '../../constants';
import { useApiErrorHandler } from '../../hooks';
import { Nullable, Undefinable } from '../../types';
import { DateTimeRangeError } from '../../util';
import { SelectablePlan, SelectValue, ThingWithContractData } from './contract-management.types';
import { formatDataSubscriptionPlanWithTerm, formatPrice, serializeSelectValue } from './contract-management.utils';

interface InitiateContractDialogProps {
  open: boolean;
  onClose: () => void;
  item: ThingWithContractData;
  refreshList: VoidFunction;
  selectablePlans: SelectablePlan[];
  selectedCurrency: string;
}

export const ContractManagementInitiateContractDialog: FC<InitiateContractDialogProps> = ({
  open,
  onClose,
  item,
  refreshList,
  selectablePlans,
  selectedCurrency,
}) => {
  const { t, i18n } = useTranslation(['common', 'data-subscription']);
  const { restClient } = useAuthenticated();
  const { handleUpdateError } = useApiErrorHandler();
  const { enqueueSnackbar } = useSnackbar();
  const [disabled, setDisabled] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<Nullable<SelectValue>>(null);
  const [overrideTerm, setOverrideTerm] = useState(false);
  const [termOverrideValue, setTermOverrideValue] = useState('');
  const [selectedDate, setSelectedDate] = useState<Nullable<DateTime>>(null);
  const [dateError, setDateError] = useState<Undefinable<string>>(undefined);
  const [initiateNotes, setInitiateNotes] = useState('');
  const createDisabled = disabled || (overrideTerm && termOverrideValue === '') || !initiateNotes.length || !selectedPlan || !!dateError;

  const planOptions = useMemo(() => {
    return selectablePlans.map((selectablePlan) => (
      <MenuItem
        key={`${selectablePlan.plan.dataSubscriptionPlanId}-${selectablePlan.term}`}
        data-testid={`${selectablePlan.plan.dataSubscriptionPlanId}-${selectablePlan.term}`}
        value={serializeSelectValue({ dataSubscriptionPlanId: selectablePlan.plan.dataSubscriptionPlanId, term: selectablePlan.term })}
      >
        <Stack>
          <span>{formatDataSubscriptionPlanWithTerm(i18n, selectablePlan.plan, selectablePlan.term)}</span>
          <span>{formatPrice(i18n, selectedCurrency, selectablePlan.price)}</span>
        </Stack>
      </MenuItem>
    ));
  }, [selectablePlans, i18n, selectedCurrency]);

  const handleManuallyInitiateContract = async (): Promise<void> => {
    if (!selectedPlan) return;
    setDisabled(true);
    const stakeholderRoles = item.contract.dataSubscription?.lifecycleState?.activeStakeholderRoles;
    try {
      await restClient.admin.dataSubscription.create({
        stakeholderType: stakeholderRoles ? stakeholderRoles[0] : null,
        stakeholderAccountId: item.thing.accountId,
        stakeholderThingId: item._id,
        start: new Date(),
        dataSubscriptionPlanId: selectedPlan.dataSubscriptionPlanId,
        currencySelected: selectedCurrency,
        termSelected: overrideTerm ? termOverrideValue : selectedPlan.term,
        notes: initiateNotes,
        reason: 'manual/subscription-creation',
        sourceInserted: null,
      });
      enqueueSnackbar(t('common:page.contract-management.contract-initiated-successfully.labels'), { variant: 'success' });
      refreshList();
    } catch (err) {
      handleUpdateError(err, 'CONTRACT', item._id);
    } finally {
      setDisabled(false);
      onClose();
    }
  };

  const handleDateChange = useMemo(() => (date: Nullable<DateTime>): void => {
    setDateError(undefined);
    if (!date || !date.isValid) {
      setDateError(DateTimeRangeError.INVALID_DATE);
      return;
    }

    if (date < DateTime.now()) {
      setDateError(DateTimeRangeError.PAST_DATE);
      return;
    }
    setSelectedDate(date);

    const diff = date.diff(DateTime.now(), ['years', 'months', 'days']);
    const roundedDays = Math.round(diff.days);

    let isoDuration = 'P';
    if (diff.years > 0) isoDuration += `${diff.years}Y`;
    if (diff.months > 0) isoDuration += `${diff.months}M`;
    if (roundedDays > 0) isoDuration += `${roundedDays}D`;

    setTermOverrideValue(isoDuration);
  }, []);

  return (
    <Dialog
      actions={
        <ConfirmDialog
          confirmPrompt={<Typography variant="body2">{t('common:page.contract-management.manually-initiate-contract.labels')}</Typography>}
          initialPrompt={t('common:page.contract-management.start-contract.action')}
          onConfirm={handleManuallyInitiateContract}
          buttonDisabled={createDisabled}
          buttonVariant="contained"
        />
      }
      open={open}
      onClose={onClose}
      data-testid="contract-management-manually-initiate-contract-dialog"
      title={t('common:page.contract-management.manually-initiate-contract.labels')}
      hasCloseBtn={true}
    >
      <Stack spacing={2}>
        <Alert severity="info" variant="filled" sx={{ alignItems: 'center' }}>
          <Typography variant="h5" sx={{ textTransform: 'uppercase' }}>{t('common:page.contract-management.initiate-contract.please-note.labels')}</Typography>
          {t('common:page.contract-management.initiate-contract.please-note-warning.labels')}
        </Alert>

        <Select
          size='small'
          displayEmpty
          value={selectedPlan ? JSON.stringify(selectedPlan) : ''}
          onChange={(e) => {
            const parsedValue = JSON.parse(e.target.value) as SelectValue;
            setSelectedPlan(parsedValue || null);
          }}
        >
          <MenuItem value=""><em>{t('common:page.contract-management.select-a-plan.labels')}</em></MenuItem>
          {planOptions}
        </Select>

        <FormControlLabel
          control={<Switch checked={overrideTerm} onChange={(e) => setOverrideTerm(e.target.checked)} />}
          label={t('common:page.contract-management.initiate-contract.override-term.action')}
        />
        {overrideTerm && (
          <DatePicker
            data-testid="override-term-date-picker"
            date={isUndefined(selectedDate) ? null : DateTime.fromISO(String(selectedDate), { zone: DEFAULT_TIME_ZONE })}
            disablePast
            onChange={handleDateChange}
            label={t('common:component.date-picker.labels.end-date')}
            required
            size="small"
            helperText={dateError && <Typography variant="caption" sx={{ color: 'red' }}>{t(`common:component.date-picker.hint.${dateError}`)}</Typography>}
            dateError={dateError}
          />
        )}
        <TextEditor
          data-testid="initiate-contract-notes-input"
          label={t('common:page.contract-management.initiate-contract.please-note.labels')}
          onKeyUp={(value) => setInitiateNotes(value.trim())}
          multiline
          rows={4}
          required
          size="small"
          value={initiateNotes}
        />
      </Stack>
    </Dialog>
  );
};
