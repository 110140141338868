/* eslint-disable react-hooks/exhaustive-deps */
import { InputTypes, TypeDefinitionTypes } from '@eagle/common';
import { Refresh, Save } from '@mui/icons-material';
import { Button, Checkbox, FormControlLabel, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ulid } from 'ulid';
import { addLocalStorageItem, FILTER_SHOW_DELETED_FLAG, isAppliedFilterArrayEqual, useLocalStorage } from '../../util';
import { AppliedFilter, AppliedFilterType, EntityField, FilterFieldNew } from '../entity-search';
import { FilterFieldNewProps, FILTERS_KEY, SavedFilter } from '../filter';
import { useBoolFlag } from '../flags';
import { Layout } from './layout';
import { NewFilterBuilder } from './new-filter-builder';
import { NewSavedFilterController } from './new-saved-filter';
import { buildFilterFieldsNew, BuildFilterFieldsOptions, handleSetShowDeleted } from './util';

interface Props {
  'data-testid'?: string;
  canShowDeleted?: boolean;
  filterFields: FilterFieldNewProps[];
  filters: AppliedFilter<AppliedFilterType>[];
  includeDeleted?: boolean;
  onCloseClicked?: () => unknown;
  onFiltersChanged: (filters: AppliedFilter<AppliedFilterType>[]) => unknown;
  savedFilterKey?: string;
  setIncludeDeleted?: (value: boolean) => void;
  setShowDeleted?: (value: boolean) => void;
  sortFields?: boolean;
  storageKey: string;
}

export const SHOW_DELETED_KEY = 'show-deleted';

export const NewFilter: FC<Props> = ({
  canShowDeleted,
  filterFields,
  filters,
  includeDeleted,
  onCloseClicked,
  onFiltersChanged,
  savedFilterKey,
  setIncludeDeleted,
  setShowDeleted,
  sortFields = true,
  storageKey,
  ...props
}) => {
  const [savedFiltersInLocalStorage, setSavedFiltersInLocalStorage] = useLocalStorage<Record<string, SavedFilter[]>>(savedFilterKey ?? '', { [storageKey]: [] });
  const [savedFilter, setSavedFilter] = useState<SavedFilter[]>(savedFiltersInLocalStorage[storageKey] ?? []);
  const [hasFilterChanged, setHasFilterChanged] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['common']);
  const hasShowDeletedFeature = useBoolFlag(FILTER_SHOW_DELETED_FLAG);
  const SHOW_DELETED_STORAGE_KEY = `${storageKey}`;

  const fields = useMemo(
    () => {
      const fieldsArray: FilterFieldNew[] = [];
      filterFields.forEach((filter) => {
        const options: Partial<BuildFilterFieldsOptions> = {
          apiUrl: filter.apiUrl,
          attributes: filter.attributes,
          dataType: filter.dataType,
          entityCache: filter.entityCache,
          fieldLabel: filter.fieldLabel,
          findItems: filter.findItems,
          omitEntityFilter: filter.omitEntityFilter,
          pathIdentifier: filter.pathIdentifier,
          replacePath: filter.replacePath,
          selectMultiple: filter.selectMultiple,
        };
        const filterField = buildFilterFieldsNew(
          filter.entityTypes ?? [],
          filter.typePropertyName,
          filter.propertyLabel,
          options,
        );
        fieldsArray.push(filterField);
      });
      fieldsArray.push(
        {
          definition: {
            description: null,
            format: 'raw',
            input: InputTypes.TEXT,
            label: t('common:terms.tag'),
            multiple: null,
            type: TypeDefinitionTypes.TEXT,
          },
          path: 'tags',
        },
      );
      return sortFields ? fieldsArray.sort((a, b) => (a.definition.label > b.definition.label) ? 1 : -1) : fieldsArray;
    },
    [filterFields],
  );

  const renderFilters = (): JSX.Element => {
    return (
      <Stack spacing={1}>
        <NewFilterBuilder
          fields={fields}
          filterOptions={{
            addFilterText: t('common:component.filter.actions.add-filter'),
            addButtonText: t('common:component.filter.actions.add-filter'),
            cancelButtonText: t('common:common.action.cancel'),
            moreText: t('common:terms.filter'),
            valuePlaceholderText: t('common:component.filter.labels.value'),
          }}
          filters={filters}
          onFiltersChanged={onFiltersChanged}
          storageKey={storageKey}
        />
        {hasShowDeletedFeature && canShowDeleted && setShowDeleted
          && <FormControlLabel
            control={<Checkbox data-testid="show-deleted-checkbox" checked={includeDeleted} onChange={(_, checked) => handleSetShowDeleted(checked, setShowDeleted, SHOW_DELETED_STORAGE_KEY)} />}
            label={t('common:common.labels.show-deleted')}
          />
        }
      </Stack>
    );
  };

  const renderSavedFilters = (): JSX.Element => {
    return (
      <NewSavedFilterController
        filterOptions={{
          caption: t('common:component.filter.labels.saved-caption'),
          saveButtonText: t('common:component.filter.actions.saved-filter'),
        }}
        onFiltersChanged={(newFilters) => {
          onCloseClicked?.();
          onFiltersChanged?.(newFilters);
          addLocalStorageItem(FILTERS_KEY, {
            [storageKey]: newFilters,
          });
        }}
        savedFilter={savedFilter}
        setSavedFilter={setSavedFilter}
      />
    );
  };

  const stringifyFilter = (): string => {
    const resultGroupedByFilterName = filters.reduce<Record<string, string[]>>((acc, filter) => {
      acc[filter.definition.label] ??= [];
      const filterValue =
        filter.definition.type === 'entity' || filter.definition.type === TypeDefinitionTypes.REFERENCE
          ? (filter.value as EntityField).display
          : (filter.value as string);
      acc[filter.definition.label].push(filterValue);
      return acc;
    }, {});

    const result = Object.entries(resultGroupedByFilterName)
      .map(([filterName, filterValues]) => {
        return t('common:component.filter.labels.is', {
          field: filterName,
          value: filterValues.join(t('common:component.filter.labels.or')),
        });
      })
      .join(t('common:component.filter.labels.and'));
    return result;
  };

  const saveFilter = (filtersToSave: AppliedFilter[]): void => {
    setHasFilterChanged(false);

    if (isCurrentFilterAlreadySaved()) {
      enqueueSnackbar(t('common:component.filter.hint.filter-already-saved'), { variant: 'warning' });
      return;
    }

    setSavedFilter([
      ...savedFilter,
      {
        id: ulid(),
        created: Date.now(),
        filters: filtersToSave,
        name: stringifyFilter(),
      },
    ]);
  };

  const handleSave = (): void => {
    saveFilter(filters);
  };

  const handleReset = (): void => {
    addLocalStorageItem(FILTERS_KEY, {
      [storageKey]: [],
    });
    onFiltersChanged([]);
  };

  const renderActions = (): JSX.Element => <>
    <Button disabled={!filters.length} onClick={handleReset} startIcon={<Refresh />} data-testid="reset-filters-button">
      {t('common:common.action.reset')}
    </Button>
    <Button disabled={!hasFilterChanged} onClick={handleSave} startIcon={<Save />} data-testid="save-filters-button">
      {t('common:common.action.save-filters')}
    </Button>
  </>;

  const isCurrentFilterAlreadySaved = (): boolean => {
    return savedFilter.some((savedFilterElements) => {
      return isAppliedFilterArrayEqual(savedFilterElements.filters, filters);
    });
  };

  useEffect(() => {
    if (!savedFilter.length && filters.length > 0) setHasFilterChanged(true);
    setSavedFiltersInLocalStorage({ ...savedFiltersInLocalStorage, [storageKey]: savedFilter });
  }, [savedFilter]); // Intentionally excluding filters to prevent unnecessary calls

  useEffect(() => {
    if (!filters.length) return setHasFilterChanged(false);
    setHasFilterChanged(true);
  }, [filters]);

  return (
    <Layout
      actions={renderActions()}
      onCloseClicked={onCloseClicked}
      filterComponent={renderFilters()}
      savedFiltersComponent={renderSavedFilters()}
      savedFiltersLength={savedFilter.length}
      {...props}
    />
  );
};
