import { Chip, useTheme } from '@mui/material';
import { FC } from 'react';

interface StageBadgeProps {
  stage: string;
  display: string;
}

export const StageBadge: FC<StageBadgeProps> = ({ stage, display }) => {
  const theme = useTheme();
  const getStageColor = (stageId: string): { backgroundColor: string; contrastText: string } => {
    switch (stageId) {
      case 'available-to-lease':
        return { backgroundColor: theme.palette.success.main, contrastText: theme.palette.getContrastText(theme.palette.success.main) };
      case 'pending-account-approval':
        return { backgroundColor: theme.palette.warning.main, contrastText: theme.palette.getContrastText(theme.palette.warning.main) };
      case 'leased':
        return { backgroundColor: theme.palette.grey[700], contrastText: theme.palette.getContrastText(theme.palette.grey[700]) };
      default:
        return { backgroundColor: theme.palette.grey[300], contrastText: theme.palette.getContrastText(theme.palette.grey[300]) };
    }
  };

  return (
    <Chip
      data-testid="lease-stage-badge"
      label={display}
      size="small"
      sx={{
        '& .MuiChip-label': {
          maxWidth: '100%',
          fontWeight: 500,
          fontSize: '0.75rem',
        },
        backgroundColor: getStageColor(stage).backgroundColor,
        color: getStageColor(stage).contrastText,
        width: '100%',
      }}
    />
  );
};
