import { Time } from '@eagle/common';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { Nullable } from '../types';
import { getLocationWeather, roundLatLng } from '../util';
import { useConfig } from './use-config';

interface UseLocationWeatherQueryOptions {
  latLng: L.LatLng;
  units?: 'metric' | 'imperial';
}

export const useLocationWeatherQuery = ({ latLng, units }: UseLocationWeatherQueryOptions): UseQueryResult<Nullable<Record<string, string | number>>> => {
  const config = useConfig();
  const roundedLatLng = roundLatLng(latLng, 4);

  return useQuery({
    queryKey: ['location-weather', roundedLatLng, units, config.hereMaps?.apiKey],
    queryFn: () => getLocationWeather(roundedLatLng, units, config.hereMaps?.apiKey),
    staleTime: Time.minutes(1),
  });
};
