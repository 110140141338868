import { RoleFunction } from '@eagle/common';
import { Replay, WarningAmber } from '@mui/icons-material';
import { ButtonBase, MenuItem, Select, Stack, useTheme } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IconWithTooltip, useBoolFlag } from '../../components';
import { useHasAuthorization } from '../../util';
import { useContractListContext } from './contract-list-provider';
import { ContractManagementManuallyManageMenu } from './contract-management-manually-manage-menu';
import { ContractUnavailabilityReason, SelectablePlan, ThingWithContractData } from './contract-management.types';
import { canSelectDataSubscriptionPlan, deserializeSelectValue, formatDataSubscriptionPlanWithTerm, formatPrice, getSelectablePlans, serializeSelectValue } from './contract-management.utils';

const SYSTEM_ADMIN_ROLE = [RoleFunction.SYSTEM_ADMINISTRATOR];

interface ContractListPlanSelectProps {
  item: ThingWithContractData;
}

export const ContractListPlanSelect: FC<ContractListPlanSelectProps> = ({ item }) => {
  const { hasAuthorization } = useHasAuthorization();
  const hasSysAdminPermissions = hasAuthorization(SYSTEM_ADMIN_ROLE);
  const hasAdminContractManualManagementFlag = useBoolFlag('admin-contract-management-manual-contract-management-feature');
  const hasContractManualManagementPermission = hasSysAdminPermissions && hasAdminContractManualManagementFlag;
  const { state: { selectedCurrency, cartItems, checkout }, addCartItem, removeCartItem } = useContractListContext();
  const { t, i18n } = useTranslation(['common', 'data-subscription']);
  const theme = useTheme();

  const itemInCart = cartItems.find((cartItem) => cartItem.thing._id === item._id);

  const getValue = (): string => {
    if (!itemInCart) {
      return '';
    }
    return serializeSelectValue({ dataSubscriptionPlanId: itemInCart.dataSubscriptionPlan.dataSubscriptionPlanId, term: itemInCart.term });
  };

  const removeFromCart = (): void => {
    removeCartItem(item._id);
  };

  const selectablePlans = getSelectablePlans(item.thingTypeWithDataSubscriptions?.dataSubscriptionPlans ?? [], selectedCurrency);
  const canSelect = canSelectDataSubscriptionPlan(item.contract.dataSubscription, selectablePlans);
  const emptyMessage = canSelect.result ? t('common:page.contract-management.select-a-plan.labels') : t('common:page.contract-management.no-plan-available.labels');

  const findOption = (value: string): SelectablePlan => {
    const deserializedValue = deserializeSelectValue(value);
    const option = selectablePlans.find((option) => option.plan.dataSubscriptionPlanId === deserializedValue.dataSubscriptionPlanId && option.term === deserializedValue.term);
    if (!option) {
      throw new Error('Missing option.');
    }
    return option;
  };

  return (
    <Stack alignItems="center" direction="row" spacing={1}>
      <Select
        data-testid="plan-select"
        disabled={!canSelect.result || checkout.status !== 'idle'}
        displayEmpty
        fullWidth
        onChange={(e) => {
          if (e.target.value === '') {
            return removeFromCart();
          }
          const option = findOption(e.target.value);
          addCartItem({
            thing: item.thing,
            dataSubscriptionPlan: option.plan,
            term: option.term,
          });
        }}
        renderValue={(value) => {
          if (value === '') {
            return emptyMessage;
          }
          const option = findOption(value);
          return formatDataSubscriptionPlanWithTerm(i18n, option.plan, option.term);
        }}
        size="small"
        sx={{ overflow: 'hidden' }}
        value={getValue()}
      >
        <MenuItem value=""><em>{emptyMessage}</em></MenuItem>
        {selectablePlans.map((selectablePlan) => {
          return (
            <MenuItem
              key={`${selectablePlan.plan.dataSubscriptionPlanId}-${selectablePlan.term}`}
              data-testid={`${selectablePlan.plan.dataSubscriptionPlanId}-${selectablePlan.term}`}
              value={serializeSelectValue({ dataSubscriptionPlanId: selectablePlan.plan.dataSubscriptionPlanId, term: selectablePlan.term })}
            >
              <Stack>
                <span>{formatDataSubscriptionPlanWithTerm(i18n, selectablePlan.plan, selectablePlan.term)}</span>
                <span>{formatPrice(i18n, selectedCurrency, selectablePlan.price)}</span>
              </Stack>
            </MenuItem>
          );
        })}
      </Select>
      {!canSelect.result && (
        <IconWithTooltip arrow data-testid="plan-select-warning" icon={<WarningAmber color="warning" />} title={t(getWarningKey(item, canSelect.reason))} />
      )}
      {itemInCart && (
        <ButtonBase data-testid="plan-select-remove-button" disabled={checkout.status !== 'idle'} disableRipple onClick={removeFromCart} sx={{ color: theme.palette.text.secondary }}>
          <Replay />
        </ButtonBase>
      )}
      {hasContractManualManagementPermission && (
        <ContractManagementManuallyManageMenu item={item} selectablePlans={selectablePlans} />
      )}
    </Stack>
  );
};

const getWarningKey = (item: ThingWithContractData, reason: ContractUnavailabilityReason): string => {
  switch (reason) {
    case 'no-contract':
      return 'common:page.contract-management.no-contract-for-vehicle.labels';
    case 'already-in-cart':
      return 'common:page.contract-management.plan-already-in-another-cart.labels';
    case 'no-plans':
      return 'common:page.contract-management.plan-unavailable-for-selected-currency.labels';
    case 'non-owner':
      return item.contract.dataSubscription?.lastDataSubscription.finish
        ? 'common:page.contract-management.expired-contract-non-owner.labels'
        : 'common:page.contract-management.contract-non-owner.labels';
  }
};
