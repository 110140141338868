/* eslint-disable react-hooks/rules-of-hooks */
import { Image } from '@eagle/api-types';
import { PersonType, ThingType } from '@eagle/core-data-types';
import { Link, Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { FC } from 'react';
import { useHref } from 'react-router';
import { useCustomRoutes } from '../../hooks';
import { CacheDataTypes } from '../../types';
import { EntityAvatar } from '../entity-avatar';
import { FetchOneOfAll } from '../fetch';
import { ListPaperColumn } from '../list-item';
import { SimpleElement } from '../simple-element';
import { BoldMatchedText } from '../text';

interface Props {
  data: {
    _id: string;
    display: string;
    thingTypeId?: string;
    personTypeId?: string;
    image?: Image;
  };
  'data-testid'?: string;
  showAvatar?: boolean;
  inlineImage?: string;
  indicator?: string;
  link?: boolean;
  linkTestId?: string;
  sx?: SxProps;
}

export const DisplayColumn: FC<Props> = ({ data, showAvatar, indicator, inlineImage, link, linkTestId, sx, ...props }) => {
  const { thing: customRoutesThing, person: customRoutesPerson } = useCustomRoutes();
  const dataType = data.thingTypeId ? CacheDataTypes.THING_TYPE : (data.personTypeId ? CacheDataTypes.PERSON_TYPE : null);
  const id = (dataType === CacheDataTypes.THING_TYPE) ? data.thingTypeId : (data.personTypeId || null);

  let display = <BoldMatchedText text={data.display} />;

  if (link) {
    const route = dataType === CacheDataTypes.THING_TYPE ? customRoutesThing : customRoutesPerson;
    const url = useHref(`/${route}/${data._id}`);
    display = <Link data-testid={linkTestId} href={url}>{data.display}</Link>;
  }

  const column = !dataType ? <ListPaperColumn sx={sx} data-testid={props['data-testid']} label>{data.display}</ListPaperColumn> : <ListPaperColumn
    data-testid={props['data-testid']}
    sx={sx}
    label={<FetchOneOfAll
      id={id}
      dataType={dataType}
      notFoundFactory={() => <span />}
      renderFactory={(entityType: ThingType | PersonType) => <SimpleElement data={entityType} />}
    />}
  >
    {display}
  </ListPaperColumn>;

  if (showAvatar) {
    return <Stack direction='row' sx={sx} spacing={1} alignItems='center' marginBottom={0}>
      <EntityAvatar inlineImage={inlineImage} badge={indicator} data={data} />
      {column}
    </Stack>;
  }

  return <>{column}</>;
};
