import { Chip } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ContractStatus, ThingWithContractData } from './contract-management.types';
import { getContractStatus } from './contract-management.utils';

const statusToChipDefinition: Record<ContractStatus, { label: string; color: 'success' | 'warning' | 'error' | 'default' }> = {
  'in-contract': { label: 'common:page.contract-management.contract-status.in-contract.labels', color: 'success' },
  'upcoming': { label: 'common:page.contract-management.contract-status.upcoming.labels', color: 'warning' },
  'recently-expired': { label: 'common:page.contract-management.contract-status.expired.labels', color: 'error' },
  'out-of-contract': { label: 'common:page.contract-management.contract-status.out-of-contract.labels', color: 'error' },
  'not-renewable': { label: 'common:page.contract-management.contract-status.contract-not-renewable.labels', color: 'error' },
  'unknown': { label: 'common:page.contract-management.contract-status.unknown.labels', color: 'default' },
};

interface ContractStatusChipProps {
  fullWidth?: boolean;
  item: ThingWithContractData;
}

export const ContractListStatusChip: FC<ContractStatusChipProps> = ({ fullWidth, item }) => {
  const { t } = useTranslation(['common']);
  const status = getContractStatus(item);
  const chipDefinition = statusToChipDefinition[status];

  return (
    <Chip data-testid="status-chip" color={chipDefinition.color} label={t(chipDefinition.label)} sx={fullWidth ? { width: '100%' } : undefined} />
  );
};
