import { DesktopTimePicker } from '@mui/lab';
import { TimePickerView } from '@mui/lab/TimePicker/shared';
import { TextField, Typography } from '@mui/material';
import { DateTime, DurationUnit } from 'luxon';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Nullable } from '../../types';
import { hourCycle } from '../../util';
import { DateErrors, DateTimeRangeError, getTimeFormatString } from '../../util/date';

interface Props {
  'data-testid'?: string;
  disabled: boolean;
  error?: DateTimeRangeError;
  handleDisableEscapeKey: (keyPressed: boolean) => void;
  label: string;
  maxRange: number;
  maxRangeUnit: DurationUnit;
  setTime: (date?: DateTime) => void;
  setTimeError: (error?: DateTimeRangeError) => void;
  time?: DateTime;
  views?: TimePickerView[];
}

export const TimePicker: FC<Props> = ({
  disabled,
  error,
  handleDisableEscapeKey,
  label,
  maxRange,
  maxRangeUnit,
  setTime,
  setTimeError,
  time,
  views = ['hours', 'minutes', 'seconds'],
  ...props
}): JSX.Element => {
  const { t } = useTranslation(['common']);
  const [open, setOpen] = useState(false);
  const hour12 = hourCycle();

  const timeFormat = getTimeFormatString(views.indexOf('seconds') === -1 ? { hour: 'numeric', minute: 'numeric' } : { hour: 'numeric', minute: 'numeric', second: 'numeric' });

  const handleEscapeKeyPress = (keyPressed: boolean): void => {
    handleDisableEscapeKey(keyPressed);
    setOpen(keyPressed);
  };

  const handleTimeChange = (date: Nullable<Date>, input?: string): void => {
    if (!input && !date) {
      setTimeError(DateTimeRangeError.INVALID_TIME);
      return;
    }

    if (input) {
      const inputDateTime = DateTime.fromFormat(input, timeFormat);

      if (inputDateTime.invalidReason === DateErrors.UNPARSABLE) {
        setTimeError(DateTimeRangeError.INVALID_FORMAT);
        return;
      }
      if (inputDateTime.invalidReason === DateErrors.OUT_OF_RANGE) {
        setTimeError(DateTimeRangeError.INVALID_TIME);
        return;
      }

      if (inputDateTime.isValid) {
        const { result } = DateTime.fromFormatExplain(input, timeFormat);
        const dateObjectUnits = views.indexOf('seconds') === -1 ? { hour: Number(result?.hour), minute: Number(result?.minute) } : { hour: Number(result?.hour), minute: Number(result?.minute), second: Number(result?.second) };

        const updatedDateTime = DateTime.now().set(dateObjectUnits);
        setTime(updatedDateTime);
        setTimeError(undefined);
      }
      return;
    }

    if (date) {
      const newDateTime = DateTime.fromJSDate(date);
      const { hour, minute, second } = newDateTime;
      const updatedDateTime = DateTime.now().set({ hour, minute, second });
      setTime(updatedDateTime);
      setTimeError(undefined);
    }
  };

  const ErrorMessage = (): JSX.Element => {
    if (!error) return <></>;
    if (error !== DateTimeRangeError.INVALID_RANGE_OVER) return <>{t(`common:component.date-picker.hint.${error}`)}</>;
    // @ts-expect-error Normally `count` should be a number, but we want to pass a string here and react-i18next types don't accept that.
    return <>{t(`common:component.date-picker.hint.${error}`, {
      count: t(`common:component.date-time-range-picker.hint.${maxRangeUnit}WithCount`, { count: maxRange }),
    })}</>;
  };

  return (
    <DesktopTimePicker
      ampm={hour12}
      disabled={disabled}
      label={label}
      mask=""
      onChange={handleTimeChange}
      onClose={() => {
        if (!open) return;
        handleEscapeKeyPress(false);
      }}
      onOpen={() => handleEscapeKeyPress(true)}
      open={open}
      openTo="hours"
      PopperProps={{ style: { zIndex: 1400 } }}
      renderInput={(params) => (
        <TextField
          {...params}
          error={false}
          FormHelperTextProps={{ sx: { m: 0, minHeight: '20px' } }}
          helperText={error && <Typography variant="caption" sx={{ color: 'red' }}><ErrorMessage /></Typography>}
          size="small"
          data-testid={props['data-testid']}
        />
      )}
      value={time?.toJSDate() ?? null}
      views={views}
    />
  );
};
