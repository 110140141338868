import { AccountType, GetNextStagesResponse, StakeholderAccountResult } from '@eagle/core-data-types';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useAuthenticated } from '../../auth';

export const useStakeholderAccountQuery = ({
  accountId,
  role,
  sharedThingId,
}: {
  accountId: string;
  role: string;
  sharedThingId: string;
}): UseQueryResult<StakeholderAccountResult> => {
  const { axios } = useAuthenticated();

  return useQuery({
    queryKey: ['stakeholder-account', accountId, role, sharedThingId],
    queryFn: async () => {
      const response = await axios.get<StakeholderAccountResult>(`/api/v1/shared-thing/${sharedThingId}/life-cycle/stakeholder/${role}/account/${accountId}`);
      return response.data;
    },
  });
};

export const useStakeholderAccountTypeQuery = (
  sharedThingId: string,
  role: string,
  accountId: string,
  enabled: boolean,
): UseQueryResult<AccountType> => {
  const { restClient } = useAuthenticated();

  return useQuery<AccountType, Error>({
    queryKey: ['stakeholder-account-type', sharedThingId, role, accountId],
    queryFn: async () => {
      return restClient.sharedThing.getStakeholderAccountType(sharedThingId, role, accountId);
    },
    enabled,
  });
};

export const useSharedThingNextStagesQuery = (
  sharedThingId: string,
  enabled: boolean,
): UseQueryResult<GetNextStagesResponse> => {
  const { restClient } = useAuthenticated();

  return useQuery<GetNextStagesResponse, Error>({
    queryKey: ['shared-thing-next-stages', sharedThingId],
    queryFn: async () => {
      return restClient.sharedThing.getNextStages(sharedThingId);
    },
    enabled,
  });
};
