export const DEFAULT_MAP_LOCATION_RESULT = {
  op: 'ui',
  name: 'BasicColumn',
  innerSx: {
    '& > label': {
      lineHeight: 1.2,
    },
  },
  label: {
    op: 'context',
    path: 'title',
  },
  children: {
    op: 'ui',
    name: 'Typography',
    children: {
      op: 'context',
      path: 'address.label',
    },
  },
};

export const DEFAULT_MAP_GEOFENCE_RESULT = {
  op: 'ui',
  name: 'BasicColumn',
  innerSx: {
    '& > label': {
      lineHeight: 1.2,
    },
  },
  label: {
    op: 'context',
    path: 'geofenceType.display',
  },
  children: {
    op: 'ui',
    name: 'Typography',
    children: {
      op: 'context',
      path: 'geofence.display',
    },
  },
};

export const DEFAULT_MAP_THING_SEARCH_RESULT = {
  op: 'ui',
  name: 'BasicColumn',
  innerSx: {
    '& > label': {
      lineHeight: 1.2,
    },
  },
  reverse: true,
  label: {
    op: 'ui',
    name: 'Stack',
    direction: 'row',
    spacing: 0.5,
    children: [
      {
        op: 'ui',
        name: 'EventAddress',
        sx: { fontStyle: 'normal' },
        variant: 'caption',
        addressUnavailableText: {
          op: 'context',
          path: 'addressUnavailableText',
        },
        location: {
          op: 'context',
          path: 'location',
        },
      },
      {
        op: 'if',
        condition: {
          op: 'context',
          path: 'showViewDetail',
        },
        then: {
          op: 'ui',
          name: 'Stack',
          direction: 'row',
          spacing: 0.5,
          children: [
            {
              op: 'ui',
              name: 'Typography',
              sx: { fontStyle: 'normal'},
              variant: 'caption',
              children: '-',
            },
            {
              op: 'ui',
              name: 'Link',
              href: {
                op: 'context',
                path: 'href',
              },
              sx: { fontStyle: 'normal', pointerEvents: 'auto', whiteSpace: 'nowrap' },
              variant: 'caption',
              children: {
                op: 'context',
                path: 'viewDetailText',
              },
            },
          ],

        },
        else: null,
      },
    ],
  },
  children: {
    op: 'ui',
    name: 'Typography',
    children: {
      op: 'context',
      path: 'thing.display',
    },
  },
};
