import { Skeleton } from '@mui/material';
import { get, isNumber, isString } from 'lodash';
import { FC } from 'react';
import { useLocationWeatherQuery } from '../../hooks';

interface Props {
  append?: string;
  latLng: L.LatLng;
  path: string;
  units?: 'metric' | 'imperial';
  fallback?: string;
  allowDecimal?: boolean;
}

export const WeatherField: FC<Props> = ({ allowDecimal, append = '', fallback, latLng, path = 'temperature', units }) => {
  const { data: weather, isLoading } = useLocationWeatherQuery({ latLng, units });

  if (isLoading) {
    return <Skeleton />;
  }

  const value = (() => {
    const fieldInfo = get(weather, path);

    if (isNumber(fieldInfo) && !allowDecimal) {
      return Math.round(fieldInfo);
    }

    return fieldInfo;
  })();

  if (!isString(path) || !value) {
    return fallback ? <>{fallback}</> : null;
  }

  return <>{`${value}${append}`}</>;
};
