import LayersIcon from '@mui/icons-material/Layers';
import { ClickAwayListener, Drawer, Paper, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import chroma from 'chroma-js';
import { FC, useRef, useState } from 'react';
import { scrollbar } from '../../../style';
import { LayerSelectionProps, ToggleLayers, Undefinable } from '../../../types';
import { disableClickPropagation } from '../../../util/maps';
import { MapButton } from '../map-button';
import { MainMenuDrawer } from './main-menu-drawer/main-menu-drawer';
import { MainMenu } from './main-menu/main-menu';
import { baseLayers as defaultBaseLayers } from './map-layers-default';
import { SubMenu } from './sub-menu/sub-menu';

export const SLIDE_DURATION = 200;
export const DRAWER_CONTAINER_ID = 'inlay-map-layer-selection-root';
export const MODAL_DRAWER_CONTAINER_ID = 'modal-inlay-map-layer-selection-root';

export const LayerSelection: FC<LayerSelectionProps> = ({
  activeLayers,
  handleBaseChange,
  handleToggleChange,
  mapBaseLayers,
  mapToggleLayers,
  open,
  selectedBaseLayer,
  selectedToggleLayers,
  setOpen,
  drawerLayerSelection = false,
  buttonSx,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const [selectedSubMenu, setSelectedSubMenu] = useState<ToggleLayers>();
  const [subMenuHeight, setSubMenuHeight] = useState(0);
  const [subMenuWidth, setSubMenuWidth] = useState(0);

  if (!mapBaseLayers.length && !mapToggleLayers.length) return <></>;

  const isLayerActive = (label: ToggleLayers): boolean => {
    return Boolean(activeLayers.find((layer) => layer.label === label));
  };
  const isLayerChecked = (label: ToggleLayers): boolean => selectedToggleLayers.includes(label);
  const openSubMenu = (label: Undefinable<ToggleLayers>): void => setSelectedSubMenu(label);

  const drawerContainer = document.getElementById(MODAL_DRAWER_CONTAINER_ID) ?? document.getElementById(DRAWER_CONTAINER_ID);
  const baseLayers = mapBaseLayers.map((item) => ({ ...item, label: item.label }));
  const previewLayer = defaultBaseLayers().find((item) => item.label === selectedBaseLayer) ?? baseLayers[0];
  const otherLayers = baseLayers.filter((item) => item.label !== selectedBaseLayer);

  disableClickPropagation(ref);

  return (
    <ClickAwayListener onClickAway={() => selectedSubMenu ? setSelectedSubMenu(undefined) : setOpen(false)}>
      {!drawerLayerSelection ?
        <Paper
          ref={ref}
          elevation={3}
          sx={{
            border: `1px solid ${chroma(theme.palette.primary.main).css()}`,
            bottom: '0px',
            cursor: 'default',
            m: 3,
            minWidth: '200px',
            overflowX: 'hidden',
            p: '0.5rem',
            position: 'absolute',
            right: '5em',
            transition: 'width 125ms ease, transform 125ms ease',
            zIndex: 500,
          }}
        >
          {selectedSubMenu
            ? <SubMenu
              closeSubMenu={() => setSelectedSubMenu(undefined)}
              height={subMenuHeight}
              width={subMenuWidth}
              selectedSubMenu={selectedSubMenu}
            />
            : <MainMenu
              handleBaseChange={handleBaseChange}
              handlePreviewLayerClick={() => setOpen(!open)}
              handleToggleChange={handleToggleChange}
              isBottomSectionIn={open}
              isLayerChecked={isLayerChecked}
              isLayerActive={isLayerActive}
              mapToggleLayers={mapToggleLayers}
              openSubMenu={openSubMenu}
              otherLayers={otherLayers}
              previewLayer={previewLayer}
              setSubMenuHeight={setSubMenuHeight}
              setSubMenuWidth={setSubMenuWidth}
              slideIn={!selectedSubMenu}
            />
          }
        </Paper>
        : <>
          <Paper elevation={3} ref={ref} sx={buttonSx}>
            <MapButton onClick={() => setOpen(!open)} sx={{ background: theme.palette.common.white }}>
              <LayersIcon />
            </MapButton>
          </Paper>
          <Box data-testid="inlay-map-layer-selection-root" id={DRAWER_CONTAINER_ID} sx={{ width: 1, height: 1, position: 'absolute', pointerEvents: open ? 'all' : 'none' }}>
            <Drawer
              ref={ref}
              anchor="right"
              open={open}
              BackdropProps={{
                style: { position: 'absolute' },
                onClick: () => setOpen(!open),
              }}
              ModalProps={{
                container: drawerContainer,
                sx: { position: 'absolute' },
              }}
              PaperProps={{
                sx: {
                  py: 1,
                  px: 2,
                  position: 'absolute',
                  width: selectedSubMenu ? '100%' : 'fit-content',
                  bottom: 0,
                  cursor: 'default',
                  overflowX: 'hidden',
                  zIndex: 500,
                  '& .MuiRadio-root': { py: .5 },
                  ...scrollbar,
                },
              }}
              SlideProps={{
                onEnter: (node) => {
                  node.style.transform = 'translateX(0%)';
                },
                onExiting: (node) => {
                  node.style.transform = 'translateX(100%)';
                },
              }}
            >
              {selectedSubMenu ?
                <SubMenu closeSubMenu={() => setSelectedSubMenu(undefined)} selectedSubMenu={selectedSubMenu} />
                :
                <MainMenuDrawer
                  handleBaseChange={handleBaseChange}
                  handlePreviewLayerClick={() => setOpen(!open)}
                  handleToggleChange={handleToggleChange}
                  isLayerChecked={isLayerChecked}
                  isLayerActive={isLayerActive}
                  mapToggleLayers={mapToggleLayers}
                  openSubMenu={openSubMenu}
                  previewLayer={previewLayer}
                  baseLayers={baseLayers} />
              }
            </Drawer>
          </Box>
        </>}
    </ClickAwayListener>
  );
};
