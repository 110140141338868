/* eslint-disable react-hooks/exhaustive-deps */
import CloseIcon from '@mui/icons-material/Close';
import FilterIcon from '@mui/icons-material/FilterAltRounded';
import SearchIcon from '@mui/icons-material/Search';
import { Box, CircularProgress, Container, IconButton, Stack, Typography } from '@mui/material';
import { FC, Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge } from '../../components/badge';
import { BottomNavigationAction } from '../../components/bottom-navigation';
import { ListLayoutMobile } from './index';
import { InternalProps } from './view.types';

export const ListPageViewMobile: FC<InternalProps> = ({
  actions,
  appliedFiltersComponents,
  clearAllFiltersComponent,
  extraActions,
  filterDisabled,
  filterDrawerComponent,
  filtersLength,
  lifecycleTemplate,
  listContent,
  openFilter,
  paginationComponent,
  scrollRef,
  search,
  searchOpen,
  searchRemoved,
  setFilterOpen,
  setSearchOpen,
  showFilterButton,
  showExtraActions,
  stakeholderRole,
  subtitleComponent,
  tableContent,
  ...props
}): JSX.Element => {
  const { t } = useTranslation(['common']);

  const mobileSearchComponent = useMemo(() => {
    if (searchRemoved) return <></>;
    return (
      <Container>
        <Stack direction="column" spacing={1} sx={{ py: 2.5 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h5">{t('common:component.search.labels.label')}</Typography>
            <IconButton
              onClick={() => setSearchOpen(false)}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={{ width: 'inherit' }}>
            {search}
          </Box>
        </Stack>
      </Container>
    );
  }, [search, searchRemoved, setSearchOpen]);

  const filterIcon = (
    <Badge component="div" badgeContent={filtersLength} sx={{ '& .MuiBadge-badge': { top: '3px', right: '-2px' } }}>
      <FilterIcon />
    </Badge>
  );

  const actionComponents = useMemo(
    () => [
      !searchRemoved && <BottomNavigationAction key={'search'} label={t('common:component.search.labels.label')} icon={<SearchIcon />} onClick={() => setSearchOpen(true)} />,
      showFilterButton
      && <BottomNavigationAction
        data-testid='filters-button'
        key={'filter'}
        icon={filterIcon}
        label={t('common:terms.filter')}
        onClick={() => setFilterOpen(true)}
      />,
      showExtraActions
      && <BottomNavigationAction
        data-testid='extraActions-button'
        key={'extraActions'}
        icon={extraActions}
        label={t('admin:page.transfers-list.update-stage-dialog.self-claim.title')}
      />,
      ...actions?.length
        ? actions.map((action, i) => {
          if (action.buttonNode) {
            return <Fragment key={i}>{action.buttonNode}</Fragment>;
          }
          return (
            <BottomNavigationAction
              key={i}
              disabled={action.disabled || action.isLoading}
              label={action?.shortLabel || action.label}
              href={action.href}
              icon={action.isLoading ? <CircularProgress color="inherit" size="1.5rem" /> : action.icon}
              onClick={action.onClick}
              value={action.shortLabel}
              data-testid={action.testId ?? 'action-button'}
            />
          );
        })
        : [],
    ],
    [actions, extraActions, filtersLength, searchRemoved, setFilterOpen, t, showFilterButton],
  );

  return (
    <ListLayoutMobile
      data-testid={props['data-testid']}
      actions={actionComponents}
      clearAllFiltersButton={clearAllFiltersComponent}
      content={listContent ?? tableContent ?? <></>}
      filterContent={filterDrawerComponent}
      filters={appliedFiltersComponents}
      pagination={listContent && paginationComponent}
      scrollRef={scrollRef}
      search={mobileSearchComponent}
      searchOpen={searchOpen}
      setSearchOpen={setSearchOpen}
      showFilters={openFilter}
      subtitle={subtitleComponent}
    />
  );
};
