import { SxProps, Theme } from '@mui/material';
import { SetStateAction } from 'react';
import { FilterTypes } from '../components';
import { CacheDataTypes } from './cache';
import { CommonEntityWithDeleted } from './entity';

export enum ToggleLayers {
  geofences = 'geofences',
  incidents = 'incidents',
  petrolStations = 'petrol-stations',
  pointOfInterest = 'point-of-interest',
  traffic = 'traffic',
  transport = 'transport',
}

export interface LayerSelectionProps {
  activeLayers: MapLayerToggle[];
  handleBaseChange: (label: BaseLayers) => void;
  handleToggleChange: (label: ToggleLayers) => void;
  mapBaseLayers: MapLayerImage[];
  mapToggleLayers: MapLayerToggle[];
  open: boolean;
  selectedBaseLayer: BaseLayers;
  selectedToggleLayers: ToggleLayers[];
  setOpen: (value: SetStateAction<boolean>) => void;
  drawerLayerSelection: boolean;
  buttonSx?: SxProps<Theme>;
}

export interface MapLayerToggle {
  label: ToggleLayers;
  layerComponent: JSX.Element;
  maxBoundingBoxSize?: number;
  minZoomLevel?: number;
  showSubMenuButton?: boolean;
}

export interface MapImageButton {
  image: JSX.Element;
  label: string;
  onClick: () => void;
}

export interface MapLayerImage {
  image: JSX.Element;
  label: BaseLayers;
  layerComponent?: JSX.Element;
  mapStyle: (theme: Theme, apiKey: string, enableSource?: boolean) => Record<string, unknown>;
}

export enum BaseLayers {
  classic = 'classic',
  parcelBoundaries = 'parcel-boundaries',
  satellite = 'satellite',
}

export interface SubSectionData {
  cacheDataTypes: CacheDataTypes;
  cacheFilter: (items: CommonEntityWithDeleted[]) => CommonEntityWithDeleted[];
  itemType: string;
  storageKey: MapStorageKeys;
  typePropertyName: FilterTypes;
}

export enum SubMenuSectionData {
  GEOFENCE_TYPE = 'geofence-types',
  POINT_OF_INTEREST_TYPE = 'point-of-interest-type'
}

export interface MapPopupSize {
  height: number;
  width: number;
}

export enum MapStorageKeys {
  GEOFENCE = 'GEOFENCE',
  HISTORY_JOURNEY_SAVED_POSITION = 'HISTORY_JOURNEY_SAVED_POSITION',
  HISTORY_MAP_SAVED_POSITION = 'HISTORY_MAP_SAVED_POSITION',
  HISTORY_SNAP_TO_ROAD_PREFERENCE = 'HISTORY_SNAP_TO_ROAD_PREFERENCE',
  MAP_BASE_LAYER = 'MAP_BASE_LAYER',
  MAP_TOGGLE_LAYERS = 'MAP_TOGGLE_LAYERS',
  POINT_OF_INTEREST = 'POINT_OF_INTEREST',
  OPERATOR_MAP = 'OPERATOR_MAP_POSITION',
  STORYBOOK_POSITION = 'STORYBOOK_POSITION',
  THING_MAP = 'THING_MAP_POSITION',
  THING_SEARCH_MAP = 'THING_SEARCH_MAP_POSITION',
}

export enum MapPanes {
  BREADCRUMBS = 'breadcrumbs',
  CLUSTER_LINE = 'clusterLine',
  HOVER_LINE = 'hoverLine',
  POPUP_PANE = 'popupPane',
  START_END_MARKERS = 'startEndMarkers',
}

export interface MapPosition {
  alt?: number;
  lat: number;
  lng: number;
  timeStamp: number;
}
