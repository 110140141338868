import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { T_MANY } from '../../constants';
import { GroupChipElement } from '../group-chip-element';
import { ListPaperColumn } from '../list-item';

interface Props {
  'data-testid'?: string;
  data: {
    groupIds: string[];
  };
}

export const GroupColumn: FC<Props> = ({ data, ...props }) => {
  const { t } = useTranslation(['common', 'track']);

  return <ListPaperColumn data-testid={props['data-testid']} label={t('common:terms.group', { count: T_MANY })} sx={{ overflow: 'hidden' }}>
    <GroupChipElement groupIds={data.groupIds} />
  </ListPaperColumn>;
};
