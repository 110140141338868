import CloseIcon from '@mui/icons-material/Close';
import { Divider, IconButton, List, ListItem, Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { T_MANY } from '../../../../constants';
import { BaseLayers, MapLayerImage, MapLayerToggle, ToggleLayers } from '../../../../types';
import { testid } from '../../../../util';
import { useBoolFlag } from '../../../flags';
import { RadioButtonSet } from '../../../radio-button-set';
import { useMapLayers } from '../../hooks';
import { LayerSelectionCheckBox } from '../layer-selection-check-box';

interface Props {
  handleBaseChange: (label: BaseLayers) => void;
  handlePreviewLayerClick: () => void;
  handleToggleChange: (layer: ToggleLayers) => void;
  isLayerActive: (label: ToggleLayers) => boolean;
  isLayerChecked: (label: ToggleLayers) => boolean;
  mapToggleLayers: MapLayerToggle[];
  openSubMenu: (label?: ToggleLayers) => void;
  previewLayer: MapLayerImage;
  baseLayers: MapLayerImage[];
}

export const MainMenuDrawer: FC<Props> = ({
  handleBaseChange,
  handlePreviewLayerClick,
  handleToggleChange,
  isLayerActive,
  isLayerChecked,
  mapToggleLayers,
  openSubMenu,
  previewLayer,
  baseLayers,
}) => {
  const { t } = useTranslation(['common']);
  const theme = useTheme();
  const { displayThingsLayerSelection, isThingsLayerSelected, setIsThingsLayerSelected } = useMapLayers();
  const displayThingsLayerSelectionFlag = useBoolFlag('portals-things-map-layer');

  return <>
    <IconButton aria-label="close" onClick={handlePreviewLayerClick} sx={{ position: 'absolute', right: theme.spacing(1), top: 0, color: theme.palette.grey[500] }}>
      <CloseIcon />
    </IconButton>
    <Typography variant="body1">{t('common:component.map.layers.labels.map-layers')}</Typography>
    <List>
      {displayThingsLayerSelection && displayThingsLayerSelectionFlag && (
        <LayerSelectionCheckBox
          checked={isThingsLayerSelected}
          handleClick={() => { setIsThingsLayerSelected(!isThingsLayerSelected); }}
          label={t('terms:thing', { count: T_MANY })}
        />
      )}
      {mapToggleLayers.map(({ label, showSubMenuButton }, i) => {
        const isActive = isLayerActive(label);
        return (
          <ListItem disablePadding key={`map-toggle-layer-selection-${i}`}>
            <LayerSelectionCheckBox
              checked={isActive ? isLayerChecked(label) : false}
              disabled={!isActive}
              handleClick={() => handleToggleChange(label)}
              handleSubMenuButtonClick={() => openSubMenu(label)}
              label={t(`common:component.map.layers.labels.${label}`)}
              showSubMenuButton={showSubMenuButton}
              data-testid={testid`map-layer-selection-${i}`}
            />
          </ListItem>
        );
      })}
    </List>
    {!!baseLayers.length && <>
      <Divider sx={{ mb: 1 }} />
      <Typography variant="body1">{t('common:component.map.layers.labels.map-type')}</Typography>
      <RadioButtonSet
        data-testid="map-type-selection"
        options={baseLayers.map((item) => ({ value: item.label, label: t(`common:component.map.layers.labels.${item.label.toLowerCase()}`), testId: `${item.label}-input` }))}
        radioSize="small"
        selected={previewLayer.label}
        row={false}
        setSelected={(value) => handleBaseChange(value as BaseLayers)}
      />
    </>}
  </>;
};
