export const ALERT_LIST_HEADERS = {
  op: 'ui',
  name: 'TableRow',
  children: [{
    op: 'ui',
    name: 'TableCell',
    children: {
      op: 'translate',
      translation: 'common:terms.date-time',
    },
  },
  {
    op: 'ui',
    name: 'TableCell',
    children: {
      op: 'translate',
      translation: 'common:terms.alert-type',
    },
  },
  {
    op: 'ui',
    name: 'TableCell',
    children: {
      op: 'translate',
      translation: 'terms:thing',
      options: {
        count: 1,
      },
    },
  },
  {
    op: 'ui',
    name: 'TableCell',
    children: {
      op: 'translate',
      translation: 'common:terms.location',
    },
  },
  {
    op: 'ui',
    name: 'TableCell',
    children: {
      op: 'translate',
      translation: 'common:terms.group',
      options: {
        count: 999,
      },
    },
  }],
};

export const ALERT_LIST_ITEM = [{
  op: 'ui',
  name: 'TableCell',
  children: {
    op: 'ui',
    name: 'DateDisplay',
    date: {
      op: 'context',
      path: 'occurred',
    },
    timezone: {
      op: 'context',
      path: 'data.location.timeZone.id',
    },
  },
},
{
  op: 'ui',
  name: 'TableCell',
  sx: {
    width: '200px',
  },
  children: {
    op: 'ui',
    name: 'AlertLabel',
    alert: {
      op: 'context',
    },
  },
},
{
  op: 'ui',
  name: 'TableCell',
  children: {
    op: 'ui',
    name: 'BasicColumn',
    reverse: true,
    innerSx: {
      maxWidth: '220px',
    },
    label: {
      op: 'ui',
      name: 'EntityDisplayMultiple',
      'data-testid': 'person-display',
      dataType: 'person',
      ids: {
        op: 'context',
        path: 'personIds',
      },
    },
    children: {
      op: 'ui',
      name: 'EntityDisplayMultiple',
      dataType: 'thing',
      'data-testid': 'thing-display',
      ids: {
        op: 'context',
        path: 'thingIds',
      },
    },
  },
},
{
  op: 'ui',
  name: 'TableCell',
  children: {
    op: 'ui',
    name: 'FormatAddress',
    sx: {
      maxWidth: '250px',
    },
    value: {
      op: 'context',
      path: 'data.location.address',
    },
    addressUnavailableText: {
      op: 'translate',
      translation: 'common:page.thing-map.address-unavailable.hint',
    },
  },
},
{
  op: 'ui',
  name: 'TableCell',
  sx: {
    maxWidth: '220px',
  },
  children: {
    op: 'ui',
    name: 'GroupChipElement',
    groupIds: {
      op: 'context',
      path: 'groupIds',
    },
    type: 'group',
    noResultsText: {
      op: 'translate',
      translation: 'common:component.alert-table.hint.no-results.groups',
    },
  },
}];

export const ALERT_LIST_ITEM_CARD = {
  op: 'ui',
  name: 'Paper',
  sx: {
    display: 'flex',
    flexDirection: 'column',
    p: 2,
    gap: 2,
  },
  children: [
    {
      op: 'ui',
      name: 'BasicColumn',
      wrap: true,
      reverse: true,
      avatar: {
        op: 'ui',
        name: 'Icon',
        icon: 'ThingIcon',
      },
      label: {
        op: 'ui',
        name: 'EntityDisplayMultiple',
        'data-testid': 'person-display',
        dataType: 'person',
        ids: {
          op: 'context',
          path: 'personIds',
        },
      },
      children: {
        op: 'ui',
        name: 'EntityDisplayMultiple',
        'data-testid': 'thing-display',
        dataType: 'thing',
        ids: {
          op: 'context',
          path: 'thingIds',
        },
      },
    },

    {
      op: 'ui',
      name: 'BasicColumn',
      wrap: true,
      avatar: {
        op: 'ui',
        name: 'Icon',
        icon: 'TimeIcon',
      },
      children: {
        op: 'ui',
        name: 'DateDisplay',
        date: {
          op: 'context',
          path: 'occurred',
        },
        timezone: {
          op: 'context',
          path: 'data.location.timeZone.id',
        },
      },
    },

    {
      op: 'ui',
      name: 'BasicColumn',
      wrap: true,
      avatar: {
        op: 'ui',
        name: 'Icon',
        icon: 'LocationIcon',
      },
      children: {
        op: 'ui',
        name: 'FormatAddress',
        value: {
          op: 'context',
          path: 'data.location.address',
        },
        addressUnavailableText: {
          op: 'translate',
          translation: 'common:page.thing-map.address-unavailable.hint',
        },
      },
    },
    {
      op: 'ui',
      name: 'Box',
      children: {
        op: 'ui',
        name: 'GroupChipElement',
        groupIds: {
          op: 'context',
          path: 'groupIds',
        },
        type: 'group',
      },
    },
    {
      op: 'ui',
      name: 'AlertLabel',
      align: 'center',
      alert: {
        op: 'context',
      },
    },
  ],
};
