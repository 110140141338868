import { createContext, FC, PropsWithChildren, useContext } from 'react';
import { AuthStatus, DomainCustomConfig, useAuth } from '../auth';
import { applyDefaultConfig } from '../util';

export const configContext = createContext<Partial<DomainCustomConfig>>({});

export const ConfigProvider: FC<PropsWithChildren & { config: Partial<DomainCustomConfig> }> = ({ config, children }) => {
  return <configContext.Provider value={applyDefaultConfig(config)}>{children}</configContext.Provider>;
};

export const useConfig = (): Partial<DomainCustomConfig> => {
  return useContext(configContext);
};

export const useSwitchAwareConfig = (): Partial<DomainCustomConfig> => {
  const config = useConfig();
  const auth = useAuth();

  const switchedConfig = auth.state?.status === AuthStatus.AUTHENTICATED ? auth.state.switchedConfig : undefined;
  return switchedConfig ?? config;
};
