import { Alert, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthenticated } from '../../auth';
import { ConfirmDialog, Dialog, TextEditor } from '../../components';
import { useApiErrorHandler } from '../../hooks';
import { ThingWithContractData } from './contract-management.types';

interface TerminateContractDialogProps {
  open: boolean;
  onClose: () => void;
  item: ThingWithContractData;
  refreshList: VoidFunction;
}

export const ContractManagementTerminateContractDialog: FC<TerminateContractDialogProps> = ({
  open,
  onClose,
  item,
  refreshList,
}) => {
  const { t } = useTranslation(['common', 'data-subscription']);
  const { restClient } = useAuthenticated();
  const { handleUpdateError } = useApiErrorHandler();
  const { enqueueSnackbar } = useSnackbar();
  const [disabled, setDisabled] = useState(false);
  const [terminateNotes, setTerminateNotes] = useState('');

  const handleTerminateContract = async (): Promise<void> => {
    if (!item.contract.dataSubscription?.lastDataSubscription.dataSubscriptionId) return;
    setDisabled(true);
    try {
      await restClient.admin.dataSubscription.finish(item.contract.dataSubscription.lastDataSubscription.dataSubscriptionId, {
        finish: new Date(),
        reason: 'manual/subscription-expiration',
        notes: terminateNotes,
      });
      enqueueSnackbar(t('common:page.contract-management.contract-terminated-successfully.labels'), { variant: 'success' });
      refreshList();
    }
    catch (err) {
      handleUpdateError(err, 'CONTRACT', item._id);
    } finally {
      setDisabled(false);
      onClose();
    }
  };

  return (
    <Dialog
      actions={
        <ConfirmDialog
          buttonDisabled={disabled || terminateNotes.trim().length < 1}
          confirmPrompt={<Typography variant="body2">{t('common:page.contract-management.terminate-contract.labels')}</Typography>}
          initialPrompt={t('common:page.contract-management.terminate-contract.action.labels')}
          onConfirm={handleTerminateContract}
          buttonVariant="contained"
        />
      }
      data-testid="contract-management-manually-terminate-contract-dialog"
      open={open}
      onClose={onClose}
      hasCloseBtn={true}
      title={t('common:page.contract-management.terminate-contract.labels')}
    >
      <Stack spacing={2}>
        <Alert severity="warning" variant="filled" sx={{ alignItems: 'center' }}>
          <Typography variant="h5" sx={{ textTransform: 'uppercase' }}>{t('common:page.contract-management.warning.labels')}</Typography>
          {t('common:page.contract-management.terminate-contract-warning.labels')}
        </Alert>
        <TextEditor
          data-testid="terminate-contract-notes-input"
          label={t('common:page.contract-management.terminate-contract-warning-placeholder.labels')}
          onKeyUp={(value) => setTerminateNotes(value)}
          multiline
          rows={4}
          required
          size="small"
          value={terminateNotes}
        />
      </Stack>
    </Dialog>
  );
};
