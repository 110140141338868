import { LifecycleTemplate, Stage, Thing } from '@eagle/core-data-types';
import { createContext, Dispatch, useContext } from 'react';
import { Undefinable } from '../../../types';
import { StageConfirmDialogAction, StageConfirmDialogState } from './stage-confirm-dialog-reducer';

interface Context {
  dispatch: Dispatch<StageConfirmDialogAction>;
  lifecycleTemplate: LifecycleTemplate;
  sharedThingId: string;
  stage: Stage;
  state: StageConfirmDialogState;
  thing?: Thing;
  editableStakeholders: string[];
}

export const stageConfirmDialogContext = createContext<Undefinable<Context>>(undefined);

export const useStageConfirmDialogContext = (): Context => {
  const data = useContext(stageConfirmDialogContext);
  if (!data) throw new Error(`Missing Provider in tree above ${useStageConfirmDialogContext.name}`);
  return data;
};
