/* eslint-disable react-hooks/exhaustive-deps */
import { ThingEventSnapshot } from '@eagle/core-data-types';
import L from 'leaflet';
import { DateTime } from 'luxon';
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo } from 'react';
import { Pane, useMap } from 'react-leaflet';
import { CLUSTER_BOUNDS_PADDING, MAP_FLY_TO_DURATION } from '../../../constants';
import { MapPanes, MapPosition } from '../../../types';
import { Maybe, Nullable } from '../../../types/common';
import { useLocalStorage } from '../../../util/local-storage';
import { useHistorySearch } from '../../entity-journey/use-history-search';
import { useEventDialog } from '../../event-dialog';
import { ThingEventItem } from './thing-event-item';
import { EventLocationData, ThingEventItems } from './thing-event-pane.types';

interface Props {
  hoveredEventId: Maybe<string>;
  isAntPolylineEnabled: boolean;
  selectedEvent: Maybe<EventLocationData>;
  setHoveredEventId: Dispatch<SetStateAction<Maybe<string>>>;
  setSelectedEvent: Dispatch<SetStateAction<Maybe<EventLocationData>>>;
  storageKey: string;
}

const MAP_FLY_THRESHOLD_ZOOM = 0.5;
const MAP_FLY_THRESHOLD_CENTER = 100;

export const ThingEventPane: FC<Props> = ({
  storageKey,
  hoveredEventId,
  isAntPolylineEnabled,
  setHoveredEventId,
  selectedEvent,
  setSelectedEvent,
}): JSX.Element => {
  const { eventData, nextZoomTarget, setNextZoomTarget, entityVisibilities } = useHistorySearch();
  const { setEvents, setEventIndex, setOpen } = useEventDialog();
  const [savedPosition, setSavedPosition] = useLocalStorage<Nullable<MapPosition>>(storageKey, null);
  const findSelect = useMemo(() => eventData.find((event) => event.isSelected), [eventData]);
  const map = useMap();

  const handleBreadcrumbClick = useCallback((index: number, items: ThingEventSnapshot[]): void => {
    setEventIndex(index);
    setOpen(true);
    setEvents(items);
  }, [setEventIndex, setOpen, setEvents]);

  const panToBounds = useCallback((target?: ThingEventItems): void => {
    if (!target) return;

    const linePositions = target.data.items.filter((item) => !!item.data?.location).map((item) => {
      const locationData = item.data.location;
      return L.latLng([locationData.latitude, locationData.longitude]);
    });

    if (!linePositions.length) return;

    const journeyBounds = L.latLngBounds(linePositions).pad(CLUSTER_BOUNDS_PADDING);
    !savedPosition && setSavedPosition({
      alt: map.getZoom(),
      lat: map.getCenter().lat,
      lng: map.getCenter().lng,
      timeStamp: DateTime.now().toMillis(),
    });

    const currentZoom = map.getZoom();
    const currentCenter = map.getCenter();
    const journeyMaxZoom = map.getBoundsZoom(journeyBounds);

    if (
      currentZoom < journeyMaxZoom + MAP_FLY_THRESHOLD_ZOOM
      && currentZoom > journeyMaxZoom - MAP_FLY_THRESHOLD_ZOOM
      && journeyBounds.getCenter().toBounds(MAP_FLY_THRESHOLD_CENTER).contains(currentCenter)
    ) return;

    map.flyToBounds(journeyBounds, { duration: MAP_FLY_TO_DURATION });
  }, [map, savedPosition]);

  useEffect(() => {
    if (!findSelect) {
      if (!savedPosition) return;
      map.flyTo(L.latLng({ lat: savedPosition.lat, lng: savedPosition.lng }), savedPosition.alt, { duration: MAP_FLY_TO_DURATION });
      return setSavedPosition(null);
    }

    panToBounds(findSelect);
  }, [findSelect, map, savedPosition]);

  useEffect(() => {
    if (!nextZoomTarget) return;
    panToBounds(eventData.find((event) => event.journeyId === nextZoomTarget));
    setNextZoomTarget(null);
  }, [eventData, nextZoomTarget]);

  return (
    <>
      <Pane name={MapPanes.CLUSTER_LINE} style={{ zIndex: 597 }} />
      <Pane name={MapPanes.HOVER_LINE} style={{ zIndex: 598 }} />
      <Pane name={MapPanes.BREADCRUMBS} style={{ zIndex: 599 }}>
        {eventData.map((eventItem) => (
          <ThingEventItem
            key={`${eventItem.entityId}-${eventItem.journeyId}`}
            eventItem={eventItem}
            entityVisibilities={entityVisibilities}
            handleBreadcrumbClick={handleBreadcrumbClick}
            hoveredEventId={hoveredEventId}
            isAntPolylineEnabled={isAntPolylineEnabled}
            selectedEvent={selectedEvent}
            setHoveredEventId={setHoveredEventId}
            setSelectedEvent={setSelectedEvent}
          />
        ))}
      </Pane>
      <Pane name={MapPanes.START_END_MARKERS} style={{ zIndex: 600 }} />
    </>
  );
};
