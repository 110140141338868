import { BottomNavigationAction as MUIBottomNavigationAction, Theme, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { FC, MouseEventHandler, ReactNode } from 'react';

interface Props {
  'data-testid'?: string;
  disabled?: boolean;
  href?: string;
  icon?: ReactNode;
  key?: string | number;
  label?: ReactNode;
  onClick?: MouseEventHandler | undefined;
  sx?: SxProps<Theme>;
  value?: ReactNode;
}

export const BottomNavigationAction: FC<Props> = ({ ...props }) => {
  const theme = useTheme();
  return (
    <MUIBottomNavigationAction
      showLabel={true}
      sx={{
        '&': {
          lineHeight: 'normal',
          textAlign: 'center',
          color: props.disabled ? theme.palette.action.disabled : theme.palette.action.active,
        },
      }}
      {...props}
    />
  );
};
