import { Box } from '@mui/material';
import { SxProps } from '@mui/system';
import { FC, ReactNode } from 'react';
import { makeStyles } from '../../theme';

interface Props {
  'data-testid'?: string;
  template: string;
  columns?: number;
  sx?: SxProps;
}

export const LayoutGrid: FC<Props> = ({ template, columns, sx, ...props }) => {
  if (!template) {
    return <></>;
  }

  const elements: ReactNode[] = [];

  Object.keys(props).map((prop, i) => {
    const value = props[prop as keyof typeof props];
    if (value) {
      elements.push(
        <Box sx={{ gridArea: prop }} key={i}>{value}</Box>,
      );
    }
  });

  const { classes } = makeStyles()(() => ({
    container: {
      display: 'grid',
      gridTemplateColumns: !columns ? 'auto' : `repeat(${columns} , 1fr)`,
      gridTemplateAreas: template,
    },
  }))();

  return <Box data-testid={props['data-testid']} className={classes.container} sx={sx} >{elements}</Box>;
};
