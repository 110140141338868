import { Person, PersonType, Thing, ThingType } from '@eagle/core-data-types';
import { Add, ContentCopy, SyncAlt } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Maybe, Nullable } from '../../types';
import { ThingPersonDialogOperation } from '../assign-multiple-dialog/add-thing-person-dialog';
import { FetchOne, FetchOneOfAll } from '../fetch';
import { DeleteIcon } from '../icons';
import { EventLocationData } from '../map';
import { EntityCard } from './entity-card';
import { EntityCardActions } from './entity-card-actions';
import { CardAction, EntityItems, EntityTypeCacheType } from './entity-journey-types';
import { MinimizedJourney } from './minimized-journey';
import { useHistorySearch } from './use-history-search';

interface Props {
  'data-testid'?: string;
  entityItem: EntityItems;
  handleDrawerToggle?: (value: boolean) => void;
  hoveredEventId: Maybe<string>;
  isDrawerOpen?: boolean;
  openDialog: (operationType: ThingPersonDialogOperation, id: string, entityType: EntityTypeCacheType) => void;
  selectedEvent: Maybe<EventLocationData>;
  setHoveredEventId: Dispatch<SetStateAction<Maybe<string>>>;
  setSelectedEvent: Dispatch<SetStateAction<Maybe<EventLocationData>>>;
}

export const EntityJourneyItem: FC<Props> = ({
  entityItem,
  handleDrawerToggle,
  hoveredEventId,
  isDrawerOpen,
  openDialog,
  selectedEvent,
  setHoveredEventId,
  setSelectedEvent,
  ...props }) => {
  const {
    entityVisibilities,
    openAddDateTimePicker,
    removeEntity,
    toggleAllVisibility,
  } = useHistorySearch();
  const { t } = useTranslation(['common']);
  const [anchorEl, setAnchorEl] = useState<Nullable<HTMLElement>>(null);
  const open = Boolean(anchorEl);

  const getActions = (entityTypeDisplay: string): CardAction[] => [
    {
      name: ThingPersonDialogOperation.ADD,
      icon: <Add />,
      isConfirm: false,
      label: t('common:component.entity-journey.action.add-date-time'),
      onClick: () => {
        setAnchorEl(null);
        openAddDateTimePicker(entityItem.id);
      },
    },
    {
      name: ThingPersonDialogOperation.CHANGE,
      icon: <SyncAlt />,
      isConfirm: false,
      label: t('common:component.entity-journey.action.change-entity', { entity: entityTypeDisplay }),
      onClick: () => {
        setAnchorEl(null);
        openDialog(ThingPersonDialogOperation.CHANGE, entityItem.id, entityItem.entityType);
      },
    },
    {
      name: ThingPersonDialogOperation.DUPLICATE,
      icon: <ContentCopy />,
      isConfirm: false,
      label: t('common:component.entity-journey.action.duplicate-set'),
      onClick: () => {
        setAnchorEl(null);
        openDialog(ThingPersonDialogOperation.DUPLICATE, entityItem.id, entityItem.entityType);
      },
    },
    {
      name: 'delete',
      confirmPrompt: t('common:common.hint.confirm'),
      icon: <DeleteIcon />,
      isConfirm: true,
      label: t('common:common.action.remove'),
      onClick: () => {
        setAnchorEl(null);
        removeEntity(entityItem.id);
      },
    },
  ];

  const handleMoreButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleVisibilityButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    toggleAllVisibility(entityItem.id);
  };

  return (
    <Stack data-testid={props['data-testid']} sx={{ flexDirection: isDrawerOpen ? 'column-reverse' : 'column' }}>
      <Stack sx={{ display: isDrawerOpen ? 'none' : 'block' }}>
        {entityItem.journeyList.map((journey) => (
          <MinimizedJourney
            key={journey.id}
            dataType={entityItem.entity}
            handleDrawerToggle={handleDrawerToggle}
            journey={journey}
          />
        ))}
      </Stack>
      <FetchOne
        id={entityItem.id}
        dataType={entityItem.entity}
        renderFactory={(data: Thing | Person) => (
          <FetchOneOfAll
            id={data[entityItem.entityTypeKey as keyof (Thing | Person)]}
            dataType={entityItem.entityType}
            renderFactory={(dataType: ThingType | PersonType) =>
              <Box sx={{ display: isDrawerOpen ? 'block' : 'none' }}>
                <EntityCard
                  data-testid="entity-card"
                  entityDataType={dataType}
                  entityDisplay={data.display}
                  entityItem={entityItem}
                  handleMoreButtonClick={handleMoreButtonClick}
                  handleVisibilityButtonClick={handleVisibilityButtonClick}
                  hoveredEventId={hoveredEventId}
                  isEntityVisible={!!entityVisibilities.find((entity) => entity.entityId === entityItem.id)?.isVisible}
                  selectedEvent={selectedEvent}
                  setHoveredEventId={setHoveredEventId}
                  setSelectedEvent={setSelectedEvent}
                />
                <EntityCardActions data-testid="entity-actions" actions={getActions(dataType.display)} anchorEl={anchorEl} handleClose={handleClose} open={open} />
              </Box>
            }
          />
        )}
      />
    </Stack>
  );
};
