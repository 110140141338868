import { Checkbox, FormControlLabel, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { ChangeEvent, FC, ReactNode } from 'react';

interface SelectionProps {
  checked: boolean;
  'data-testid'?: string;
  disabled?: boolean;
  handleClick: (e: ChangeEvent<HTMLInputElement>) => void;
  indeterminate?: boolean;
  label: ReactNode;
  subItem?: boolean;
  sx?: SxProps;
  wrap?: boolean;
}

export const SelectionCheckBox: FC<SelectionProps> = ({ checked, handleClick, indeterminate, label, subItem, sx, disabled = false, wrap, ...rest }) => {
  const theme = useTheme();

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          disabled={disabled}
          indeterminate={indeterminate}
          onChange={handleClick}
          size="small"
          sx={{
            p: 0,
            pr: 1,
            color: theme.palette.grey[500],
          }}
        />
      }
      label={label}
      sx={{
        m: 0,
        width: '100%',
        px: 2,
        py: '6px',
        pl: subItem ? 3 : 2,
        ...(wrap
          ? {
            '&': {
              alignItems: 'flex-start',
            },
            '& .MuiFormControlLabel-label': {
              color: subItem ? theme.palette.text.secondary : theme.palette.text.primary,
              textWrap: 'wrap',
            },
          }
          : {
            '& .MuiFormControlLabel-label': {
              color: subItem ? theme.palette.text.secondary : theme.palette.text.primary,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
          }
        ),
        ...sx,
      }}
      {...rest}
    />
  );
};
