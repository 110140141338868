import { CheckoutStatus, SupportedCurrency } from '@eagle/core-data-types';
import { useSnackbar } from 'notistack';
import { createContext, FC, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useAuth, useAuthenticated } from '../../auth';
import { captureException, MiddleSpinner, useNumberFlag } from '../../components';
import { usePromise, useSwitchAwareConfig } from '../../hooks';
import { Undefinable } from '../../types';
import { ContractListReducer, initContractListReducer } from './contract-list-reducer';
import { CartItem, ContractListState, StripeCheckoutResponse, ThingTypeWithDataSubscriptionPlans } from './contract-management.types';
import { cartItemsToApiCallBody, cartItemsToStoredCartEntry, clearStoredActiveCheckout, CONTRACT_LIST_CART_STORAGE_KEY, CONTRACT_LIST_CURRENCY_STORAGE_KEY, CONTRACT_MANAGEMENT_CART_VALIDITY_FLAG, expireCheckoutSession, getCartItemsWithActiveCheckoutSession, getInitialCartContent, getStoredCurrency, getUnsupportedItemsInCart, hasActiveStoredCheckout, storeActiveCheckout, useSyncToLocalStorageByAccount } from './contract-management.utils';

interface ContractListContext {
  addCartItem: (item: CartItem) => void;
  removeCartItem: (thingId: string) => void;
  changeCurrency: (currency: SupportedCurrency) => void;
  acceptCheckoutWarning: VoidFunction;
  closeCheckoutDrawer: VoidFunction;
  dismissCheckoutWarning: VoidFunction;
  startCheckout: (items: CartItem[]) => Promise<void>;
  acceptCurrencyWarning: VoidFunction;
  dismissCurrencyWarning: VoidFunction;
  supportedCurrencies: SupportedCurrency[];
  stripePublishableKey: string;
  refresh: Date;
  refreshList: VoidFunction;
  state: ContractListState;
}

const contractListContext = createContext<Undefinable<ContractListContext>>(undefined);

interface ContractListProviderProps extends PropsWithChildren {
  thingTypes: ThingTypeWithDataSubscriptionPlans[];
}

export const ContractListProvider: FC<ContractListProviderProps> = ({ thingTypes, ...props }) => {
  const config = useSwitchAwareConfig();
  const { account, restClient } = useAuthenticated();
  const cartValidityInHours = useNumberFlag(CONTRACT_MANAGEMENT_CART_VALIDITY_FLAG) ?? 24;

  const [initialCart, error, status] = usePromise<{ content: CartItem[]; currency: SupportedCurrency }>(async () => {
    if (!config.paymentGateway?.supportedCurrencies[0]) {
      throw new Error('No supported currency.');
    }

    const currency = getStoredCurrency(account._id, config.paymentGateway?.supportedCurrencies) ?? config.paymentGateway.supportedCurrencies[0];
    const content = await getInitialCartContent(account._id, cartValidityInHours, thingTypes, currency, restClient);
    return {
      content,
      currency,
    };
  }, [account._id, restClient, thingTypes, config.paymentGateway?.supportedCurrencies, cartValidityInHours]);

  if (error) {
    throw error;
  }

  if (status === 'pending') {
    return <MiddleSpinner />;
  }

  return (
    <ContractListProviderInner initialCartItems={initialCart.content} initialCurrency={initialCart.currency} {...props} />
  );
};

interface ContractListProviderInnerProps extends PropsWithChildren {
  initialCartItems: CartItem[];
  initialCurrency: SupportedCurrency;
}

const ContractListProviderInner: FC<ContractListProviderInnerProps> = ({ children, initialCartItems, initialCurrency }) => {
  const { getAccessToken } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { i18n, t } = useTranslation(['common']);
  const { axios, restClient } = useAuthenticated();
  const config = useSwitchAwareConfig();
  if (!config.paymentGateway?.supportedCurrencies[0]) {
    throw new Error('No supported currency.');
  }
  if (!config.paymentGateway?.stripePublishableKey) {
    throw new Error('Missing stripePublishableKey.');
  }

  const [refresh, setRefresh] = useState(new Date());

  const [state, dispatch] = useReducer(ContractListReducer, { cartItems: initialCartItems, selectedCurrency: initialCurrency }, initContractListReducer);

  useSyncToLocalStorageByAccount(CONTRACT_LIST_CART_STORAGE_KEY, useMemo(() => cartItemsToStoredCartEntry(state.cartItems), [state.cartItems]));
  useSyncToLocalStorageByAccount(CONTRACT_LIST_CURRENCY_STORAGE_KEY, state.selectedCurrency);

  const subscribeToUnmount = useSubscribeToUnmount();

  const [searchParams, setSearchParams] = useSearchParams();
  const sessionId = searchParams.get('session_id');

  const addCartItem = useCallback((item: CartItem) => {
    dispatch({ type: 'ADD_TO_CART', item });
  }, []);

  const removeCartItem = useCallback((thingId: string) => {
    dispatch({ type: 'REMOVE_FROM_CART', thingId });
  }, []);

  const changeCurrency = useCallback((currency: SupportedCurrency) => {
    const unsupportedEntriesInCart = getUnsupportedItemsInCart(state.cartItems, currency);
    if (unsupportedEntriesInCart.length === 0) {
      return dispatch({ type: 'SET_CURRENCY', currency });
    }
    dispatch({ type: 'OPEN_CURRENCY_WARNING', targetCurrency: currency });
  }, [state.cartItems]);

  const dismissCurrencyWarning = useCallback(() => {
    dispatch({ type: 'DISMISS_CURRENCY_WARNING' });
  }, []);

  const acceptCurrencyWarning = useCallback(() => {
    dispatch({ type: 'ACCEPT_CURRENCY_WARNING' });
  }, []);

  const startCheckout = useCallback(async (items: CartItem[]) => {
    try {
      if (state.checkout.status !== 'idle' && state.checkout.status !== 'warning') {
        return;
      }

      if (hasActiveStoredCheckout()) {
        return dispatch({ type: 'SET_CHECKOUT', checkout: { status: 'has-other-session' } });
      }

      dispatch({ type: 'SET_CHECKOUT', checkout: { status: 'preparing' } });

      const cartItemsWithActiveCheckoutSession = await getCartItemsWithActiveCheckoutSession(restClient, items);

      if (cartItemsWithActiveCheckoutSession.length > 0) {
        return dispatch({ type: 'SET_CHECKOUT', checkout: { status: 'warning', items: cartItemsWithActiveCheckoutSession } });
      }

      const { data: checkoutData } = await axios.post<StripeCheckoutResponse>('/api/v1/thing-subscription', cartItemsToApiCallBody(items, state.selectedCurrency), { params: { locale: i18n.resolvedLanguage } });
      storeActiveCheckout(checkoutData);

      dispatch({ type: 'SET_CHECKOUT', checkout: { status: 'in-progress', data: checkoutData } });
    }
    catch (error) {
      console.error(error);
      dispatch({ type: 'SET_CHECKOUT', checkout: { status: 'idle' } });
      enqueueSnackbar(t('common:page.contract-management.error.unable-to-connect-to-payment-gateway.hint'), { variant: 'error' });
      captureException(error);
    }
  }, [state.checkout, enqueueSnackbar, t, axios, i18n.resolvedLanguage, state.selectedCurrency, restClient]);

  const acceptCheckoutWarning = useCallback(() => {
    const newItems = state.cartItems.filter((item) => {
      if (state.checkout.status !== 'warning') {
        return true;
      }
      return !state.checkout.items.includes(item);
    });

    dispatch({ type: 'SET_CART_CONTENT', items: newItems });

    if (newItems.length === 0) {
      return dispatch({ type: 'SET_CHECKOUT', checkout: { status: 'idle' } });
    }

    void startCheckout(newItems);
  }, [state.cartItems, state.checkout, startCheckout]);

  const dismissCheckoutWarning = useCallback(() => {
    dispatch({ type: 'SET_CHECKOUT', checkout: { status: 'idle' } });
  }, []);

  const expireActiveCheckoutSession = useCallback((keepAlive: boolean) => {
    if (state.checkout.status === 'in-progress') {
      clearStoredActiveCheckout();
      const accessToken = getAccessToken();
      if (accessToken) {
        void expireCheckoutSession(state.checkout.data.sessionId, accessToken.raw, axios, keepAlive);
      }
    }
  }, [axios, state.checkout, getAccessToken]);

  const closeCheckoutDrawer = useCallback(() => {
    if (state.checkout.status === 'session-status-check') {
      return;
    }
    if (state.checkout.status === 'in-progress') {
      expireActiveCheckoutSession(false);
    }
    dispatch({ type: 'SET_CHECKOUT', checkout: { status: 'idle' } });
  }, [state.checkout, expireActiveCheckoutSession]);

  const refreshList = useCallback(() => {
    setRefresh(new Date());
  }, []);

  useEffect(() => {
    const checkSessionStatus = async (sessionId: string): Promise<void> => {
      try {
        clearStoredActiveCheckout();
        dispatch({ type: 'SET_CHECKOUT', checkout: { status: 'session-status-check', sessionId } });
        const session = (await axios.get<{ status: CheckoutStatus }>(`/api/v1/checkout-session/${sessionId}/status`)).data;
        switch (session.status) {
          case CheckoutStatus.PAYMENT_COMPLETED:
          case CheckoutStatus.COMPLETED:
            dispatch({ type: 'SET_CHECKOUT', checkout: { status: 'success' } });
            dispatch({ type: 'SET_CART_CONTENT', items: [] });
            break;
          case CheckoutStatus.SESSION_CREATED:
            dispatch({ type: 'SET_CHECKOUT', checkout: { status: 'error', error: { type: 'pending' } } });
            break;
          case CheckoutStatus.SESSION_EXPIRED:
            dispatch({ type: 'SET_CHECKOUT', checkout: { status: 'error', error: { type: 'expired' } } });
            break;
          default:
            dispatch({ type: 'SET_CHECKOUT', checkout: { status: 'error', error: { type: 'general' } } });
        }
      }
      catch (error) {
        dispatch({ type: 'SET_CHECKOUT', checkout: { status: 'error', error: { type: 'general' } } });
        console.error(error);
      } finally {
        setRefresh(new Date());
        setSearchParams({}, { replace: true });
      }
    };

    if (sessionId) {
      void checkSessionStatus(sessionId);
    }
  }, [sessionId, axios, setSearchParams]);

  useEffect(() => {
    if (state.checkout.status === 'in-progress') {
      const onBeforeUnload = (): void => {
        expireActiveCheckoutSession(true);
      };

      const onUnmount = (): void => {
        expireActiveCheckoutSession(false);
      };

      window.addEventListener('beforeunload', onBeforeUnload);
      const unsubscribe = subscribeToUnmount(onUnmount);
      return () => {
        window.removeEventListener('beforeunload', onBeforeUnload);
        unsubscribe();
      };
    }
  }, [state.checkout, expireActiveCheckoutSession, subscribeToUnmount]);

  return (
    <contractListContext.Provider
      value={{
        acceptCheckoutWarning,
        acceptCurrencyWarning,
        addCartItem,
        closeCheckoutDrawer,
        changeCurrency,
        dismissCheckoutWarning,
        dismissCurrencyWarning,
        refreshList,
        removeCartItem,
        startCheckout,
        state,
        supportedCurrencies: config.paymentGateway.supportedCurrencies,
        stripePublishableKey: config.paymentGateway.stripePublishableKey,
        refresh,
      }}
    >
      {children}
    </contractListContext.Provider>
  );
};

export const useContractListContext = (): ContractListContext => {
  const value = useContext(contractListContext);

  if (value === undefined) {
    throw new Error('Missing ContractListProvider in tree above useContractListContext.');
  }

  return value;
};

const useSubscribeToUnmount = (): (fn: VoidFunction) => VoidFunction => {
  const listeners = useRef<Set<VoidFunction>>(new Set());

  useEffect(() => {
    const listenersValue = listeners.current;
    return () => {
      for (const listener of listenersValue) {
        listener();
      }
    };
  }, []);

  return useCallback((fn: VoidFunction) => {
    listeners.current.add(fn);
    return () => {
      listeners.current.delete(fn);
    };
  }, []);
};
