import { PaginatedResponse } from '@eagle/api-types';
import { RoleFunction } from '@eagle/common';
import { LifecycleTemplate, ServiceHistory, ServiceMetric, ServiceType, Thing, ThingLifeCycleStateResponse } from '@eagle/core-data-types';
import AddIcon from '@mui/icons-material/Add';
import { Alert, Button, Paper, Stack, Tooltip, Typography } from '@mui/material';
import Axios from 'axios';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthenticated } from '../../auth';
import { SERVICE_CENTER_ROLE } from '../../constants';
import { useFetchOneCache, usePromise, useSmallScreen } from '../../hooks';
import { FindItemsDeferredResult, Query } from '../../pages/list/types';
import { CacheDataTypes, Undefinable } from '../../types';
import { FILTER_OUT, useHasAuthorization } from '../../util';
import { BasicList } from '../basic-list';
import { ErrorBoundary } from '../error-boundary';
import { MiddleSpinner } from '../middle-spinner';
import { ServiceHistoryDetailsDialog } from './service-history-details-dialog';
import { ServiceHistoryListView } from './service-history-list-view';
import { ServiceHistoryServiceCenter } from './service-history-service-center';
import { ServiceHistoryExtended } from './service-history.types';
import { UpdateCreateServiceHistoryDialog } from './update-create-service-history-dialog';

interface Props {
  lifeCycleState: ThingLifeCycleStateResponse;
  thingId: string;
  thing: Thing;
  isEditable?: boolean;
  onAddService?: () => void;
  onServiceCenterChanged?: () => void;
}

const SERVICE_ADMINISTRATOR = [RoleFunction.SERVICE_ADMINISTRATOR] as const;

export const ServiceHistoryCard: FC<Props> = ({ onServiceCenterChanged, lifeCycleState, thingId, thing, isEditable = false }) => {
  const { axios } = useAuthenticated();
  const { t } = useTranslation(['common']);
  const { hasAuthorization } = useHasAuthorization();
  const smallScreen = useSmallScreen();
  const lifecycleTemplateCache = useFetchOneCache(CacheDataTypes.LIFECYCLE_TEMPLATE);
  const serviceEditPermissions = hasAuthorization(SERVICE_ADMINISTRATOR);
  const addAndEditPermission = isEditable && serviceEditPermissions;
  const [newServiceDialogOpen, setNewServiceDialogOpen] = useState(false);
  const [viewServiceDialogOpen, setViewServiceDialogOpen] = useState(false);
  const [editServiceDialogOpen, setEditServiceDialogOpen] = useState(false);
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [refreshList, setRefreshList] = useState(new Date());
  const [selectedService, setSelectedService] = useState<ServiceHistoryExtended>();

  const handleFindServices = useCallback(({ pagination }: Query): FindItemsDeferredResult<ServiceHistory> => {
    const cancelToken = Axios.CancelToken.source();
    return {
      cancel: () => cancelToken.cancel(),
      promise: axios.get<PaginatedResponse<ServiceHistory>>(`/api/v1/service-history/thing/${thingId}`, {
        cancelToken: cancelToken.token,
        params: {
          ...pagination,
          sort: '-occurred',
          filter: FILTER_OUT.deleted,
        },
      }).then((response) => {
        const headers = response.headers as Record<string, any>;
        const matchCount = response.data.count ?? Number.parseInt(headers['x-match-count'] as string, 10);
        return {
          result: {
            results: response.data.items ?? [],
            itemCount: matchCount,
          },
          resultDescription: '',
        };
      }),
    };
    // Using a refresh state for refetching is a hack, we should find an other solution in the future.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios, thingId, refreshList]);

  const [serviceTypes, serviceTypesError, serviceTypesState] = usePromise<ServiceType[]>(
    async () => {
      const { data } = await axios.get<PaginatedResponse<ServiceType>>(`/api/v1/service-type/thing/${thingId}`, {
        params: {
          filter: FILTER_OUT.deleted,
        },
      });
      return data.items ?? [];
    }, [axios, thingId]);

  const [serviceMetrics, serviceMetricsError, serviceMetricsState] = usePromise<ServiceMetric[]>(
    async () => {
      const { data } = await axios.get<PaginatedResponse<ServiceMetric>>(`/api/v1/service-metric/thing/${thingId}`, {
        params: {
          filter: FILTER_OUT.deleted,
        },
      });
      return data.items ?? [];
    }, [axios, thingId]);

  const [lifeCycleData, lifeCycleDataError, lifeCycleDataState] = usePromise(async () => {
    if (!thing.sharedThingId) return;

    const lifeCycleTemplate = await lifecycleTemplateCache.one<LifecycleTemplate>(lifeCycleState.lifecycleTemplateId);
    if (!lifeCycleTemplate) return;

    const stakeholderRoles = (await axios.get<string[]>(`/api/v1/my/life-cycle-template/${lifeCycleTemplate._id}/stakeholders`)).data;

    const serviceCenterAccount = lifeCycleState.stakeholders?.[SERVICE_CENTER_ROLE];

    return {
      lifeCycleTemplate,
      lifeCycleState,
      stageId: lifeCycleState.stageId,
      stakeholderRoles,
      sharedThingId: thing.sharedThingId,
      serviceCenterAccount,
    };
  }, [thing, axios, lifecycleTemplateCache, lifeCycleState]);

  const templateHasServiceCenter = Boolean(lifeCycleData?.lifeCycleTemplate.stakeholderAccounts?.[SERVICE_CENTER_ROLE]);

  const renderServiceHistoryList = (services: Undefinable<ServiceHistory[]>, isLoading: boolean): JSX.Element => {
    if (isLoading || serviceTypesState === 'pending' || serviceMetricsState === 'pending' || lifeCycleDataState === 'pending') return <MiddleSpinner />;

    const list = (() => {
      if (serviceTypesError || serviceMetricsError || lifeCycleDataError) {
        return <Alert data-testid="service-error" severity="error" sx={{ my: 0 }}>
          {t('common:component.search.hint.unexpected-error-message')}
        </Alert>;
      }

      if (!serviceMetrics || !serviceMetrics.length) {
        return <></>;
      }

      if (!services || !services.length) {
        return <Alert data-testid="no-search-results" severity="info" sx={{ my: 0 }}>
          {t('common:page.thing-detail.service-history.list.none.hint')}
        </Alert>;
      }
      return <ServiceHistoryListView services={services} serviceTypes={serviceTypes} serviceMetrics={serviceMetrics} onClick={handleViewEditService} />;
    })();

    return (
      <Stack spacing={2}>
        {isEditable && lifeCycleData && (
          <ErrorBoundary>
            <ServiceHistoryServiceCenter
              lifeCycleTemplate={lifeCycleData.lifeCycleTemplate}
              onChanged={onServiceCenterChanged}
              currentStageId={lifeCycleData.stageId}
              serviceCenterAccount={lifeCycleData.serviceCenterAccount}
              stakeholderRoles={lifeCycleData.stakeholderRoles}
              thing={thing}
              sharedThingId={lifeCycleData.sharedThingId}
              lifeCycleState={lifeCycleData.lifeCycleState}
            />
          </ErrorBoundary>
        )}
        {list}
      </Stack>
    );
  };

  const handleViewEditService = (service?: Undefinable<ServiceHistoryExtended>): void => {
    if (!service) return setNewServiceDialogOpen(true);
    setSelectedService(service);
    if (service && addAndEditPermission) {
      setEditServiceDialogOpen(true);
    } else {
      setViewServiceDialogOpen(true);
    }
  };

  const renderCreateAction = (): JSX.Element => {
    if (!addAndEditPermission) return <></>;
    const hasErrors = !!serviceTypesError || !!serviceMetricsError || !serviceTypes?.length || !serviceMetrics?.length;
    return (
      <Tooltip arrow title={!hasErrors ? null : t('common:page.thing-detail.service-history.add-new-service.no-service-type.hint')} placement="left">
        <span>
          <Button
            data-testid="add-new-service-button"
            disabled={saveInProgress || hasErrors}
            onClick={() => handleViewEditService()}
            startIcon={<AddIcon />}
            sx={{ alignSelf: 'flex-end' }}
          >
            {smallScreen ? t('common:page.thing-detail.service-history.add.service.action') : t('common:page.thing-detail.service-history.add-new.service.action')}
          </Button>
        </span>
      </Tooltip>
    );
  };

  const actions = [
    <Stack key="title-add" direction="row" sx={{ justifyContent: 'space-between', px: 3 }}>
      <Typography variant="h5" color="text.primary">{t('common:page.thing-detail.service-history.service-history-title.labels')}</Typography>
      {renderCreateAction()}
    </Stack>,
  ];

  return <>
    <Paper sx={{ py: 3 }}>
      <BasicList
        actions={actions}
        limit={5}
        hidePagination={!!serviceTypesError || !!serviceMetricsError}
        onQueryChanged={handleFindServices}
        renderContent={renderServiceHistoryList}
      />
    </Paper>
    {addAndEditPermission && lifeCycleData && <UpdateCreateServiceHistoryDialog
      data-testid="add-new-service-dialog"
      thing={thing}
      open={newServiceDialogOpen}
      handleClose={() => setNewServiceDialogOpen(false)}
      lifeCycleState={lifeCycleData.lifeCycleState}
      saveInProgress={saveInProgress}
      serviceMetrics={serviceMetrics || []}
      serviceTypes={serviceTypes || []}
      setRefreshList={setRefreshList}
      setSaveInProgress={setSaveInProgress}
      sharedThingId={lifeCycleData.sharedThingId}
      templateHasServiceCenter={templateHasServiceCenter}
    />}
    {selectedService && lifeCycleData && <ServiceHistoryDetailsDialog
      item={selectedService}
      open={viewServiceDialogOpen}
      handleClose={() => setViewServiceDialogOpen(false)}
      serviceMetrics={serviceMetrics || []}
      serviceTypes={serviceTypes || []}
      templateHasServiceCenter={templateHasServiceCenter}
    />}
    {addAndEditPermission && lifeCycleData && <UpdateCreateServiceHistoryDialog
      data-testid="update-service-dialog"
      thing={thing}
      item={selectedService}
      open={editServiceDialogOpen}
      handleClose={() => setEditServiceDialogOpen(false)}
      lifeCycleState={lifeCycleData.lifeCycleState}
      saveInProgress={saveInProgress}
      serviceMetrics={serviceMetrics || []}
      serviceTypes={serviceTypes || []}
      setRefreshList={setRefreshList}
      setSaveInProgress={setSaveInProgress}
      sharedThingId={lifeCycleData.sharedThingId}
      templateHasServiceCenter={templateHasServiceCenter}
    />}
  </>;
};
