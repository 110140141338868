import { LifecycleTemplate } from '@eagle/core-data-types';
import { IconButton, Theme, Tooltip } from '@mui/material';
import { SxProps } from '@mui/system';
import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge } from '../badge';
import { SelfClaimIcon } from '../icons';
import { SelfClaimDialog } from './self-claim-dialog';

interface Props {
  disabled?: boolean;
  onSelfClaimClicked: () => void;
  openSelfClaim?: boolean;
  size?: 'small' | 'medium' | 'large';
  sx?: SxProps<Theme>;
  lifecycleTemplate?: LifecycleTemplate;
  stakeholderRole?: string | null;
  showIcon?: boolean;
}

export const SelfClaimBar: FC<Props> = memo(({ disabled = false, onSelfClaimClicked: onClickHandler, openSelfClaim = false, size, sx, lifecycleTemplate, stakeholderRole, showIcon = true }) => {
  const { t } = useTranslation(['common']);

  const handleSelfClaimDialogNext = (): void => {
    // TODO: Implement self-claim dialog next action
  };

  const renderSelfClaimButton = (): JSX.Element => (
    <Badge data-testid="self-claim-badge">
      <Tooltip arrow disableHoverListener={disabled} title={t('admin:page.transfers-list.update-stage-dialog.self-claim.vehicle-search.title')} >
        <span>
          <IconButton
            color='primary'
            data-testid="self-claim-button"
            disabled={disabled}
            onClick={onClickHandler}
            size={size}
            sx={{ ...sx, lineHeight: 'inherit', p: 1 }}
          >
            <SelfClaimIcon />
          </IconButton>
        </span>
      </Tooltip>
    </Badge>
  );

  return (
    <>
      {showIcon && renderSelfClaimButton()}

      {openSelfClaim && lifecycleTemplate && stakeholderRole && (
        <SelfClaimDialog
          open={openSelfClaim}
          lifecycleTemplate={lifecycleTemplate}
          onSelfClaimDialogClose={onClickHandler}
          onSelfClaimDialogNext={handleSelfClaimDialogNext}
          stakeholderRole={stakeholderRole}
        />
      )}
    </>
  );
});

SelfClaimBar.displayName = 'SelfClaimBar';
