import ga from 'react-ga4';
import { matchPath } from 'react-router-dom';
import { RouteInfo } from '../components/page-content';

export const ContentCategoryAdmin = {
  Accounts: 'admin_accounts',
  AlertConditions: 'admin_alert_conditions',
  AuthorizedApplications: 'admin_authorized_apps',
  Contracts: 'admin_contracts',
  Geofence: 'admin_geofence',
  GeofenceGroups: 'admin_geofence_groups',
  Groups: 'admin_groups',
  OIDCMapping: 'admin_oidc_mapping',
  People: 'admin_people',
  PointOfInterest: 'admin_poi',
  PointOfInterestSets: 'admin_poi_sets',
  Roles: 'admin_roles',
  Subscriptions: 'admin_subscriptions',
  Things: 'admin_things',
  Transfers: 'admin_transfers',
  UserGroups: 'admin_user_groups',
  UserProfile: 'admin_user_profile',
  Users: 'admin_users',
} as const;

export const ContentCategoryTracking = {
  Alerts: 'track_alerts',
  Analytics: 'track_analytics',
  Dashboards: 'track_dashboards',
  Map: 'track_map',
  People: 'track_people',
  Reports: 'track_reports',
  Things: 'track_things',
  UserProfile: 'track_user_profile',
} as const;

type TrackingEventCategory = 'keyword_search' | 'list_pagination' | 'list';

export type ContentCategoryAdmin = typeof ContentCategoryAdmin[keyof typeof ContentCategoryAdmin];
export type ContentCategoryTracking = typeof ContentCategoryTracking[keyof typeof ContentCategoryTracking];

const findMatchedRoute = (routes: RouteInfo[], pathname: string): RouteInfo | undefined => {
  // Special case for /user/groups/ as the path was matching /user/:id
  if (pathname === '/user/groups') {
    return routes.find((route) => route.path === '/user/groups');
  }
  return routes.find((route) => matchPath(route.path, pathname));
};

export const trackEvent = (category: TrackingEventCategory, action: string, feature: string, params: Record<string, string | number> = {}): void => {
  ga.gtag('event', category, {
    action,
    feature,
    ...params,
  });
};

export const trackPage = (page: string, routes: RouteInfo[]): void => {
  const matchedRoute = findMatchedRoute(routes, page);

  const data: { hitType: string; page: string; content_group?: string } = {
    hitType: 'pageview',
    page,
  };

  if (matchedRoute && matchedRoute.googleAnalyticsCategory) {
    // eslint-disable-next-line dot-notation
    data['content_group'] = matchedRoute.googleAnalyticsCategory;
  }

  ga.send(data);
};
