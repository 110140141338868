import { Operator } from '@jexop/core';
import { isNumber, isObject } from 'lodash';

export const objectValueByIndex: Operator<unknown> = (props) => {
  if (!isNumber(props.index) || !isObject(props.object)) {
    return null;
  }

  const keys = Object.keys(props.object);

  if (!(keys.length > 0) || !props.object[keys[props.index] as keyof typeof props.object]) {
    return null;
  }

  return props.object[keys[props.index] as keyof typeof props.object];
};

export const objectKeyByIndex: Operator<string> = (props) => {
  if (!isNumber(props.index) || !isObject(props.object)) {
    return null;
  }

  const keys = Object.keys(props.object);

  if (keys.length < 1 || !keys[props.index]) {
    return null;
  }

  return keys[props.index];
};

export const objectKeyCount: Operator<number> = (props) => {
  if (!isNumber(props.index) || !isObject(props.object)) {
    return null;
  }

  const keys = Object.keys(props.object);

  if (keys.length < 1 || !keys[props.index]) {
    return null;
  }

  return keys[props.index].length;
};

