import { CopyAll } from '@mui/icons-material';
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { LatLng } from 'leaflet';
import { enqueueSnackbar } from 'notistack';
import { cloneElement, FC, PropsWithChildren, ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '../error-message';

export interface MapLinkMenuService {
  label: string;
  link: string;
  icon?: ReactNode;
}

interface Props extends PropsWithChildren {
  latLng: LatLng;
  services: MapLinkMenuService[];
  hasCopy?: boolean;
  'aria-label'?: string;
  'data-testid'?: string;
}

export const MapLinksMenu: FC<Props> = ({ children, latLng, services, hasCopy, ...props }) => {
  const { t } = useTranslation(['common']);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleMenuClick = (formattedLink: string): void => {
    window.open(formattedLink, '_blank');
    handleClose();
  };

  const handleCopyClick = async (): Promise<void> => {
    if (!latLng) return;

    try {
      await navigator.clipboard.writeText(`${latLng.lat},${latLng.lng}`);
      enqueueSnackbar(t('common:component.map-links-menu.labels.copied-success'), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(<ErrorMessage error={error as Error} />, { variant: 'error' });
    } finally {
      handleClose();
    }
  };

  const formattedServices = useMemo(() =>
    services.map((service) => ({
      ...service,
      formattedLink: service.link.replace('{latLng}', `${latLng.lat},${latLng.lng}`),
    })), [services, latLng],
  );

  return (
    <>
      {cloneElement(children as React.ReactElement, { onClick: handleOpen })}
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} aria-label={props['aria-label']} data-testid={props['data-testid']}>
        {formattedServices.map((service) => (
          <MenuItem key={service.label} onClick={() => handleMenuClick(service.formattedLink)}>
            {service.icon && <ListItemIcon>{service.icon}</ListItemIcon>}
            <ListItemText primary={service.label} />
          </MenuItem>
        ))}
        {hasCopy && (
          <MenuItem onClick={handleCopyClick}>
            <ListItemIcon><CopyAll /></ListItemIcon>
            <ListItemText primary={t('common:component.map-links-menu.labels.copy')} />
          </MenuItem>
        )}
      </Menu>
    </>
  );
};
