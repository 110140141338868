export enum CacheDataTypes {
  ACCOUNT = 'account',
  ACCOUNT_REQUEST = 'account-request',
  ACCOUNT_TEMPLATE = 'account-templates',
  ACCOUNT_TYPE = 'account-type',
  ADMIN_USER = 'admin-user',
  ALERT = 'ALERT',
  ALERT_CONDITION = 'alert-condition',
  APPLICATION = 'application',
  BRAND = 'brand',
  COUNTRY = 'country',
  DEVICE = 'device',
  DEVICE_TYPE = 'device-type',
  FEATURE = 'feature',
  FEATURE_TYPE = 'feature-type',
  FEATURE_TYPE_ID_PREFIX = 'feature-type-id-prefix',
  GATEWAY = 'gateway',
  GEOFENCE = 'geofence',
  GEOFENCE_GROUP = 'geofence-group',
  GEOFENCE_TYPE = 'geofence-type',
  GROUP = 'group',
  INSTALLATION = 'installation',
  INSTALLATION_TEMPLATE = 'installation-template',
  LIFECYCLE_TEMPLATE = 'lifecycle-template',
  MODEL = 'model',
  MY_ACCESSIBLE_ACCOUNT_TYPE = 'my-accessible-account-type',
  MY_ACCOUNT = 'my-account',
  MY_FEATURE = 'my-feature',
  MY_SUBSCRIPTION = 'my-subscription',
  MY_USER = 'my-user',
  OIDC_GROUP_MAPPING = 'oidc-group-mapping',
  PERSON = 'person',
  PERSON_TYPE = 'person-type',
  POINT_OF_INTEREST = 'point-of-interest',
  POINT_OF_INTEREST_SET = 'point-of-interest-set',
  POINT_OF_INTEREST_TYPE = 'point-of-interest-type',
  ROLE = 'role',
  SELF_CLAIM = 'self-claim',
  SHARED_THING = 'shared-thing',
  SHARED_THING_TYPE = 'shared-thing-type',
  SUBSCRIPTION = 'subscription',
  THING = 'thing',
  THING_TYPE = 'thing-type',
  USER = 'user',
  USER_GROUP = 'user-group',
  VARIANT = 'variant'
}
