import { AxiosInstance } from 'axios';

interface TokenResponse {
  sessionTransferToken: string;
}

export const createMicroPortalRoute = (app: string, params: string): string => {
  return `/micro-portal/authenticate/${app}?${params}`;
};

export const createMicroPortalUrl = (app: string, params: string, customHost?: string): string => {
  return `${customHost || ''}/extension/${app}?${params}`;
};

export const getMicroPortalToken = async (axios: AxiosInstance, app: string): Promise<string | undefined> => {
  try {
    const res = await axios.post<TokenResponse>(`/api/v1/auth/micro-portal/${app}/init`);
    if (res.data.sessionTransferToken) {
      return res.data.sessionTransferToken;
    }
    throw new Error('No session transfer token received');
  } catch (error) {
    console.error('Error fetching micro portal token:', error);
    throw error;
  }
};
