/* eslint-disable react-hooks/exhaustive-deps */
import { ThingEventSnapshot } from '@eagle/core-data-types';
import { captureException } from '@sentry/react';
import * as turf from '@turf/turf';
import { default as axios, default as axiosStatic } from 'axios';
import L from 'leaflet';
import { useSnackbar } from 'notistack';
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BehaviorSubject } from 'rxjs';
import { HERE_MAP_API_KEY } from '../../../constants';
import { useConfig, useObservable } from '../../../hooks';
import { Maybe } from '../../../types';
import { EntityVisibility, useHistorySearch } from '../../entity-journey';
import { RouteResponse } from '../layers/route-matching/route-matching.types';
import { RoutingItemNoSnap } from '../layers/route-matching/routing-item-no-snap';
import { RoutingItemSnap } from '../layers/route-matching/routing-item-snap';
import { routingObservable } from './routing-observable';
import { EventLocationData, ThingEventItems } from './thing-event-pane.types';
import { getJourneyVisibility } from './thing-event-pane.util';

interface Props {
  eventItem: ThingEventItems;
  entityVisibilities: EntityVisibility[];
  handleBreadcrumbClick: (index: number, items: ThingEventSnapshot[]) => void;
  hoveredEventId: Maybe<string>;
  isAntPolylineEnabled: boolean;
  selectedEvent: Maybe<EventLocationData>;
  setHoveredEventId: Dispatch<SetStateAction<Maybe<string>>>;
  setSelectedEvent: Dispatch<SetStateAction<Maybe<EventLocationData>>>;
}

export interface FindRouteResult {
  cancel: () => unknown;
  promise: Promise<RouteResponse>;
}

export type LocationPoints = turf.Feature<turf.Point, {
  cluster: boolean;
  eventData: EventLocationData;
  iconColor: string;
  index: number;
}>[]

export const ROUTING_URL = 'https://routematching.hereapi.com/v8/calculateroute.json';

export const ThingEventItem: FC<Props> = ({
  eventItem,
  entityVisibilities,
  handleBreadcrumbClick,
  hoveredEventId,
  isAntPolylineEnabled,
  selectedEvent,
  setHoveredEventId,
  setSelectedEvent,
}): JSX.Element => {
  const {
    color,
    data: { items },
    entityDisplay,
    entityId,
    focused,
    isSelected,
    journeyId,
    mapMatchTolerance,
  } = eventItem;

  const {
    enableRoutingPostRequest,
    eventLimit,
    progressivelyLoadRoute,
    setMapLoading,
    setSnapToRoad,
    snapToRoad,
  } = useHistorySearch();
  const config = useConfig();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['common']);
  const eventRouteRenderer: L.Canvas = new L.Canvas();

  const [journeyError, setJourneyError] = useState(false);
  const [hasRoute, setHasRoute] = useState(false);
  const snapToRoadSubject = useMemo(() => new BehaviorSubject(snapToRoad), []);
  const journeyCalledSubject = useMemo(() => new BehaviorSubject(false), []);
  const locationPointsSubject = useMemo(() => new BehaviorSubject<LocationPoints>([]), []);
  const params = {
    apikey: config.hereMaps?.apiKey ?? HERE_MAP_API_KEY,
    mode: 'fastest;car;traffic:disabled',
    routeMatch: 1,
  };

  const resetMapLoad = useCallback(() => {
    setMapLoading((previousLoading) => ({ ...previousLoading, [journeyId]: false }));
  }, [journeyId]);

  const locationPoints = useMemo(() => {
    return items
      .filter(({ data }) => data.location && typeof data.location.latitude === 'number' && typeof data.location.longitude === 'number')
      .map((eventData, index) => {
        const { latitude, longitude } = eventData.data.location;
        return turf.point([longitude, latitude], {
          cluster: false,
          eventData,
          iconColor: color,
          index,
        });
      });
  }, [items, color]);

  const routingRequest = useCallback((locations: LocationPoints): FindRouteResult => {
    const cancelToken = axiosStatic.CancelToken.source();
    const collection = turf.featureCollection(locations);
    setMapLoading((previousLoading) => ({ ...previousLoading, [journeyId]: true }));

    if (enableRoutingPostRequest) {
      return {
        cancel: () => cancelToken.cancel(),
        promise: axios.post<RouteResponse>(
          ROUTING_URL,
          collection,
          { params },
        ).then(({ data }) => {
          resetMapLoad();
          return data;
        }),
      };
    }

    const waypoints: Record<string, string> = {};
    locations.forEach(({ geometry: { coordinates } }, i) => {
      const { lat, lng } = L.GeoJSON.coordsToLatLng(coordinates as [number, number]);
      waypoints[`waypoint${i}`] = `${lat},${lng}`;
    });

    return {
      cancel: () => cancelToken.cancel(),
      promise: axios.get<RouteResponse>(ROUTING_URL, {
        params: {
          mapMatchTolerance,
          ...params,
          ...waypoints,
        },
      }).then(({ data }) => {
        resetMapLoad();
        return data;
      }),
    };
  }, [enableRoutingPostRequest, resetMapLoad]);

  const onError = (err: Error): void => {
    setJourneyError(true);
    enqueueSnackbar(t('common:page.history.external-service-issue.hint.failure', { display: entityDisplay }), { variant: 'error' });
    captureException(err);
    resetMapLoad();
  };

  useEffect(() => {
    journeyCalledSubject.next(false);
    locationPointsSubject.next(locationPoints);
  }, [locationPoints]);

  useEffect(() => {
    snapToRoadSubject.next(snapToRoad);
  }, [snapToRoad]);

  const routeObservable = useMemo(() => {
    return routingObservable(routingRequest, onError, snapToRoadSubject, journeyCalledSubject, locationPointsSubject);
  }, []);

  const routeData = useObservable(routeObservable, onError);
  const showRoutingSnap = snapToRoad && !journeyError && locationPoints.length <= eventLimit;

  useEffect(() => {
    if (!routeData || !showRoutingSnap) return;

    const { summary } = routeData.response.route[0];

    if (summary.distance === 0 && summary.travelTime === 0) {
      enqueueSnackbar(t('track:page.history.snap-to-road.hint.failure'), { variant: 'info' });
      setSnapToRoad(false);
      return;
    }

    setHasRoute(true);
  }, [routeData, showRoutingSnap]);

  const isVisible = getJourneyVisibility(entityId, entityVisibilities, journeyId);

  if (!progressivelyLoadRoute) return <></>;

  if (showRoutingSnap && hasRoute) {
    return (
      <RoutingItemSnap
        color={color}
        focused={focused}
        handleBreadcrumbClick={handleBreadcrumbClick}
        hoveredEventId={hoveredEventId}
        isAntPolylineEnabled={isAntPolylineEnabled}
        isSelected={isSelected}
        isVisible={isVisible}
        locations={locationPoints}
        renderer={eventRouteRenderer}
        routeData={routeData}
        selectedEvent={selectedEvent}
        setHoveredEventId={setHoveredEventId}
        setSelectedEvent={setSelectedEvent}
      />
    );
  }

  return (
    <RoutingItemNoSnap
      color={color}
      handleBreadcrumbClick={handleBreadcrumbClick}
      handleNoSnapLoad={resetMapLoad}
      hoveredEventId={hoveredEventId}
      isAntPolylineEnabled={isAntPolylineEnabled}
      isSelected={isSelected}
      isVisible={isVisible}
      locations={locationPoints}
      renderer={eventRouteRenderer}
      selectedEvent={selectedEvent}
      setHoveredEventId={setHoveredEventId}
      setSelectedEvent={setSelectedEvent}
    />
  );
};
