import { MenuItem } from '@mui/material';
import { FC } from 'react';
import { testid } from '../../util';
import { SelectionCheckBox } from './selection-check-box';

interface Props {
  groupDisplay: string;
  itemsChecked: string[];
  subItemDisplay: (subItem: string) => string;
  subItems: string[];
  updateSelectedValue: (value: string[]) => void;
  wrap?: boolean;
}

export const SelectionCheckBoxGroup: FC<Props> = ({
  groupDisplay,
  itemsChecked,
  subItemDisplay,
  subItems,
  updateSelectedValue,
  wrap,
}) => {
  const isAllChecked = itemsChecked.length === subItems.length;

  const handleAllChange = (): void => {
    if (isAllChecked) return updateSelectedValue([]);
    updateSelectedValue(subItems);
  };

  const handleIndividualChange = (itemName: string) => (): void => {
    if (itemsChecked.includes(itemName)) {
      updateSelectedValue(itemsChecked.filter((itemChecked) => itemChecked !== itemName));
      return;
    }
    updateSelectedValue([...itemsChecked, itemName]);
  };

  return <>
    <MenuItem sx={{ p: 0 }}>
      <SelectionCheckBox
        checked={isAllChecked}
        data-testid={testid`selection-checkbox-${groupDisplay}`}
        handleClick={handleAllChange}
        indeterminate={itemsChecked.length > 0 && itemsChecked.length < subItems.length}
        label={groupDisplay}
        wrap={wrap}
      />
    </MenuItem>
    {subItems.map((subItem, j) =>
      <MenuItem key={j} sx={{ p: 0 }}>
        <SelectionCheckBox
          key={j}
          checked={itemsChecked.includes(subItem)}
          data-testid={testid`selection-checkbox-subitem-${subItem}`}
          handleClick={handleIndividualChange(subItem)}
          label={subItemDisplay(subItem)}
          subItem
          wrap={wrap}
        />
      </MenuItem>,
    )}
  </>;
};
