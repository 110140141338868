import { InputTypes } from '@eagle/common';
import { CacheDataTypes, CommonEntity, MapStorageKeys, SubMenuSectionData, SubSectionData, ToggleLayers } from '../../../../types';
import { filterDeleted } from '../../../../util';
import { AppliedFilter } from '../../../entity-search';
import { FilterTypes } from '../../../filter';

export const getSubSectionData = (label: string): SubSectionData[] => {
  switch (label) {
    case ToggleLayers.geofences:
      return [
        {
          cacheDataTypes: CacheDataTypes.GEOFENCE_TYPE,
          cacheFilter: filterDeleted,
          itemType: SubMenuSectionData.GEOFENCE_TYPE,
          storageKey: MapStorageKeys.GEOFENCE,
          typePropertyName: FilterTypes.GEOFENCE_TYPE,
        },
      ];
    case ToggleLayers.pointOfInterest:
      return [
        {
          cacheDataTypes: CacheDataTypes.POINT_OF_INTEREST_TYPE,
          cacheFilter: filterDeleted,
          itemType: SubMenuSectionData.POINT_OF_INTEREST_TYPE,
          storageKey: MapStorageKeys.POINT_OF_INTEREST,
          typePropertyName: FilterTypes.POINT_OF_INTEREST_TYPE,
        },
      ];
    default: return [];
  }
};

export const getFilterProps = (item: CommonEntity, label: string, propertyPath: string): AppliedFilter => {
  return {
    definition: {
      description: null,
      format: 'raw',
      input: InputTypes.CHECK,
      label,
      multiple: null,
      type: 'entity',
    },
    id: item._id,
    propertyPath,
    value: {
      display: item.display,
      id: item._id,
    },
  };
};
