import { Cancel, CheckCircle, Edit, Schedule } from '@mui/icons-material';
import { Button, IconButton, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ChangeEvent, FC, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { testid } from '../../util/test-id';
import { useSavedFilterStyles } from './styles';

interface Props {
  id: string;
  name: string;
  onLoadFilter: () => void;
  onRemoveFilter: () => void;
  onRenameFilter: (newName: string) => boolean;
}

export const SavedFilterElement: FC<Props> = ({
  id,
  name,
  onLoadFilter,
  onRemoveFilter,
  onRenameFilter,
}) => {
  const classes = useSavedFilterStyles();
  const { t } = useTranslation(['common']);
  const [renameActive, setRenameActive] = useState(false);
  const [filterNameInput, setFilterNameInput] = useState<string>(name);
  const [filterNameLabel, setFilterNameLabel] = useState<string>(name);
  const [isTouched, setIsTouched] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const inputFieldRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setIsInvalid(!filterNameInput);
  }, [filterNameInput]);

  const handleRename = (): void => {
    if (!filterNameInput) {
      setIsTouched(true);
      return;
    }

    if (onRenameFilter(filterNameInput)) {
      setFilterNameLabel(filterNameInput);
    }
    setRenameActive(false);
    setIsTouched(false);
  };

  const onInputKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      inputFieldRef.current?.blur();
    }
  };

  useEffect(() => {
    if (!renameActive) return;
    inputFieldRef.current?.focus();
  }, [renameActive]);

  return (
    <Button
      data-testid={testid`saved-filter-${id}`}
      component={Box}
      disableRipple
      fullWidth
      onClick={onLoadFilter}
      sx={{
        display: 'block',
      }}
      variant="outlined"
    >
      <Box
        className={classes.saveFilterElementWrapper}
        sx={{
          display: 'flex',
          flexGrow: 1,
          justifyContent: 'space-between',
          alignItems: 'centre',
        }}
      >
        <Box className={classes.saveFilterElementFlexCol}>
          <Schedule color="primary" />
        </Box>
        <Box className={classes.saveFilterLabel} flex={1}>
          {renameActive
            ? (
              <TextField
                data-testid={testid`saved-filter-${id}-name`}
                fullWidth
                inputRef={inputFieldRef}
                onBlur={handleRename}
                onChange={(event: ChangeEvent<HTMLInputElement>) => setFilterNameInput(event.target.value)}
                onClick={(e) => e.stopPropagation()}
                onKeyDown={onInputKeyDown}
                value={filterNameInput}
                variant="filled"
              />
            )
            : filterNameLabel
          }
        </Box>
        <Box className={classes.saveFilterElementFlexColRight}>
          <IconButton
            aria-label={t('common:component.filter.hint.remove-filter')}
            className={classes.editSaveFilterButton}
            data-testid={testid`remove-saved-filter-${id}-button`}
            onClick={(e) => {
              e.stopPropagation();
              onRemoveFilter();
            }}
            size="small"
          >
            <Cancel color="primary" />
          </IconButton>
          <IconButton
            aria-label={t('common:component.filter.hint.edit-filter')}
            className={classes.editSaveFilterButton}
            data-testid={testid`edit-saved-filter-${id}-button`}
            disabled={isInvalid}
            onClick={(e) => {
              e.stopPropagation();
              setRenameActive(true);
            }}
            size="small"
          >
            {renameActive ? <CheckCircle color={isInvalid ? 'disabled' : 'primary'} /> : <Edit color="primary" />}
          </IconButton>
        </Box>
      </Box>
      {isTouched && isInvalid
        && <Typography
          variant="caption"
          data-testid='mandatory-field-error-text'
          sx={{ color: 'red', textTransform: 'none', marginLeft: '2.5rem' }}
        >
          {t('common:common.hint.mandatory-field')}
        </Typography>
      }
    </Button>
  );
};
