import { DateTime } from 'luxon';
import { useCallback, useMemo } from 'react';
import { useAuthenticated } from '../auth';
import { MAP_RELOAD_TIME_HOURS } from '../constants';
import { MapPosition, MapStorageKeys, Nullable } from '../types';
import { isMapPosition, SavedMapPosition, useLocalStorage } from '../util';

interface UseSavedPositionReturnValue {
  savedPosition: Nullable<MapPosition>;
  setSavedPosition: (position: Nullable<MapPosition>) => void;
}

export const useSavedPosition = (mapStorageKeys: MapStorageKeys): UseSavedPositionReturnValue => {
  const { account } = useAuthenticated();
  const [savedPosition, setSavedPosition] = useLocalStorage<Nullable<SavedMapPosition>>(mapStorageKeys, null);

  const setAccountAwareSavedPosition = useCallback((position: Nullable<MapPosition>) => {
    setSavedPosition((savedPosition) => {
      if (savedPosition && isMapPosition(savedPosition)) {
        return { [account._id]: position };
      }
      return { ...savedPosition, [account._id]: position };
    });
  }, [account._id, setSavedPosition]);

  const isExpired = useMemo(() => {
    if (!savedPosition) {
      return true;
    }
    const position = parseSavedPosition(savedPosition, account._id);
    if (!position) {
      return true;
    }

    const timeDiff = DateTime.now().diff(DateTime.fromMillis(position.timeStamp), 'hours');
    return timeDiff.hours > MAP_RELOAD_TIME_HOURS;
  }, [savedPosition, account._id]);

  return {
    savedPosition: (isExpired || !savedPosition) ? null : parseSavedPosition(savedPosition, account._id),
    setSavedPosition: setAccountAwareSavedPosition,
  };
};

const parseSavedPosition = (position: SavedMapPosition, accountId: string): Nullable<MapPosition> => {
  return isMapPosition(position) ? position : (position[accountId] ?? null);
};
