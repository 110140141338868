import { FeatureInstancesWithSupportedEvents } from '@eagle/core-data-types';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useAuthenticated } from '../auth';
import { I18n } from '../providers';
import { Nullable } from '../types';

interface TranslationResult {
  label?: string;
  hint?: string;
  featureInstance?: string;
  featureLabel?: string;
}

export const ifTranslationExists = (path: string, fallback: string): string => I18n.getInstance().exists(path)
  ? I18n.getInstance().t(path)
  : fallback;

/**
 * Cleans the feature ID by removing any instance information.
 *
 * @param {string} featureId - The original feature ID.
 * @return {string} The cleaned feature ID.
 */
const cleanFeatureId = (featureId: string): string =>
  featureId.includes('/') ? featureId.split('/')[0] : featureId;

/**
 * Retrieves the translated label for a given feature and event type based on the provided flag.
 *
 * @param {string} featureTypeId - The ID of the feature type.
 * @param {string} eventTypeId - The ID of the event type.
 * @param {string} featureId - The ID of the feature.
 * @param {boolean} [flag] - An optional flag to determine which label to retrieve.
 * @return {string} The translated label.
 */
export const getEventLabelWithFlag = (featureTypeId: string, eventTypeId: string, featureId: string, flag?: boolean): string => {
  if (flag) {
    return getEventLabel(featureId, eventTypeId);
  }
  return getEventLabelV2(featureTypeId, eventTypeId, featureId);
};

/**
 * Retrieves the label for a given feature based on the provided flag.
 *
 * @param {string} feature - The ID of the feature.
 * @param {boolean} [flag] - An optional flag to determine which label to retrieve.
 * @return {string} The label for the feature.
 */
export const getFeatureLabelWithFlag = (feature: string, flag?: boolean): string => {
  if (flag) {
    return getEventFeature(feature);
  }
  return getEventFeatureV2(feature);
};

/**
 * Gets the translated label for a given feature and event type.
 *
 * @param {string} featureId - The ID of the feature.
 * @param {string} eventTypeId - The ID of the event type.
 * @return {string | undefined} The translated label or undefined if not found.
 */
export const getEventLabel = (featureId: string, eventTypeId: string): string => {
  const cleanedFeatureId = cleanFeatureId(featureId);
  const primaryKey = `common:event-descriptions-v3.${cleanedFeatureId}.${eventTypeId}.labels`;
  return ifTranslationExists(primaryKey, eventTypeId);
};

/**
 * Retrieves the translated label for a given feature type and event type using event descriptions V2.
 *
 * @deprecated Use `getEventLabel` instead.
 * @param {string} featureTypeId - The ID of the feature type.
 * @param {string} eventTypeId - The ID of the event type.
 * @return {string} The translated label.
 */
export const getEventLabelV2 = (featureTypeId: string, eventTypeId: string, feature?: string): string => {
  // Type safety checks
  if (typeof featureTypeId !== 'string' || typeof eventTypeId !== 'string') {
    return '';
  }

  // Null/empty checks
  if (!featureTypeId || !eventTypeId) {
    return eventTypeId || '';
  }

  const translationKey = `common:event-descriptions-v2.${featureTypeId}.${eventTypeId}.label`;

  if (feature) {
    if (typeof feature !== 'string') {
      return eventTypeId;
    }
    const featureLabel = getEventFeatureV2(feature);
    return I18n.getInstance().exists(translationKey, { feature: featureLabel })
      ? I18n.getInstance().t(translationKey, { feature: featureLabel })
      : eventTypeId;
  }

  return ifTranslationExists(translationKey, eventTypeId);
};

/**
 * Gets the translated hint for a given feature and event type.
 *
 * @param {string} featureId - The ID of the feature.
 * @param {string} eventTypeId - The ID of the event type.
 * @return {string | undefined} The translated hint or undefined if not found.
 */
export const getEventHint = (featureId: string, eventTypeId: string): string | undefined => {
  const cleanedFeatureId = cleanFeatureId(featureId);
  const hintKey = `common:event-descriptions-v3.${cleanedFeatureId}.${eventTypeId}.hint`;
  return I18n.getInstance().t(hintKey);
};

/**
 * Gets the translated feature instance for a given feature ID.
 *
 * @param {string} featureId - The ID of the feature.
 * @return {string | undefined} The translated feature instance or undefined if not applicable.
 */
export const getFeatureInstance = (featureId: string): string | undefined => {
  if (featureId.includes('/')) {
    const [feature, ...instanceParts] = featureId.split('/');
    const instance = instanceParts.join('.');
    const instanceKey = `common:feature-instances.${feature}.${instance}`;
    return I18n.getInstance().t(instanceKey);
  }

  return undefined;
};

/**
 * Retrieves the translated feature for a given feature ID.
 *
 * @param {string} featureId - The ID of the feature.
 * @return {string | undefined} The translated feature label or undefined if not applicable.
 */
export const getEventFeature = (featureId: string): string => {
  if (featureId.includes('/')) {
    return ifTranslationExists(`common:features.${featureId.split('/')[0]}`, featureId);
  }
  return ifTranslationExists(`common:features.${featureId}`, featureId);
};

/**
 * Retrieves the translated feature for a given feature string using event descriptions V2.
 *
 * @deprecated Use `getEventFeature` instead.
 * @param {string} feature - The feature string to translate.
 * @return {string | undefined} The translated feature label or undefined if not applicable.
 */
export const getEventFeatureV2 = (feature: string): string => {
  if (!feature || typeof feature !== 'string') {
    return '';
  }

  if (feature.includes('camera/')) {
    const [, cameraType] = feature.split('/');

    if (!cameraType) {
      return feature;
    }

    const specificKey = `common:features.camera-type.${cameraType}`;
    if (I18n.getInstance().exists(specificKey)) {
      return I18n.getInstance().t(specificKey);
    }

    return ifTranslationExists('common:features.camera-type', cameraType);
  }

  return ifTranslationExists(`common:features.${feature}`, feature);
};

/**
 * Generates a translation result object based on the given feature ID and event type ID.
 *
 * @param {string} featureId - The ID of the feature.
 * @param {string} eventTypeId - The ID of the event type.
 * @return {TranslationResult} An object containing the translated label, hint, and feature instance.
 */
export const getEventTranslation = (featureId: string, eventTypeId: string): TranslationResult => ({
  label: getEventLabel(featureId, eventTypeId),
  hint: getEventHint(featureId, eventTypeId),
  featureInstance: getFeatureInstance(featureId),
  featureLabel: getEventFeature(featureId),
});

/**
 * Retrieves the translated feature with optional instance for a given feature ID.
 *
 * @param {string} featureId - The ID of the feature.
 * @return {string} The translated feature label.
 */
export const getFeatureWithInstanceLabel = (featureId: string): string => {
  const featureDisplay = getEventFeature(featureId);
  const instanceDisplay = getFeatureInstance(featureId);

  return `${featureDisplay}${instanceDisplay ? ` - ${instanceDisplay}` : ''}`;
};

export interface FeatureWithInstancesAndEvents {
  feature: string;
  instances: string[];
  events: string[];
  alertableEvents: string[];
}

export const normalizeFeatureInstances = (featureInstances: FeatureInstancesWithSupportedEvents): FeatureWithInstancesAndEvents[] => {
  const result: FeatureWithInstancesAndEvents[] = [];

  for (const [featureInstance, definition] of Object.entries(featureInstances)) {
    const [feature, ...instanceParts] = featureInstance.split('/');
    const existing = result.find((entry) => entry.feature === feature);
    const instance = instanceParts.join('/');
    if (existing) {
      if (instance) existing.instances.push(instance);
      continue;
    }
    result.push({ feature, instances: instance ? [instance] : [], events: definition.events ?? [], alertableEvents: definition.alertableEvents ?? [] });
  }

  return result;
};

export const useMyAlertableFeaturesQuery = (thingId: Nullable<string | string[]>, enabled = true): UseQueryResult<FeatureWithInstancesAndEvents[]> => {
  return useMyFeaturesQuery({ thingId }, enabled, (data) => {
    return normalizeFeatureInstances(data).filter((feature) => feature.alertableEvents.length > 0);
  });
};

export const useMyNormalizedFeaturesQuery = (thingId: Nullable<string | string[]>, enabled = true): UseQueryResult<FeatureWithInstancesAndEvents[]> => {
  return useMyFeaturesQuery({ thingId }, enabled, normalizeFeatureInstances);
};

export const useMyAlertableFeaturesQueryByThingTypeIds = (thingTypeIds: Nullable<string[]>, enabled = true): UseQueryResult<FeatureWithInstancesAndEvents[]> => {
  return useMyFeaturesQuery({ thingTypeId: thingTypeIds }, enabled, (data) => {
    return normalizeFeatureInstances(data).filter((feature) => feature.alertableEvents.length > 0);
  });
};

const useMyFeaturesQuery = <T = FeatureInstancesWithSupportedEvents>(
  params: { thingId?: Nullable<string | string[]>; thingTypeId?: Nullable<string[]> },
  enabled = true,
  select?: (data: FeatureInstancesWithSupportedEvents) => T,
): UseQueryResult<T> => {
  const { restClient } = useAuthenticated();

  return useQuery({
    queryKey: ['my-feature-instances', params],
    enabled,
    select,
    queryFn: async () => {
      const apiParams: Record<string, string[]> = {};

      if (params.thingId && params.thingId !== null) {
        apiParams.thingId = Array.isArray(params.thingId) ? params.thingId : [params.thingId];
      }

      if (Array.isArray(params.thingTypeId) && params.thingTypeId.length > 0) {
        apiParams.thingTypeId = params.thingTypeId;
      }

      const response = await restClient.my.featureInstancesV2.getAll(apiParams);

      return response;
    },
  });
};
